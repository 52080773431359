import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import ColorPicker from '../../Shared/ColorPicker';

import './styles.scss';
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onSave: PropTypes.func,
  initData: PropTypes.shape(),
};

const defaultProps = {
  open: false,
  toggle: () => {},
  onSave: () => {},
  initData: null,
};

const defaultState = {
  title: '',
  color: '',
};

const LabelModal = ({ open, toggle, onSave, initData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [label, setLabel] = React.useState({
    ...defaultState,
  });

  React.useEffect(() => {
    if (!initData) return;
    setLabel({ ...initData });
  }, [initData]);

  const onTitleChange = (v) => {
    setLabel({ ...label, title: v });
  };

  const onColorChange = (color) => {
    setLabel({ ...label, color });
  };

  const handleSave = () => {
    onSave(label);
    setLabel(defaultState);
  };

  const handleClose = () => {
    toggle();
    setLabel(defaultState);
  };

  const isFormValid = () => {
    return label?.title?.length > 0;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {initData ? 'Edit' : 'Create'} Label
          </Typography>
          <Button 
            autoFocus 
            color="inherit" 
            onClick={handleSave}
            disabled={!isFormValid()}
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <TextField
          onChange={({ target }) => onTitleChange(target.value)}
          margin="normal"
          fullWidth
          value={label.title}
          id="label-name"
          label="Label name"
        />
        <div>
          <h3 style={{ marginBottom: 0 }}>Color</h3>
          <div className="labelModal__color-picker">
            <ColorPicker
              onChange={onColorChange}
              color={label.color}
              style={{ position: 'static' }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

LabelModal.propTypes = propTypes;
LabelModal.defaultProps = defaultProps;

export default LabelModal;
