import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import TypeIcon from '../../../Shared/TypeIcon';
import { useMyTypes } from 'core/store/selectors';
import { defaultIconsColor } from 'core/constants';

const propTypes = {
  value: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
  style: PropTypes.shape(),
  color: PropTypes.string,
};

const defaultProps = {
  onChange: () => {},
  style: {},
  color: defaultIconsColor,
};

const TypesSelect = ({ onChange, value, style, color }) => {
  const options = useMyTypes();
  const type = options.find((item) => item?.icon === value?.icon);
  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', ...style }}>
      <TypeIcon
        className="card-settings__type-icon"
        type={type ? type.icon : 'filter_none'}
        color={color}
      />
      <FormControl style={{ width: '100%', marginLeft: 10 }}>
        <InputLabel id="type-select-label">Card type</InputLabel>
        <Select
          labelId="type-select-label"
          id="type-select"
          value={value}
          onChange={({ target }) => onChange(target.value)}
          renderValue={(value) => value.title}
        >
          {options.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

TypesSelect.propTypes = propTypes;
TypesSelect.defaultProps = defaultProps;

export default TypesSelect;
