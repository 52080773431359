import { updateAllCardsWithType } from "./cardActions";
import { setShowErrorAction } from "./errorActions";

export const createTypeAction = (userId, typeData) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
    return getFirestore()
      .collection('users')
      .doc(userId)
      .collection('types')
      .add(typeData)
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
};
  
export const updateTypeAction = (userId, typeId, typeData) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  delete typeData.id;
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('types')
    .doc(typeId)
    .update(typeData)
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};
  
export const deleteTypeAction = (userId, id) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

  _dispatch(updateAllCardsWithType({userId, typeId: id}));

  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('types')
    .doc(id)
    .delete()
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};
  