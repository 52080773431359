import React from 'react';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';

import BaseLayoutRouter from '../../components/BaseLayout';
import RouteWithSubRoutes from '../../components/Shared/RouteWithSubRoutes';
import { useAuth } from '../store/selectors';
import SignIn from 'pages/auth/SignIn';
import SignUp from 'pages/auth/SignUp';
import Live from 'pages/Live';

const liveRoute = <Route path="/live/:userId/:permalink" component={Live} />;

const AppRouter = () => {
  const auth = useAuth();
  const loaded = isLoaded(auth);
  const empty = isEmpty(auth);

  return (
    <Router>
      {/* {!loaded && <Route>loading...</Route>} */}
      {loaded && empty && (
        <Switch>
          <Route path="/signup" component={SignUp} />
          <Route path="/signin" component={SignIn} />
          {liveRoute}
          <Route>
            <Redirect to="/signin" />
          </Route>
        </Switch>
      )}
      {loaded && !empty && (
        <Switch>
          <RouteWithSubRoutes {...BaseLayoutRouter} />
          {liveRoute}
          <Route>
            <Redirect to="/app/projects" />
          </Route>
        </Switch>
      )}
    </Router>
  );
};

export default AppRouter;
