import { SET_CARD, REMOVE_CARD } from '../actionsTypes/pinnedArea';

const projects = (
  state = {
    cards: [],
    loading: false,
    error: '',
  },
  { type, payload = {} }
) => {
  const { cards } = state;
  const { card } = payload;
  switch (type) {
    case SET_CARD:
      return {
        ...state,
        cards: [...cards, { ...card, isPinned: true }],
      };
    case REMOVE_CARD:
      return {
        ...state,
        cards: cards.filter((c) => c.id !== card.id),
      };
    default:
      return state;
  }
};

export default projects;
