import React from 'react';
import { Route } from 'react-router-dom';
import clsx from 'clsx';

import NewProjectModal from '../Project/NewProjectModal';

const BaseContainer = ({ open, routes, classes }) => {
  return (
    <main
      className={clsx(
        classes.content,
        {
          [classes.contentShift]: open,
        },
        'main-wrapper'
      )}
    >
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
      <NewProjectModal />
    </main>
  );
};

export default BaseContainer;
