import React from 'react';

import BoardCard from './Types/BoardCard';
import DefaultCard from './Types/DefaultCard';

const CardTypes = ({ type, ...props }) => {
  switch (type) {
    case 'board':
      return <BoardCard {...props} />;
    default:
      return <DefaultCard {...props} />;
  }
};

export default CardTypes;
