import React from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';

const RouteWithSubRoutes = ({ component: Feature, ...rest }) => (
  <Route
    render={routeProps => (
      <Feature {...routeProps} />
    )}
    {...rest}
  />
);

RouteWithSubRoutes.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
};

RouteWithSubRoutes.defaultProps = {
  component: undefined,
};

export default RouteWithSubRoutes;
