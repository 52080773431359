import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import ColorPoint from '../../ColorPoint';
import Label from '../../Labels/Label';
import { isBright } from 'core/helpers/color';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  onOptionDelete: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape()),
};

const defaultProps = {
  options: [],
  onOptionDelete: () => {},
  onChange: () => {},
  value: [],
};

const LabelsSelect = ({ options, value, onChange, onOptionDelete }) => {
  const [open, setOpen] = useState(false);  
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (e) => {
    e.preventDefault();
    if (e.target.tagName === 'path' || e.target.tagName === 'svg') return;
    setOpen(true);
  };
  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel id="labels-label">Labels</InputLabel>
      <Select
        labelId="labels-label"
        id="labels"
        name="labels"
        multiple
        fullWidth
        input={<Input />}
        placeholder="Labels"
        onOpen={handleOpen}
        open={open}
        onClose={handleClose}
        value={value}
        onChange={onChange}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Label
                key={value.id}
                label={value.title}
                style={{ marginRight: 5, backgroundColor: value.color, color: isBright(value.color) ? 'black' : 'white' }}
                onDelete={() => onOptionDelete(value.id)}
                deleteIcon={<HighlightOffIcon style={{height: 15, color: isBright(value.color) ? 'black' : 'white'}}/>}
                size="small"
              />
            ))}
          </div>
        )}
      >
        {options.map((label) => (
          <MenuItem key={label.id} value={label}>
            <span>
              <ColorPoint
                style={{ marginRight: 5, marginLeft: 0 }}
                color={label.color}
              />{' '}
              {label.title}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

LabelsSelect.propTypes = propTypes
LabelsSelect.defaultProps = defaultProps;

export default LabelsSelect;
