import React from 'react';

const ColorPoint = ({ color, style }) => {
  return (
    <span
      style={{
        width: 10,
        height: 10,
        position: 'relative',
        display: 'inline-block',
        borderRadius: '50%',
        background: color,
        ...style
      }}
    ></span>
  );
};

export default ColorPoint;
