import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Transition from "../Transition";

const propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    agreeText: PropTypes.string,
    disagreeText: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
};

const defaultProps = {
    open: false,
    onClose: ()=>{},
    onConfirm: ()=>{},
    agreeText: 'Confirm',
    disagreeText: 'Cancel',
    title: 'Delete this item?',
    text: 'You cannot restore this item.',
}

const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  agreeText,
  disagreeText,
  title,
  text,
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {disagreeText}
        </Button>
        <Button onClick={onConfirm} color="contrastText">
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = propTypes;
ConfirmModal.defaultProps = defaultProps;

export default ConfirmModal;
