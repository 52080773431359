import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import take from 'lodash/take';

export const useMyProjects = (limit) => {
  const projects = orderBy(
    useSelector((state) => state.firestore.ordered.myProjects) || [],
    'updatedAt',
    'desc'
  );
  return take(projects, limit || projects.length);
};
