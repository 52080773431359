import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';

import doc_icon from 'assets/doc_icon.png';
import kwil_icon from 'assets/kwil_icon.png';
import markdown_icon from 'assets/markdown_icon.png';
import {
  createProjectAction,
  closeNewProjectModalAction,
} from 'core/store/actions/projectsActions';
import { useProjectsState } from 'core/store/selectors';

const useStyles = makeStyles((theme) => ({
  modal: {
    background:
      'linear-gradient(90deg, rgba(61,61,61,1) 0%, rgba(112,112,112,1) 59%, rgba(189,189,189,1) 100%)',
    margin: '1%',
  },
  underline: {
    textDecoration: 'underline',
    color: 'white',
    fontSize: 30,
    textTransform: 'none',
  },
  text: {
    color: 'white',
    fontSize: 20,
    textTransform: 'none',
  },
  undertext: {
    fontSize: 20,
    color: 'white',
    textTransform: 'none',
    marginTop: '-10px',
  },
  icon: {
    fontSize: '60px',
    color: 'white',
  },
  form: {
    color: 'white',
    borderColor: 'white !important',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white !important',
    color: 'white',
  },
  floatingLabelFocusStyle: {
    color: 'white',
  },
  importButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function NewProjectModal() {
  const classes = useStyles();
  const [newBlankProject, setNewBlankProject] = useState(false);
  const [projectName, setProjectName] = useState('');
  const dispatch = useDispatch();

  const { newProjectModalOpen } = useProjectsState();

  const closeModal = () => {
    dispatch(closeNewProjectModalAction());
  };

  const createProject = () => {
    dispatch(createProjectAction({ name: projectName }));
    setNewBlankProject(false);
    setProjectName('');
    closeModal();
  };

  return (
    <Dialog open={newProjectModalOpen} onClose={closeModal}>
      <CancelIcon
        onClick={closeModal}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          color: '#fff',
        }}
      />
      <Card className={classes.modal}>
        <CardContent>
          <Grid
            container
            spacing={4}
            direction="column"
            justify="center"
            alignItems="center"
            style={{ marginBottom: 20, width: 600 }}
          >
            <Typography className={classes.underline}>New Project</Typography>
            {newBlankProject ? null : (
              <Fragment>
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    aligItems: 'center',
                  }}
                  onClick={() => setNewBlankProject(true)}
                >
                  <AddCircleOutlineIcon className={classes.icon} />
                </Button>
                <Typography className={classes.undertext}>blank</Typography>
              </Fragment>
            )}
          </Grid>
          {newBlankProject ? (
            <Grid
              container
              spacing={4}
              direction="column"
              justify="center"
              alignItems="center"
              style={{ marginTop: '1%' }}
            >
              <Grid item xs={12} style={{ width: '90%' }}>
                <TextField
                  variant="standard"
                  label="Name of the Project"
                  style={{ width: '100%' }}
                  value={projectName}
                  InputProps={{
                    style: {
                      color: 'white',
                    },
                  }}
                  onChange={(event) => setProjectName(event.target.value)}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={4}
              direction="row"
              justify="center"
              alignItems="center"
              style={{ marginTop: '1%' }}
            >
              <Grid item xs={2}>
                <Typography className={classes.text}>Import:</Typography>
              </Grid>

              <Grid item xs={2}>
                <Button>
                  <Container className={classes.importButtons}>
                    <img
                      src={doc_icon}
                      style={classes.importIcons}
                      alt="Microsoft Word Icon"
                    />
                    <Typography className={classes.text}>docs</Typography>
                  </Container>
                </Button>
              </Grid>

              <Grid item xs={3}>
                <Button className={classes.button}>
                  <Container className={classes.importButtons}>
                    <img
                      src={markdown_icon}
                      style={classes.importIcons}
                      alt="Markdown Icon"
                    />
                    <Typography className={classes.text}>markdown</Typography>
                  </Container>
                </Button>
              </Grid>

              <Grid item xs={4}>
                <Button className={classes.button}>
                  <Container className={classes.importButtons}>
                    <img
                      src={kwil_icon}
                      style={classes.importIcons}
                      alt="Kwil Icon"
                    />
                    <Typography className={classes.text}>kwil:</Typography>
                  </Container>
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>

      <Grid>
        {!newBlankProject && (
          <Card className={classes.modal}>
            <CardContent>
              <Typography
                gutterBottom
                className={classes.underline}
                style={{ marginLeft: '15%' }}
              >
                New from Template
              </Typography>

              <form className={classes.form}>
                <TextField
                  id="outlined-adornment-password"
                  variant="outlined"
                  label="Search..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon style={{ color: 'white' }} />
                      </InputAdornment>
                    ),
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  style={{ width: '100%' }}
                />
              </form>
            </CardContent>
          </Card>
        )}
      </Grid>

      <Button
        color="secondary"
        style={{
          marginBottom: newBlankProject ? 0 : '2%',
          marginTop: '2%',
        }}
        onClick={createProject}
      >
        Let's Go!
      </Button>
    </Dialog>
  );
}
