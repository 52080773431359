//sidebar

import {
  SET_LIBRARY_FILTER,
  SET_BOARD_FILTER,
  SET_SIDEBAR_FILTER,
  SET_PROJECTS_FILTER,
} from '../actionsTypes/filter';

const initialState = {
  library: {
    types: [],
    title: '',
    labels: [],
  },
  board: {
    title: '',
  },
  projects: {
    title: '',
    labels: [],
  },
  sidebar: {},
};

const cards = (
  state = {
    ...initialState,
  },
  { type, payload = {} }
) => {
  const { library, sidebar, projects, board } = state;
  const { name, value } = payload;
  switch (type) {
    case SET_LIBRARY_FILTER:
      return {
        ...state,
        library: {
          ...library,
          [name]: value,
        },
      };
    case SET_BOARD_FILTER:
      return {
        ...state,
        board: {
          ...board,
          [name]: value,
        },
      };
    case SET_SIDEBAR_FILTER:
      return {
        ...state,
        sidebar: {
          ...sidebar,
          [name]: value,
        },
      };
    case SET_PROJECTS_FILTER:
      return {
        ...state,
        projects: {
          ...projects,
          [name]: value,
        },
      };
    default:
      return state;
  }
};

export default cards;
