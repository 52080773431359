// import { Button } from '@material-ui/core';
// import clsx from 'clsx';
import React from 'react';

import Mindmap from '../../components/Mindmap';
import { 
  useCurrentProject, 
  useMyBoardColumn 
} from 'core/store/selectors';
import './styles.scss';

const MindmapPage = () => {
  const currentProject = useCurrentProject();

  const columns = useMyBoardColumn({projectId: currentProject?.id});
  
  // const [showEditor, setShowEditor] = useState(true);

  return (
    <div>
      {/* <div className={clsx(['mindmap-separate', { show: showEditor }])}>
        <hr></hr>
        <Button
          variant={showEditor ? 'outlined' : 'contained'}
          color="primary"
          size="small"
          style={{ minWidth: 110, marginLeft: 5 }}
          onClick={() => setShowEditor(!showEditor)}
        >
          {showEditor ? 'Hide' : 'Show'} Editor
        </Button>
      </div> */}
      <Mindmap columns={columns} />
    </div>
  );
};

export default MindmapPage;
