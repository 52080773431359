/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import UnfoldMoreOutlinedIcon from '@material-ui/icons/UnfoldMoreOutlined';
import { Resizable } from 'react-resizable';
import PropTypes from 'prop-types';

import CardInterface from '../CardInterface';
import { collapsedHeight, grid } from 'core/constants';

const propTypes = {
  card: PropTypes.shape(),
  onResizeStart: PropTypes.func,
  onResizeStop: PropTypes.func,
  onResize: PropTypes.func,
  onCardChange: PropTypes.func,
  onCardCopy: PropTypes.func,
  onCardRemove: PropTypes.func,
  onCardCollapse: PropTypes.func,
  onSettingsClose: PropTypes.func,
  onTitleUpdate: PropTypes.func,
  onBodyUpdate: PropTypes.func,
  onAddLabel: PropTypes.func,
  onDeleteLabel: PropTypes.func,
  disableResize: PropTypes.bool,
  provided: PropTypes.shape(),
  snapshot: PropTypes.shape(),
  style: PropTypes.shape(),
};

const defaultProps = {
  card: {},
  onResizeStart: () => {},
  onResizeStop: () => {},
  onResize: () => {},
  onCardChange: () => {},
  onCardCopy: () => {},
  onCardRemove: () => {},
  onCardCollapse: () => {},
  onSettingsClose: () => {},
  onTitleUpdate: () => {},
  onBodyUpdate: () => {},
  onAddLabel: () => {},
  onDeleteLabel: () => {},
  disableResize: false,
  provided: {},
  snapshot: {},
  style: {},
};

const getItemStyle = (isDragging, draggableStyle) => ({
  padding: grid,
  margin: `0 0 ${grid}px 0`,
  background: 'transparent',
  overflow: 'hidden',
  ...draggableStyle,
});

const BoardCard = React.memo(
  ({
    card,
    onResizeStart,
    onResizeStop,
    onResize,
    provided,
    snapshot,
    onCardChange,
    onCardRemove,
    onCardCopy,
    onCardCollapse,
    disableResize,
    onSettingsClose,
    onTitleUpdate,
    onBodyUpdate,
    onAddLabel,
    onDeleteLabel,
    height,
    style,
    disableActions,
    location,
    isHorizontal,
    ...props
  }) => {

    const iconClassName = `card${disableActions ? '__resize-disabled' : isHorizontal ? '__resize-handle' : '__vertical'}`;

    return (
      <Resizable
        height={height}
        width={0}
        axis="y"
        onResize={onResize}
        minConstraints={[0, collapsedHeight]}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
        handle={
          <UnfoldMoreOutlinedIcon
            style={{
              color: card.color,
            }}
            className={iconClassName}
          />
        }
        style={style}
        className="ignore-scroll"
      >
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(snapshot.isDragging, {
            ...provided.draggableProps.style,
          })}
        >
          <CardInterface
            provided={provided}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
            onCardCollapsed={onCardCollapse}
            onCardRemove={onCardRemove}
            onCardCopy={onCardCopy}
            disableResize={disableResize}
            data={card}
            onDataChange={onCardChange}
            onSettingsClose={onSettingsClose}
            onTitleUpdate={onTitleUpdate}
            onBodyUpdate={onBodyUpdate}
            onAddLabel={onAddLabel}
            onDeleteLabel={onDeleteLabel}
            height={height}
            disableActions={disableActions}
            location={location}
            isHorizontal={isHorizontal}
            {...props}
          />
        </div>
      </Resizable>
    );
  }
);

BoardCard.propTypes = propTypes;
BoardCard.defaultProps = defaultProps;

export default BoardCard;
