import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux'

import CustomList from '../../Shared/CustomList';
import TypeModal from './TypeModal';
import ConfirmModal from '../../Shared/ConfirmModal';
import { 
  createTypeAction, 
  updateTypeAction,
  deleteTypeAction,
} from 'core/store/actions/typesActions';
import { useMyTypes } from 'core/store/selectors/useMyTypes';
import { useAuth } from 'core/store/selectors/useAuth';

const Types = () => {
  const dispatch = useDispatch();
  
  const auth = useAuth();
  const types = useMyTypes();

  const [open, setOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [editableItem, setEditableItem] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  
  const modalToggle = () => {
    if (open) setEditableItem(null);
    setOpen(!open);
  };

  const cofirmModalToggle = () => {
    setConfirmModal(!confirmModal);
  };

  const onTypeEdit = ({ id, icon, title }) => {
    setEditableItem({ id, icon, title });
    modalToggle();
  };

  const onTypeDelete = (item) => {
    setDeletedId(item.id);
    cofirmModalToggle();
  };

  const editItem = (item) => {
    dispatch(updateTypeAction(auth?.uid, editableItem.id, item));
  };

  const createItem = (item) => {
    dispatch(createTypeAction(auth?.uid, item));
  };

  const onTypeSave = (item) => {
    if (editableItem) editItem(item);
    if (!editableItem) createItem(item);
    modalToggle();
  };

  const onModalOpen = () => {
    setEditableItem(null);
    modalToggle();
  };

  const onConfirm = () => {
    dispatch(deleteTypeAction(auth.uid, deletedId));
    setDeletedId(null);
    cofirmModalToggle();
  };

  return (
    <div>
      <h3
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Types
        <Button
          onClick={onModalOpen}
          variant="contained"
          size="small"
          color="primary"
        >
          Create
        </Button>
      </h3>
      <CustomList
        onItemEdit={onTypeEdit}
        onItemDelete={onTypeDelete}
        items={types}
      />
      <TypeModal
        initData={editableItem}
        onSave={onTypeSave}
        open={open}
        toggle={modalToggle}
      />
      <ConfirmModal
        title={'Delete type?'}
        open={confirmModal}
        onClose={cofirmModalToggle}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default Types;
