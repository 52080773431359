import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';

import { setShowErrorAction } from "./errorActions";
import { reorder } from 'core/helpers/sidebar';
import { setOpen } from '../actionsCreators/sidebarActionCreator';

/**
 * This action is called to setup the sidebar 1 column
 * It's called just once when there is no sidebar in the project
 * 
 * @param {String} userId Sidebar's Owner ID
 * @param {Object} currentSidebar Current sidebar
 * @param {String} projectId Current Project's ID
 */
export const createSidebarForProject = ({userId, currentSidebar, projectId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

  if (!(_getState().firestore.ordered.mySidebars && currentSidebar?.id)) {
    const sidebarToBeCreated = {
      cards: [],
      projectId: projectId
    };
        
    return getFirestore()
      .collection('users')
      .doc(userId)
      .collection('sidebars')
      .add(sidebarToBeCreated)
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
  }
};

/**
 * Add card into the sidebar
 * 
 * @param {String} userId Card's Owner ID
 * @param {String} cardId Card ID to be added into the sidebar
 * @param {String} currentSidebar Sidebar ID
 */
export const addCardIntoSidebar = ({userId, cardId, currentSidebar}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  const sidebar = _cloneDeep(currentSidebar);

  delete sidebar.id;

  // Avoid adding duplicated cards into sidebar
  if (!sidebar.cards.includes(cardId)) {
    sidebar.cards.unshift(cardId);
  }
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('sidebars')
    .doc(currentSidebar.id)
    .update(sidebar)
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};

/**
 * Remove card from the sidebar
 * 
 * @param {String} userId Card's Owner ID
 * @param {String} cardId Card ID to be removed from the subcolumn
 */
export const removeCardFromSidebar = ({userId, cardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

  _map(
    _getState().firestore.ordered.mySidebars, sb => {
      const cards = _filter(sb.cards, c => c !== cardId);
      const sidebarToUpdate = {...sb, cards: cards};

      const id = sidebarToUpdate.id;
      delete sidebarToUpdate.id;

      return getFirestore()
        .collection('users')
        .doc(userId)
        .collection('sidebars')
        .doc(id)
        .update(sidebarToUpdate)
        .catch(error => {
          _dispatch(setShowErrorAction({error}));
        });
    } 
  )
};

/**
 * Update the sidebar
 * 
 * @param {String} userId Card's Owner ID
 * @param {Object} updatedSidebar New Sidebar's info
 */
export const updateSidebarAction = ({userId, updatedSidebar}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

    return getFirestore()
      .collection('users')
      .doc(userId)
      .collection('sidebars')
      .doc(updatedSidebar.id)
      .update(updatedSidebar)
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
};
  
/**
 * Reorder card in sidebar
 * 
 * @param {String} userId Card's Owner ID
 * @param {Object} source Source object from DnD library
 * @param {Object} destination Destination object from DnD library
 */
export const reorderSidebar = ({userId, currentSidebar, source, destination}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  const orderedSidebar = reorder(currentSidebar.cards, source.index, destination.index);

  const updatedSidebar = { ...currentSidebar, cards: orderedSidebar };

  _dispatch(updateSidebarAction({userId, updatedSidebar}));
}

/**
 * Open or close sidebar
 * 
 * @param {Boolean} open 
 */
export const setSidebarOpen = (open) => async (dispatch) => {
  try {
    dispatch(setOpen({open}));
  } catch (error) {
    dispatch(setShowErrorAction({error}));
  }
};
