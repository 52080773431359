import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import SignBase from './SignBase';
import useStyles from './SignBase.styles';

const SignIn = () => {
  const classes = useStyles();
  const firebase = useFirebase();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const errorMessage = useSelector(
    (state) => state.firebase.authError?.message
  );

  const signIn = () => {
    firebase.login({ email, password }).catch(() => {});
  };

  const handlePasswordKeyUp = (e) => {
    if (e.keyCode === 13) {
      signIn();
    }
  };

  return (
    <SignBase
      socialHeading="Sign In with"
      link={
        <RouterLink className={classes.link} to="/signup">
          Don't have an account? Signup
        </RouterLink>
      }
    >
      <form>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={1}>
            <Icon>email</Icon>
          </Grid>
          <Grid item xs={10}>
            <TextField
              autoFocus
              variant="standard"
              margin="none"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={1}>
            <Icon>lock</Icon>
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="standard"
              margin="none"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={handlePasswordKeyUp}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Box marginTop={2}>
            <Button color="secondary" onClick={signIn}>
              Sign In
            </Button>
          </Box>
        </Grid>
        <Grid item style={{ marginBottom: 5 }}>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={!!errorMessage}
            autoHideDuration={6000}
            onClose={() => firebase.logout()}
          >
            <SnackbarContent
              style={{
                backgroundColor: '#ff7f7f',
                fontSize: 16,
              }}
              message={<span id="client-snackbar">{errorMessage}</span>}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => firebase.logout()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
          </Snackbar>
        </Grid>
      </form>
    </SignBase>
  );
};

export default SignIn;
