import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Types from './Types';
import Labels from './Labels';

const SettingsPage = () => {

  return (
    <>
      <Typography variant="h4">Settings</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <Types></Types>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Labels></Labels>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}></Grid>
      </Grid>
    </>
  );
};

export default SettingsPage;
