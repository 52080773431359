import { createStyles, makeStyles } from '@material-ui/core/styles';

import backgroundImg from 'assets/background_image.png';

export default makeStyles((theme) =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
    },
    header: {
      padding: '10px',
    },
    background: {
      backgroundImage: `url(${backgroundImg})`,
      backgroundSize: 'cover',
      flexGrow: 1,
    },
    footer: {
      fontFamily: 'Saira',
      fontSize: '18px',
      lineHeight: '28px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        lineHeight: '14px',
      },
    },
    title: {
      paddingLeft: '126px',
      fontFamily: 'Saira',
      fontSize: '72px',
      lineHeight: '72px',
      letterSpacing: '-0.1em',
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0',
        fontSize: '36px',
        lineHeight: '36px',
      },
    },
    feather: {
      width: '25px',
      padding: 5,
      [theme.breakpoints.up('md')]: {
        position: 'fixed',
        left: 0,
        top: '-18%',
        width: '193px',
      },
    },
    links: {},
    content: {
      height: '100%',
      width: '365px',
      margin: 'auto',
    },
    form: {
      width: '100%',
      position: 'relative',
      background: '#FFFFFF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '3px',
    },
    link: {
      color: 'inherit',
      backgroundColor: '#A2DBAF',
      borderRadius: '16px',
      padding: '7px',
      textAlign: 'center',
      marginTop: 10,
    },
  })
);
