import React, { useState, useCallback, useEffect, memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useEditorState } from '@kwilio/editor';

import { useCurrentProject } from 'core/store/selectors';
import NavigationTree from './NavigationTree';
import Bookmarks from './Bookmarks';

// externalized constants in case we figure out a way
// to find them in the theme
const searchGridHeightPx = 64;
const separatorHeightPx = 1;
const headerHeightPx = 48;

const useStyles = makeStyles({
  navigationTabRoot: {
    height: `calc(100vh - ${searchGridHeightPx}px - ${separatorHeightPx}px - ${headerHeightPx}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
});

const NavigationSections = memo(function NavigationSections({
  height,
  goAndScrollTo,
}) {
  const currentProject = useCurrentProject();
  const maxHeight = Math.round(height / 2);
  const cProps = {
    maxHeight,
    goAndScrollTo,
  };
  if (!currentProject) {
    return null;
  }
  return (
    <>
      <NavigationTree {...cProps} />
      <Bookmarks {...cProps} />
    </>
  );
});

export default function NavigationTab() {
  const classes = useStyles();
  const { manager } = useEditorState();
  const [height, setHeight] = useState(0);
  const [node, setNode] = useState(0);

  //goto block
  const goTo = useMemo(() => {
    if (manager) {
      return manager.data.actions.goTo;
    }
  }, [manager]);

  const scrollTo = useCallback(
    (position) => {
      const dom = manager.view.nodeDOM(position);
      if (dom) {
        document.getElementsByTagName('main')[0].scrollTo({
          top: dom.offsetTop,
        });
        // window.scrollTo(0, dom.offsetTop);
      }
    },
    [manager]
  );

  const goAndScrollTo = useCallback(
    (position) => {
      goTo && goTo({ position });
      scrollTo && scrollTo(position);
    },
    [goTo, scrollTo]
  );

  // resize block
  const ref = useCallback((node) => {
    if (node) {
      setNode(node);
    }
  }, []);

  const handleResize = useCallback(() => {
    if (node) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, [node]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div ref={ref} className={classes.navigationTabRoot}>
      <NavigationSections {...{ height, goAndScrollTo }} />
    </div>
  );
}
