import Project from '../../pages/Project';
import Projects from '../../pages/Projects';
import CardsPage from '../../pages/CardsLibrary';
import ProfilePage from '../../pages/profile';
import Mindmap from '../../pages/Mindmap';

export default [
  {
    path: '/app/project',
    component: Project,
    exact: true,
  },
  {
    path: '/app/projects',
    component: Projects,
    exact: true,
  },
  {
    path: '/app/cards',
    component: CardsPage,
    exact: true,
  },
  {
    path: '/app/mindmap',
    component: Mindmap,
    exact: true,
  },
  {
    path: '/app/profile',
    component: ProfilePage,
    exact: true,
  },
];
