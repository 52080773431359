export default [
  {
    icon: '3d_rotation',
    keywords: 'action, 3d, rotation',
  }, {
    icon: 'accessibility',
    keywords: 'action, accessibility',
  }, {
    icon: 'accessible',
    keywords: 'action, accessible',
  }, {
    icon: 'account_balance',
    keywords: 'action, account, balance',
  }, {
    icon: 'account_balance_wallet',
    keywords: 'action, account, balance, wallet',
  }, {
    icon: 'account_box',
    keywords: 'action, account, box',
  }, {
    icon: 'account_circle',
    keywords: 'action, account, circle',
  }, {
    icon: 'add_shopping_cart',
    keywords: 'action, add, shopping, cart',
  }, {
    icon: 'alarm',
    keywords: 'action, alarm',
  }, {
    icon: 'alarm_add',
    keywords: 'action, alarm, add',
  }, {
    icon: 'alarm_off',
    keywords: 'action, alarm, off',
  }, {
    icon: 'alarm_on',
    keywords: 'action, alarm, on',
  }, {
    icon: 'all_out',
    keywords: 'action, all, out',
  }, {
    icon: 'android',
    keywords: 'action, android',
  }, {
    icon: 'announcement',
    keywords: 'action, announcement',
  }, {
    icon: 'aspect_ratio',
    keywords: 'action, aspect, ratio',
  }, {
    icon: 'assessment',
    keywords: 'action, assessment',
  }, {
    icon: 'assignment',
    keywords: 'action, assignment',
  }, {
    icon: 'assignment_ind',
    keywords: 'action, assignment, ind',
  }, {
    icon: 'assignment_late',
    keywords: 'action, assignment, late',
  }, {
    icon: 'assignment_return',
    keywords: 'action, assignment, return',
  }, {
    icon: 'assignment_returned',
    keywords: 'action, assignment, returned',
  }, {
    icon: 'assignment_turned_in',
    keywords: 'action, assignment, turned, in',
  }, {
    icon: 'autorenew',
    keywords: 'action, autorenew',
  }, {
    icon: 'backup',
    keywords: 'action, backup',
  }, {
    icon: 'book',
    keywords: 'action, book',
  }, {
    icon: 'bookmark',
    keywords: 'action, bookmark',
  }, {
    icon: 'bookmark_border',
    keywords: 'action, bookmark, border',
  }, {
    icon: 'bug_report',
    keywords: 'action, bug, report',
  }, {
    icon: 'build',
    keywords: 'action, build',
  }, {
    icon: 'cached',
    keywords: 'action, cached',
  }, {
    icon: 'camera_enhance',
    keywords: 'action, camera, enhance',
  }, {
    icon: 'card_giftcard',
    keywords: 'action, card, giftcard',
  }, {
    icon: 'card_membership',
    keywords: 'action, card, membership',
  }, {
    icon: 'card_travel',
    keywords: 'action, card, travel',
  }, {
    icon: 'change_history',
    keywords: 'action, change, history',
  }, {
    icon: 'check_circle',
    keywords: 'action, check, circle',
  }, {
    icon: 'chrome_reader_mode',
    keywords: 'action, chrome, reader, mode',
  }, {
    icon: 'class',
    keywords: 'action, class',
  }, {
    icon: 'code',
    keywords: 'action, code',
  }, {
    icon: 'compare_arrows',
    keywords: 'action, compare, arrows',
  }, {
    icon: 'copyright',
    keywords: 'action, copyright',
  }, {
    icon: 'credit_card',
    keywords: 'action, credit, card',
  }, {
    icon: 'dashboard',
    keywords: 'action, dashboard',
  }, {
    icon: 'date_range',
    keywords: 'action, date, range',
  }, {
    icon: 'delete',
    keywords: 'action, delete',
  }, {
    icon: 'description',
    keywords: 'action, description',
  }, {
    icon: 'dns',
    keywords: 'action, dns',
  }, {
    icon: 'done',
    keywords: 'action, done',
  }, {
    icon: 'done_all',
    keywords: 'action, done, all',
  }, {
    icon: 'donut_large',
    keywords: 'action, donut, large',
  }, {
    icon: 'donut_small',
    keywords: 'action, donut, small',
  }, {
    icon: 'eject',
    keywords: 'action, eject',
  }, {
    icon: 'event',
    keywords: 'action, event',
  }, {
    icon: 'event_seat',
    keywords: 'action, event, seat',
  }, {
    icon: 'exit_to_app',
    keywords: 'action, exit, to, app',
  }, {
    icon: 'explore',
    keywords: 'action, explore',
  }, {
    icon: 'extension',
    keywords: 'action, extension',
  }, {
    icon: 'face',
    keywords: 'action, face',
  }, {
    icon: 'favorite',
    keywords: 'action, favorite',
  }, {
    icon: 'favorite_border',
    keywords: 'action, favorite, border',
  }, {
    icon: 'feedback',
    keywords: 'action, feedback',
  }, {
    icon: 'find_in_page',
    keywords: 'action, find, in, page',
  }, {
    icon: 'find_replace',
    keywords: 'action, find, replace',
  }, {
    icon: 'fingerprint',
    keywords: 'action, fingerprint',
  }, {
    icon: 'flight_land',
    keywords: 'action, flight, land',
  }, {
    icon: 'flight_takeoff',
    keywords: 'action, flight, takeoff',
  }, {
    icon: 'flip_to_back',
    keywords: 'action, flip, to, back',
  }, {
    icon: 'flip_to_front',
    keywords: 'action, flip, to, front',
  }, {
    icon: 'gavel',
    keywords: 'action, gavel',
  }, {
    icon: 'get_app',
    keywords: 'action, get, app',
  }, {
    icon: 'gif',
    keywords: 'action, gif',
  }, {
    icon: 'grade',
    keywords: 'action, grade',
  }, {
    icon: 'group_work',
    keywords: 'action, group, work',
  }, {
    icon: 'help',
    keywords: 'action, help',
  }, {
    icon: 'help_outline',
    keywords: 'action, help, outline',
  }, {
    icon: 'highlight_off',
    keywords: 'action, highlight, off',
  }, {
    icon: 'history',
    keywords: 'action, history',
  }, {
    icon: 'home',
    keywords: 'action, home',
  }, {
    icon: 'hourglass_empty',
    keywords: 'action, hourglass, empty',
  }, {
    icon: 'hourglass_full',
    keywords: 'action, hourglass, full',
  }, {
    icon: 'http',
    keywords: 'action, http',
  }, {
    icon: 'https',
    keywords: 'action, https',
  }, {
    icon: 'important_devices',
    keywords: 'action, important, devices',
  }, {
    icon: 'info',
    keywords: 'action, info',
  }, {
    icon: 'info_outline',
    keywords: 'action, info, outline',
  }, {
    icon: 'input',
    keywords: 'action, input',
  }, {
    icon: 'invert_colors',
    keywords: 'action, invert, colors',
  }, {
    icon: 'label',
    keywords: 'action, label',
  }, {
    icon: 'label_outline',
    keywords: 'action, label, outline',
  }, {
    icon: 'language',
    keywords: 'action, language',
  }, {
    icon: 'launch',
    keywords: 'action, launch',
  }, {
    icon: 'lightbulb_outline',
    keywords: 'action, lightbulb, outline',
  }, {
    icon: 'line_style',
    keywords: 'action, line, style',
  }, {
    icon: 'line_weight',
    keywords: 'action, line, weight',
  }, {
    icon: 'list',
    keywords: 'action, list',
  }, {
    icon: 'lock',
    keywords: 'action, lock',
  }, {
    icon: 'lock_open',
    keywords: 'action, lock, open',
  }, {
    icon: 'lock_outline',
    keywords: 'action, lock, outline',
  }, {
    icon: 'loyalty',
    keywords: 'action, loyalty',
  }, {
    icon: 'markunread_mailbox',
    keywords: 'action, markunread, mailbox',
  }, {
    icon: 'motorcycle',
    keywords: 'action, motorcycle',
  }, {
    icon: 'note_add',
    keywords: 'action, note, add',
  }, {
    icon: 'offline_pin',
    keywords: 'action, offline, pin',
  }, {
    icon: 'opacity',
    keywords: 'action, opacity',
  }, {
    icon: 'open_in_browser',
    keywords: 'action, open, in, browser',
  }, {
    icon: 'open_in_new',
    keywords: 'action, open, in, new',
  }, {
    icon: 'open_with',
    keywords: 'action, open, with',
  }, {
    icon: 'pageview',
    keywords: 'action, pageview',
  }, {
    icon: 'pan_tool',
    keywords: 'action, pan, tool',
  }, {
    icon: 'payment',
    keywords: 'action, payment',
  }, {
    icon: 'perm_camera_mic',
    keywords: 'action, perm, camera, mic',
  }, {
    icon: 'perm_contact_calendar',
    keywords: 'action, perm, contact, calendar',
  }, {
    icon: 'perm_data_setting',
    keywords: 'action, perm, data, setting',
  }, {
    icon: 'perm_device_information',
    keywords: 'action, perm, device, information',
  }, {
    icon: 'perm_identity',
    keywords: 'action, perm, identity',
  }, {
    icon: 'perm_media',
    keywords: 'action, perm, media',
  }, {
    icon: 'perm_phone_msg',
    keywords: 'action, perm, phone, msg',
  }, {
    icon: 'perm_scan_wifi',
    keywords: 'action, perm, scan, wifi',
  }, {
    icon: 'pets',
    keywords: 'action, pets',
  }, {
    icon: 'picture_in_picture',
    keywords: 'action, picture, in, picture',
  }, {
    icon: 'picture_in_picture_alt',
    keywords: 'action, picture, in, picture, alt',
  }, {
    icon: 'play_for_work',
    keywords: 'action, play, work',
  }, {
    icon: 'polymer',
    keywords: 'action, polymer',
  }, {
    icon: 'power_settings_new',
    keywords: 'action, power, settings, new',
  }, {
    icon: 'pregnant_woman',
    keywords: 'action, pregnant, woman',
  }, {
    icon: 'print',
    keywords: 'action, print',
  }, {
    icon: 'query_builder',
    keywords: 'action, query, builder',
  }, {
    icon: 'question_answer',
    keywords: 'action, question, answer',
  }, {
    icon: 'receipt',
    keywords: 'action, receipt',
  }, {
    icon: 'record_voice_over',
    keywords: 'action, record, voice, over',
  }, {
    icon: 'redeem',
    keywords: 'action, redeem',
  }, {
    icon: 'reorder',
    keywords: 'action, reorder',
  }, {
    icon: 'report_problem',
    keywords: 'action, report, problem',
  }, {
    icon: 'restore',
    keywords: 'action, restore',
  }, {
    icon: 'room',
    keywords: 'action, room',
  }, {
    icon: 'rounded_corner',
    keywords: 'action, rounded, corner',
  }, {
    icon: 'rowing',
    keywords: 'action, rowing',
  }, {
    icon: 'schedule',
    keywords: 'action, schedule',
  }, {
    icon: 'search',
    keywords: 'action, search',
  }, {
    icon: 'settings',
    keywords: 'action, settings',
  }, {
    icon: 'settings_applications',
    keywords: 'action, settings, applications',
  }, {
    icon: 'settings_backup_restore',
    keywords: 'action, settings, backup, restore',
  }, {
    icon: 'settings_bluetooth',
    keywords: 'action, settings, bluetooth',
  }, {
    icon: 'settings_brightness',
    keywords: 'action, settings, brightness',
  }, {
    icon: 'settings_cell',
    keywords: 'action, settings, cell',
  }, {
    icon: 'settings_ethernet',
    keywords: 'action, settings, ethernet',
  }, {
    icon: 'settings_input_antenna',
    keywords: 'action, settings, input, antenna',
  }, {
    icon: 'settings_input_component',
    keywords: 'action, settings, input, component',
  }, {
    icon: 'settings_input_composite',
    keywords: 'action, settings, input, composite',
  }, {
    icon: 'settings_input_hdmi',
    keywords: 'action, settings, input, hdmi',
  }, {
    icon: 'settings_input_svideo',
    keywords: 'action, settings, input, svideo',
  }, {
    icon: 'settings_overscan',
    keywords: 'action, settings, overscan',
  }, {
    icon: 'settings_phone',
    keywords: 'action, settings, phone',
  }, {
    icon: 'settings_power',
    keywords: 'action, settings, power',
  }, {
    icon: 'settings_remote',
    keywords: 'action, settings, remote',
  }, {
    icon: 'settings_voice',
    keywords: 'action, settings, voice',
  }, {
    icon: 'shop',
    keywords: 'action, shop',
  }, {
    icon: 'shop_two',
    keywords: 'action, shop, two',
  }, {
    icon: 'shopping_basket',
    keywords: 'action, shopping, basket',
  }, {
    icon: 'shopping_cart',
    keywords: 'action, shopping, cart',
  }, {
    icon: 'speaker_notes',
    keywords: 'action, speaker, notes',
  }, {
    icon: 'spellcheck',
    keywords: 'action, spellcheck',
  }, {
    icon: 'star_rate',
    keywords: 'action, star, rate',
  }, {
    icon: 'stars',
    keywords: 'action, stars',
  }, {
    icon: 'store',
    keywords: 'action, store',
  }, {
    icon: 'subject',
    keywords: 'action, subject',
  }, {
    icon: 'supervisor_account',
    keywords: 'action, supervisor, account',
  }, {
    icon: 'swap_horiz',
    keywords: 'action, swap, horiz',
  }, {
    icon: 'swap_vert',
    keywords: 'action, swap, vert',
  }, {
    icon: 'swap_vertical_circle',
    keywords: 'action, swap, vertical, circle',
  }, {
    icon: 'system_update_alt',
    keywords: 'action, system, update, alt',
  }, {
    icon: 'tab',
    keywords: 'action, tab',
  }, {
    icon: 'tab_unselected',
    keywords: 'action, tab, unselected',
  }, {
    icon: 'theaters',
    keywords: 'action, theaters',
  }, {
    icon: 'thumb_down',
    keywords: 'action, thumb, down',
  }, {
    icon: 'thumb_up',
    keywords: 'action, thumb, up',
  }, {
    icon: 'thumbs_up_down',
    keywords: 'action, thumbs, up, down',
  }, {
    icon: 'timeline',
    keywords: 'action, timeline',
  }, {
    icon: 'toc',
    keywords: 'action, toc',
  }, {
    icon: 'today',
    keywords: 'action, today',
  }, {
    icon: 'toll',
    keywords: 'action, toll',
  }, {
    icon: 'touch_app',
    keywords: 'action, touch, app',
  }, {
    icon: 'track_changes',
    keywords: 'action, track, changes',
  }, {
    icon: 'translate',
    keywords: 'action, translate',
  }, {
    icon: 'trending_down',
    keywords: 'action, trending, down',
  }, {
    icon: 'trending_flat',
    keywords: 'action, trending, flat',
  }, {
    icon: 'trending_up',
    keywords: 'action, trending, up',
  }, {
    icon: 'turned_in',
    keywords: 'action, turned, in',
  }, {
    icon: 'turned_in_not',
    keywords: 'action, turned, in, not',
  }, {
    icon: 'update',
    keywords: 'action, update',
  }, {
    icon: 'verified_user',
    keywords: 'action, verified, user',
  }, {
    icon: 'view_agenda',
    keywords: 'action, view, agenda',
  }, {
    icon: 'view_array',
    keywords: 'action, view, array',
  }, {
    icon: 'view_carousel',
    keywords: 'action, view, carousel',
  }, {
    icon: 'view_column',
    keywords: 'action, view, column',
  }, {
    icon: 'view_day',
    keywords: 'action, view, day',
  }, {
    icon: 'view_headline',
    keywords: 'action, view, headline',
  }, {
    icon: 'view_list',
    keywords: 'action, view, list',
  }, {
    icon: 'view_module',
    keywords: 'action, view, module',
  }, {
    icon: 'view_quilt',
    keywords: 'action, view, quilt',
  }, {
    icon: 'view_stream',
    keywords: 'action, view, stream',
  }, {
    icon: 'view_week',
    keywords: 'action, view, week',
  }, {
    icon: 'visibility',
    keywords: 'action, visibility',
  }, {
    icon: 'visibility_off',
    keywords: 'action, visibility, off',
  }, {
    icon: 'watch_later',
    keywords: 'action, watch, later',
  }, {
    icon: 'work',
    keywords: 'action, work',
  }, {
    icon: 'youtube_searched_for',
    keywords: 'action, youtube, searched',
  }, {
    icon: 'zoom_in',
    keywords: 'action, zoom, in',
  }, {
    icon: 'zoom_out',
    keywords: 'action, zoom, out',
  }, {
    icon: 'add_alert',
    keywords: 'alert, add, alert',
  }, {
    icon: 'error',
    keywords: 'alert, error',
  }, {
    icon: 'error_outline',
    keywords: 'alert, error, outline',
  }, {
    icon: 'warning',
    keywords: 'alert, warning',
  }, {
    icon: 'add_to_queue',
    keywords: 'av, add, to, queue',
  }, {
    icon: 'airplay',
    keywords: 'av, airplay',
  }, {
    icon: 'album',
    keywords: 'av, album',
  }, {
    icon: 'art_track',
    keywords: 'av, art, track',
  }, {
    icon: 'av_timer',
    keywords: 'av, av, timer',
  }, {
    icon: 'closed_caption',
    keywords: 'av, closed, caption',
  }, {
    icon: 'equalizer',
    keywords: 'av, equalizer',
  }, {
    icon: 'explicit',
    keywords: 'av, explicit',
  }, {
    icon: 'fast_forward',
    keywords: 'av, fast, forward',
  }, {
    icon: 'fast_rewind',
    keywords: 'av, fast, rewind',
  }, {
    icon: 'fiber_dvr',
    keywords: 'av, fiber, dvr',
  }, {
    icon: 'fiber_manual_record',
    keywords: 'av, fiber, manual, record',
  }, {
    icon: 'fiber_new',
    keywords: 'av, fiber, new',
  }, {
    icon: 'fiber_pin',
    keywords: 'av, fiber, pin',
  }, {
    icon: 'fiber_smart_record',
    keywords: 'av, fiber, smart, record',
  }, {
    icon: 'forward_10',
    keywords: 'av, forward, 10',
  }, {
    icon: 'forward_30',
    keywords: 'av, forward, 30',
  }, {
    icon: 'forward_5',
    keywords: 'av, forward',
  }, {
    icon: 'games',
    keywords: 'av, games',
  }, {
    icon: 'hd',
    keywords: 'av, hd',
  }, {
    icon: 'hearing',
    keywords: 'av, hearing',
  }, {
    icon: 'high_quality',
    keywords: 'av, high, quality',
  }, {
    icon: 'library_add',
    keywords: 'av, library, add',
  }, {
    icon: 'library_books',
    keywords: 'av, library, books',
  }, {
    icon: 'library_music',
    keywords: 'av, library, music',
  }, {
    icon: 'loop',
    keywords: 'av, loop',
  }, {
    icon: 'mic',
    keywords: 'av, mic',
  }, {
    icon: 'mnone',
    keywords: 'av, mic, none',
  }, {
    icon: 'moff',
    keywords: 'av, mic, off',
  }, {
    icon: 'movie',
    keywords: 'av, movie',
  }, {
    icon: 'musvideo',
    keywords: 'av, music, video',
  }, {
    icon: 'new_releases',
    keywords: 'av, new, releases',
  }, {
    icon: 'not_interested',
    keywords: 'av, not, interested',
  }, {
    icon: 'pause',
    keywords: 'av, pause',
  }, {
    icon: 'pause_circle_filled',
    keywords: 'av, pause, circle, filled',
  }, {
    icon: 'pause_circle_outline',
    keywords: 'av, pause, circle, outline',
  }, {
    icon: 'play_arrow',
    keywords: 'av, play, arrow',
  }, {
    icon: 'play_circle_filled',
    keywords: 'av, play, circle, filled',
  }, {
    icon: 'play_circle_outline',
    keywords: 'av, play, circle, outline',
  }, {
    icon: 'playlist_add',
    keywords: 'av, playlist, add',
  }, {
    icon: 'playlist_add_check',
    keywords: 'av, playlist, add, check',
  }, {
    icon: 'playlist_play',
    keywords: 'av, playlist, play',
  }, {
    icon: 'queue',
    keywords: 'av, queue',
  }, {
    icon: 'queue_music',
    keywords: 'av, queue, music',
  }, {
    icon: 'queue_play_next',
    keywords: 'av, queue, play, next',
  }, {
    icon: 'radio',
    keywords: 'av, radio',
  }, {
    icon: 'recent_actors',
    keywords: 'av, recent, actors',
  }, {
    icon: 'remove_from_queue',
    keywords: 'av, remove, from, queue',
  }, {
    icon: 'repeat',
    keywords: 'av, repeat',
  }, {
    icon: 'repeat_one',
    keywords: 'av, repeat, one',
  }, {
    icon: 'replay',
    keywords: 'av, replay',
  }, {
    icon: 'replay_10',
    keywords: 'av, replay, 10',
  }, {
    icon: 'replay_30',
    keywords: 'av, replay, 30',
  }, {
    icon: 'replay_5',
    keywords: 'av, replay',
  }, {
    icon: 'shuffle',
    keywords: 'av, shuffle',
  }, {
    icon: 'skip_next',
    keywords: 'av, skip, next',
  }, {
    icon: 'skip_previous',
    keywords: 'av, skip, previous',
  }, {
    icon: 'slow_motion_video',
    keywords: 'av, slow, motion, video',
  }, {
    icon: 'snooze',
    keywords: 'av, snooze',
  }, {
    icon: 'sort_by_alpha',
    keywords: 'av, sort, by, alpha',
  }, {
    icon: 'stop',
    keywords: 'av, stop',
  }, {
    icon: 'subscriptions',
    keywords: 'av, subscriptions',
  }, {
    icon: 'subtitles',
    keywords: 'av, subtitles',
  }, {
    icon: 'surround_sound',
    keywords: 'av, surround, sound',
  }, {
    icon: 'video_library',
    keywords: 'av, video, library',
  }, {
    icon: 'videocam',
    keywords: 'av, videocam',
  }, {
    icon: 'videocam_off',
    keywords: 'av, videocam, off',
  }, {
    icon: 'volume_down',
    keywords: 'av, volume, down',
  }, {
    icon: 'volume_mute',
    keywords: 'av, volume, mute',
  }, {
    icon: 'volume_off',
    keywords: 'av, volume, off',
  }, {
    icon: 'volume_up',
    keywords: 'av, volume, up',
  }, {
    icon: 'web',
    keywords: 'av, web',
  }, {
    icon: 'web_asset',
    keywords: 'av, web, asset',
  }, {
    icon: 'business',
    keywords: 'communication, business',
  }, {
    icon: 'call',
    keywords: 'communication, call',
  }, {
    icon: 'call_end',
    keywords: 'communication, call, end',
  }, {
    icon: 'call_made',
    keywords: 'communication, call, made',
  }, {
    icon: 'call_merge',
    keywords: 'communication, call, merge',
  }, {
    icon: 'call_missed',
    keywords: 'communication, call, missed',
  }, {
    icon: 'call_missed_outgoing',
    keywords: 'communication, call, missed, outgoing',
  }, {
    icon: 'call_received',
    keywords: 'communication, call, received',
  }, {
    icon: 'call_split',
    keywords: 'communication, call, split',
  }, {
    icon: 'chat',
    keywords: 'communication, chat',
  }, {
    icon: 'chat_bubble',
    keywords: 'communication, chat, bubble',
  }, {
    icon: 'chat_bubble_outline',
    keywords: 'communication, chat, bubble, outline',
  }, {
    icon: 'clear_all',
    keywords: 'communication, clear, all',
  }, {
    icon: 'comment',
    keywords: 'communication, comment',
  }, {
    icon: 'contact_mail',
    keywords: 'communication, contact, mail',
  }, {
    icon: 'contact_phone',
    keywords: 'communication, contact, phone',
  }, {
    icon: 'contacts',
    keywords: 'communication, contacts',
  }, {
    icon: 'dialer_sip',
    keywords: 'communication, dialer, sip',
  }, {
    icon: 'dialpad',
    keywords: 'communication, dialpad',
  }, {
    icon: 'email',
    keywords: 'communication, email',
  }, {
    icon: 'forum',
    keywords: 'communication, forum',
  }, {
    icon: 'import_contacts',
    keywords: 'communication, import, contacts',
  }, {
    icon: 'import_export',
    keywords: 'communication, import, export',
  }, {
    icon: 'invert_colors_off',
    keywords: 'communication, invert, colors, off',
  }, {
    icon: 'live_help',
    keywords: 'communication, live, help',
  }, {
    icon: 'location_off',
    keywords: 'communication, location, off',
  }, {
    icon: 'location_on',
    keywords: 'communication, location, on',
  }, {
    icon: 'mail_outline',
    keywords: 'communication, mail, outline',
  }, {
    icon: 'message',
    keywords: 'communication, message',
  }, {
    icon: 'no_sim',
    keywords: 'communication, no, sim',
  }, {
    icon: 'phone',
    keywords: 'communication, phone',
  }, {
    icon: 'phonelink_erase',
    keywords: 'communication, phonelink, erase',
  }, {
    icon: 'phonelink_lock',
    keywords: 'communication, phonelink, lock',
  }, {
    icon: 'phonelink_ring',
    keywords: 'communication, phonelink, ring',
  }, {
    icon: 'phonelink_setup',
    keywords: 'communication, phonelink, setup',
  }, {
    icon: 'portable_wifi_off',
    keywords: 'communication, portable, wifi, off',
  }, {
    icon: 'present_to_all',
    keywords: 'communication, present, to, all',
  }, {
    icon: 'ring_volume',
    keywords: 'communication, ring, volume',
  }, {
    icon: 'screen_share',
    keywords: 'communication, screen, share',
  }, {
    icon: 'speaker_phone',
    keywords: 'communication, speaker, phone',
  }, {
    icon: 'stay_current_landscape',
    keywords: 'communication, stay, current, landscape',
  }, {
    icon: 'stay_current_portrait',
    keywords: 'communication, stay, current, portrait',
  }, {
    icon: 'stay_primary_landscape',
    keywords: 'communication, stay, primary, landscape',
  }, {
    icon: 'stay_primary_portrait',
    keywords: 'communication, stay, primary, portrait',
  }, {
    icon: 'stop_screen_share',
    keywords: 'communication, stop, screen, share',
  }, {
    icon: 'swap_calls',
    keywords: 'communication, swap, calls',
  }, {
    icon: 'textsms',
    keywords: 'communication, textsms',
  }, {
    icon: 'voicemail',
    keywords: 'communication, voicemail',
  }, {
    icon: 'vpn_key',
    keywords: 'communication, vpn, key',
  }, {
    icon: 'add',
    keywords: 'content, add',
  }, {
    icon: 'add_box',
    keywords: 'content, add, box',
  }, {
    icon: 'add_circle',
    keywords: 'content, add, circle',
  }, {
    icon: 'add_circle_outline',
    keywords: 'content, add, circle, outline',
  }, {
    icon: 'archive',
    keywords: 'content, archive',
  }, {
    icon: 'backspace',
    keywords: 'content, backspace',
  }, {
    icon: 'block',
    keywords: 'content, block',
  }, {
    icon: 'clear',
    keywords: 'content, clear',
  }, {
    icon: 'content_copy',
    keywords: 'content, content, copy',
  }, {
    icon: 'content_cut',
    keywords: 'content, content, cut',
  }, {
    icon: 'content_paste',
    keywords: 'content, content, paste',
  }, {
    icon: 'create',
    keywords: 'content, create',
  }, {
    icon: 'drafts',
    keywords: 'content, drafts',
  }, {
    icon: 'filter_list',
    keywords: 'content, filter, list',
  }, {
    icon: 'flag',
    keywords: 'content, flag',
  }, {
    icon: 'font_download',
    keywords: 'content, font, download',
  }, {
    icon: 'forward',
    keywords: 'content, forward',
  }, {
    icon: 'gesture',
    keywords: 'content, gesture',
  }, {
    icon: 'inbox',
    keywords: 'content, inbox',
  }, {
    icon: 'link',
    keywords: 'content, link',
  }, {
    icon: 'mail',
    keywords: 'content, mail',
  }, {
    icon: 'markunread',
    keywords: 'content, markunread',
  }, {
    icon: 'move_to_inbox',
    keywords: 'content, move, to, inbox',
  }, {
    icon: 'next_week',
    keywords: 'content, next, week',
  }, {
    icon: 'redo',
    keywords: 'content, redo',
  }, {
    icon: 'remove',
    keywords: 'content, remove',
  }, {
    icon: 'remove_circle',
    keywords: 'content, remove, circle',
  }, {
    icon: 'remove_circle_outline',
    keywords: 'content, remove, circle, outline',
  }, {
    icon: 'reply',
    keywords: 'content, reply',
  }, {
    icon: 'reply_all',
    keywords: 'content, reply, all',
  }, {
    icon: 'report',
    keywords: 'content, report',
  }, {
    icon: 'save',
    keywords: 'content, save',
  }, {
    icon: 'select_all',
    keywords: 'content, select, all',
  }, {
    icon: 'send',
    keywords: 'content, send',
  }, {
    icon: 'sort',
    keywords: 'content, sort',
  }, {
    icon: 'text_format',
    keywords: 'content, text, format',
  }, {
    icon: 'unarchive',
    keywords: 'content, unarchive',
  }, {
    icon: 'undo',
    keywords: 'content, undo',
  }, {
    icon: 'weekend',
    keywords: 'content, weekend',
  }, {
    icon: 'access_alarm',
    keywords: 'device, access, alarm',
  }, {
    icon: 'access_alarms',
    keywords: 'device, access, alarms',
  }, {
    icon: 'access_time',
    keywords: 'device, access, time',
  }, {
    icon: 'add_alarm',
    keywords: 'device, add, alarm',
  }, {
    icon: 'airplanemode_active',
    keywords: 'device, airplanemode, active',
  }, {
    icon: 'airplanemode_inactive',
    keywords: 'device, airplanemode, inactive',
  }, {
    icon: 'battery_alert',
    keywords: 'device, battery, alert',
  }, {
    icon: 'battery_charging_full',
    keywords: 'device, battery, charging, full',
  }, {
    icon: 'battery_full',
    keywords: 'device, battery, full',
  }, {
    icon: 'battery_std',
    keywords: 'device, battery, std',
  }, {
    icon: 'battery_unknown',
    keywords: 'device, battery, unknown',
  }, {
    icon: 'bluetooth',
    keywords: 'device, bluetooth',
  }, {
    icon: 'bluetooth_connected',
    keywords: 'device, bluetooth, connected',
  }, {
    icon: 'bluetooth_disabled',
    keywords: 'device, bluetooth, disabled',
  }, {
    icon: 'bluetooth_searching',
    keywords: 'device, bluetooth, searching',
  }, {
    icon: 'brightness_auto',
    keywords: 'device, brightness, auto',
  }, {
    icon: 'brightness_high',
    keywords: 'device, brightness, high',
  }, {
    icon: 'brightness_low',
    keywords: 'device, brightness, low',
  }, {
    icon: 'brightness_medium',
    keywords: 'device, brightness, medium',
  }, {
    icon: 'data_usage',
    keywords: 'device, data, usage',
  }, {
    icon: 'developer_mode',
    keywords: 'device, developer, mode',
  }, {
    icon: 'devices',
    keywords: 'device, devices',
  }, {
    icon: 'dvr',
    keywords: 'device, dvr',
  }, {
    icon: 'gps_fixed',
    keywords: 'device, gps, fixed',
  }, {
    icon: 'gps_not_fixed',
    keywords: 'device, gps, not, fixed',
  }, {
    icon: 'gps_off',
    keywords: 'device, gps, off',
  }, {
    icon: 'grapheq',
    keywords: 'device, graphic, eq',
  }, {
    icon: 'location_disabled',
    keywords: 'device, location, disabled',
  }, {
    icon: 'location_searching',
    keywords: 'device, location, searching',
  }, {
    icon: 'network_cell',
    keywords: 'device, network, cell',
  }, {
    icon: 'network_wifi',
    keywords: 'device, network, wifi',
  }, {
    icon: 'nfc',
    keywords: 'device, nfc',
  }, {
    icon: 'screen_lock_landscape',
    keywords: 'device, screen, lock, landscape',
  }, {
    icon: 'screen_lock_portrait',
    keywords: 'device, screen, lock, portrait',
  }, {
    icon: 'screen_lock_rotation',
    keywords: 'device, screen, lock, rotation',
  }, {
    icon: 'screen_rotation',
    keywords: 'device, screen, rotation',
  }, {
    icon: 'sd_storage',
    keywords: 'device, sd, storage',
  }, {
    icon: 'settings_system_daydream',
    keywords: 'device, settings, system, daydream',
  }, {
    icon: 'signal_cellular_4_bar',
    keywords: 'device, signal, cellular, bar',
  }, {
    icon: 'signal_cellular_connected_no_internet_4_bar',
    keywords: 'device, signal, cellular, connected, no, internet, bar',
  }, {
    icon: 'signal_cellular_no_sim',
    keywords: 'device, signal, cellular, no, sim',
  }, {
    icon: 'signal_cellular_null',
    keywords: 'device, signal, cellular, null',
  }, {
    icon: 'signal_cellular_off',
    keywords: 'device, signal, cellular, off',
  }, {
    icon: 'signal_wifi_4_bar',
    keywords: 'device, signal, wifi, bar',
  }, {
    icon: 'signal_wifi_4_bar_lock',
    keywords: 'device, signal, wifi, bar, lock',
  }, {
    icon: 'signal_wifi_off',
    keywords: 'device, signal, wifi, off',
  }, {
    icon: 'storage',
    keywords: 'device, storage',
  }, {
    icon: 'usb',
    keywords: 'device, usb',
  }, {
    icon: 'wallpaper',
    keywords: 'device, wallpaper',
  }, {
    icon: 'widgets',
    keywords: 'device, widgets',
  }, {
    icon: 'wifi_lock',
    keywords: 'device, wifi, lock',
  }, {
    icon: 'wifi_tethering',
    keywords: 'device, wifi, tethering',
  }, {
    icon: 'attach_file',
    keywords: 'editor, attach, file',
  }, {
    icon: 'attach_money',
    keywords: 'editor, attach, money',
  }, {
    icon: 'border_all',
    keywords: 'editor, border, all',
  }, {
    icon: 'border_bottom',
    keywords: 'editor, border, bottom',
  }, {
    icon: 'border_clear',
    keywords: 'editor, border, clear',
  }, {
    icon: 'border_color',
    keywords: 'editor, border, color',
  }, {
    icon: 'border_horizontal',
    keywords: 'editor, border, horizontal',
  }, {
    icon: 'border_inner',
    keywords: 'editor, border, inner',
  }, {
    icon: 'border_left',
    keywords: 'editor, border, left',
  }, {
    icon: 'border_outer',
    keywords: 'editor, border, outer',
  }, {
    icon: 'border_right',
    keywords: 'editor, border, right',
  }, {
    icon: 'border_style',
    keywords: 'editor, border, style',
  }, {
    icon: 'border_top',
    keywords: 'editor, border, top',
  }, {
    icon: 'border_vertical',
    keywords: 'editor, border, vertical',
  }, {
    icon: 'drag_handle',
    keywords: 'editor, drag, handle',
  }, {
    icon: 'format_align_center',
    keywords: 'editor, format, align, center',
  }, {
    icon: 'format_align_justify',
    keywords: 'editor, format, align, justify',
  }, {
    icon: 'format_align_left',
    keywords: 'editor, format, align, left',
  }, {
    icon: 'format_align_right',
    keywords: 'editor, format, align, right',
  }, {
    icon: 'format_bold',
    keywords: 'editor, format, bold',
  }, {
    icon: 'format_clear',
    keywords: 'editor, format, clear',
  }, {
    icon: 'format_color_fill',
    keywords: 'editor, format, color, fill',
  }, {
    icon: 'format_color_reset',
    keywords: 'editor, format, color, reset',
  }, {
    icon: 'format_color_text',
    keywords: 'editor, format, color, text',
  }, {
    icon: 'format_indent_decrease',
    keywords: 'editor, format, indent, decrease',
  }, {
    icon: 'format_indent_increase',
    keywords: 'editor, format, indent, increase',
  }, {
    icon: 'format_italic',
    keywords: 'editor, format, italic',
  }, {
    icon: 'format_line_spacing',
    keywords: 'editor, format, line, spacing',
  }, {
    icon: 'format_list_bulleted',
    keywords: 'editor, format, list, bulleted',
  }, {
    icon: 'format_list_numbered',
    keywords: 'editor, format, list, numbered',
  }, {
    icon: 'format_paint',
    keywords: 'editor, format, paint',
  }, {
    icon: 'format_quote',
    keywords: 'editor, format, quote',
  }, {
    icon: 'format_shapes',
    keywords: 'editor, format, shapes',
  }, {
    icon: 'format_size',
    keywords: 'editor, format, size',
  }, {
    icon: 'format_strikethrough',
    keywords: 'editor, format, strikethrough',
  }, {
    icon: 'format_textdirection_l_to_r',
    keywords: 'editor, format, textdirection, to',
  }, {
    icon: 'format_textdirection_r_to_l',
    keywords: 'editor, format, textdirection, to',
  }, {
    icon: 'format_underlined',
    keywords: 'editor, format, underlined',
  }, {
    icon: 'functions',
    keywords: 'editor, functions',
  }, {
    icon: 'highlight',
    keywords: 'editor, highlight',
  }, {
    icon: 'insert_chart',
    keywords: 'editor, insert, chart',
  }, {
    icon: 'insert_comment',
    keywords: 'editor, insert, comment',
  }, {
    icon: 'insert_drive_file',
    keywords: 'editor, insert, drive, file',
  }, {
    icon: 'insert_emoticon',
    keywords: 'editor, insert, emoticon',
  }, {
    icon: 'insert_invitation',
    keywords: 'editor, insert, invitation',
  }, {
    icon: 'insert_link',
    keywords: 'editor, insert, link',
  }, {
    icon: 'insert_photo',
    keywords: 'editor, insert, photo',
  }, {
    icon: 'linear_scale',
    keywords: 'editor, linear, scale',
  }, {
    icon: 'merge_type',
    keywords: 'editor, merge, type',
  }, {
    icon: 'mode_comment',
    keywords: 'editor, mode, comment',
  }, {
    icon: 'mode_edit',
    keywords: 'editor, mode, edit',
  }, {
    icon: 'money_off',
    keywords: 'editor, money, off',
  }, {
    icon: 'publish',
    keywords: 'editor, publish',
  }, {
    icon: 'short_text',
    keywords: 'editor, short, text',
  }, {
    icon: 'space_bar',
    keywords: 'editor, space, bar',
  }, {
    icon: 'strikethrough_s',
    keywords: 'editor, strikethrough',
  }, {
    icon: 'text_fields',
    keywords: 'editor, text, fields',
  }, {
    icon: 'vertical_align_bottom',
    keywords: 'editor, vertical, align, bottom',
  }, {
    icon: 'vertical_align_center',
    keywords: 'editor, vertical, align, center',
  }, {
    icon: 'vertical_align_top',
    keywords: 'editor, vertical, align, top',
  }, {
    icon: 'wrap_text',
    keywords: 'editor, wrap, text',
  }, {
    icon: 'attachment',
    keywords: 'file, attachment',
  }, {
    icon: 'cloud',
    keywords: 'file, cloud',
  }, {
    icon: 'cloud_circle',
    keywords: 'file, cloud, circle',
  }, {
    icon: 'cloud_done',
    keywords: 'file, cloud, done',
  }, {
    icon: 'cloud_download',
    keywords: 'file, cloud, download',
  }, {
    icon: 'cloud_off',
    keywords: 'file, cloud, off',
  }, {
    icon: 'cloud_queue',
    keywords: 'file, cloud, queue',
  }, {
    icon: 'cloud_upload',
    keywords: 'file, cloud, upload',
  }, {
    icon: 'create_new_folder',
    keywords: 'file, create, new, folder',
  }, {
    icon: 'file_download',
    keywords: 'file, file, download',
  }, {
    icon: 'file_upload',
    keywords: 'file, file, upload',
  }, {
    icon: 'folder',
    keywords: 'file, folder',
  }, {
    icon: 'folder_open',
    keywords: 'file, folder, open',
  }, {
    icon: 'folder_shared',
    keywords: 'file, folder, shared',
  }, {
    icon: 'cast',
    keywords: 'hardware, cast',
  }, {
    icon: 'cast_connected',
    keywords: 'hardware, cast, connected',
  }, {
    icon: 'computer',
    keywords: 'hardware, computer',
  }, {
    icon: 'desktop_mac',
    keywords: 'hardware, desktop, mac',
  }, {
    icon: 'desktop_windows',
    keywords: 'hardware, desktop, windows',
  }, {
    icon: 'developer_board',
    keywords: 'hardware, developer, board',
  }, {
    icon: 'device_hub',
    keywords: 'hardware, device, hub',
  }, {
    icon: 'devices_other',
    keywords: 'hardware, devices, other',
  }, {
    icon: 'dock',
    keywords: 'hardware, dock',
  }, {
    icon: 'gamepad',
    keywords: 'hardware, gamepad',
  }, {
    icon: 'headset',
    keywords: 'hardware, headset',
  }, {
    icon: 'headset_mic',
    keywords: 'hardware, headset, mic',
  }, {
    icon: 'keyboard',
    keywords: 'hardware, keyboard',
  }, {
    icon: 'keyboard_arrow_down',
    keywords: 'hardware, keyboard, arrow, down',
  }, {
    icon: 'keyboard_arrow_left',
    keywords: 'hardware, keyboard, arrow, left',
  }, {
    icon: 'keyboard_arrow_right',
    keywords: 'hardware, keyboard, arrow, right',
  }, {
    icon: 'keyboard_arrow_up',
    keywords: 'hardware, keyboard, arrow, up',
  }, {
    icon: 'keyboard_backspace',
    keywords: 'hardware, keyboard, backspace',
  }, {
    icon: 'keyboard_capslock',
    keywords: 'hardware, keyboard, capslock',
  }, {
    icon: 'keyboard_hide',
    keywords: 'hardware, keyboard, hide',
  }, {
    icon: 'keyboard_return',
    keywords: 'hardware, keyboard, return',
  }, {
    icon: 'keyboard_tab',
    keywords: 'hardware, keyboard, tab',
  }, {
    icon: 'keyboard_voice',
    keywords: 'hardware, keyboard, voice',
  }, {
    icon: 'laptop',
    keywords: 'hardware, laptop',
  }, {
    icon: 'laptop_chromebook',
    keywords: 'hardware, laptop, chromebook',
  }, {
    icon: 'laptop_mac',
    keywords: 'hardware, laptop, mac',
  }, {
    icon: 'laptop_windows',
    keywords: 'hardware, laptop, windows',
  }, {
    icon: 'memory',
    keywords: 'hardware, memory',
  }, {
    icon: 'mouse',
    keywords: 'hardware, mouse',
  }, {
    icon: 'phone_android',
    keywords: 'hardware, phone, android',
  }, {
    icon: 'phone_iphone',
    keywords: 'hardware, phone, iphone',
  }, {
    icon: 'phonelink',
    keywords: 'hardware, phonelink',
  }, {
    icon: 'phonelink_off',
    keywords: 'hardware, phonelink, off',
  }, {
    icon: 'power_input',
    keywords: 'hardware, power, input',
  }, {
    icon: 'router',
    keywords: 'hardware, router',
  }, {
    icon: 'scanner',
    keywords: 'hardware, scanner',
  }, {
    icon: 'security',
    keywords: 'hardware, security',
  }, {
    icon: 'sim_card',
    keywords: 'hardware, sim, card',
  }, {
    icon: 'smartphone',
    keywords: 'hardware, smartphone',
  }, {
    icon: 'speaker',
    keywords: 'hardware, speaker',
  }, {
    icon: 'speaker_group',
    keywords: 'hardware, speaker, group',
  }, {
    icon: 'tablet',
    keywords: 'hardware, tablet',
  }, {
    icon: 'tablet_android',
    keywords: 'hardware, tablet, android',
  }, {
    icon: 'tablet_mac',
    keywords: 'hardware, tablet, mac',
  }, {
    icon: 'toys',
    keywords: 'hardware, toys',
  }, {
    icon: 'tv',
    keywords: 'hardware, tv',
  }, {
    icon: 'videogame_asset',
    keywords: 'hardware, videogame, asset',
  }, {
    icon: 'watch',
    keywords: 'hardware, watch',
  }, {
    icon: 'add_a_photo',
    keywords: 'image, add, photo',
  }, {
    icon: 'add_to_photos',
    keywords: 'image, add, to, photos',
  }, {
    icon: 'adjust',
    keywords: 'image, adjust',
  }, {
    icon: 'assistant',
    keywords: 'image, assistant',
  }, {
    icon: 'assistant_photo',
    keywords: 'image, assistant, photo',
  }, {
    icon: 'audiotrack',
    keywords: 'image, audiotrack',
  }, {
    icon: 'blur_circular',
    keywords: 'image, blur, circular',
  }, {
    icon: 'blur_linear',
    keywords: 'image, blur, linear',
  }, {
    icon: 'blur_off',
    keywords: 'image, blur, off',
  }, {
    icon: 'blur_on',
    keywords: 'image, blur, on',
  }, {
    icon: 'brightness_1',
    keywords: 'image, brightness',
  }, {
    icon: 'brightness_2',
    keywords: 'image, brightness',
  }, {
    icon: 'brightness_3',
    keywords: 'image, brightness',
  }, {
    icon: 'brightness_4',
    keywords: 'image, brightness',
  }, {
    icon: 'brightness_5',
    keywords: 'image, brightness',
  }, {
    icon: 'brightness_6',
    keywords: 'image, brightness',
  }, {
    icon: 'brightness_7',
    keywords: 'image, brightness',
  }, {
    icon: 'broken_image',
    keywords: 'image, broken, image',
  }, {
    icon: 'brush',
    keywords: 'image, brush',
  }, {
    icon: 'camera',
    keywords: 'image, camera',
  }, {
    icon: 'camera_alt',
    keywords: 'image, camera, alt',
  }, {
    icon: 'camera_front',
    keywords: 'image, camera, front',
  }, {
    icon: 'camera_rear',
    keywords: 'image, camera, rear',
  }, {
    icon: 'camera_roll',
    keywords: 'image, camera, roll',
  }, {
    icon: 'center_focus_strong',
    keywords: 'image, center, focus, strong',
  }, {
    icon: 'center_focus_weak',
    keywords: 'image, center, focus, weak',
  }, {
    icon: 'collections',
    keywords: 'image, collections',
  }, {
    icon: 'collections_bookmark',
    keywords: 'image, collections, bookmark',
  }, {
    icon: 'color_lens',
    keywords: 'image, color, lens',
  }, {
    icon: 'colorize',
    keywords: 'image, colorize',
  }, {
    icon: 'compare',
    keywords: 'image, compare',
  }, {
    icon: 'control_point',
    keywords: 'image, control, point',
  }, {
    icon: 'control_point_duplicate',
    keywords: 'image, control, point, duplicate',
  }, {
    icon: 'crop',
    keywords: 'image, crop',
  }, {
    icon: 'crop_16_9',
    keywords: 'image, crop, 16',
  }, {
    icon: 'crop_3_2',
    keywords: 'image, crop',
  }, {
    icon: 'crop_5_4',
    keywords: 'image, crop',
  }, {
    icon: 'crop_7_5',
    keywords: 'image, crop',
  }, {
    icon: 'crop_din',
    keywords: 'image, crop, din',
  }, {
    icon: 'crop_free',
    keywords: 'image, crop, free',
  }, {
    icon: 'crop_landscape',
    keywords: 'image, crop, landscape',
  }, {
    icon: 'crop_original',
    keywords: 'image, crop, original',
  }, {
    icon: 'crop_portrait',
    keywords: 'image, crop, portrait',
  }, {
    icon: 'crop_rotate',
    keywords: 'image, crop, rotate',
  }, {
    icon: 'crop_square',
    keywords: 'image, crop, square',
  }, {
    icon: 'dehaze',
    keywords: 'image, dehaze',
  }, {
    icon: 'details',
    keywords: 'image, details',
  }, {
    icon: 'edit',
    keywords: 'image, edit',
  }, {
    icon: 'exposure',
    keywords: 'image, exposure',
  }, {
    icon: 'exposure_neg_1',
    keywords: 'image, exposure, neg',
  }, {
    icon: 'exposure_neg_2',
    keywords: 'image, exposure, neg',
  }, {
    icon: 'exposure_plus_1',
    keywords: 'image, exposure, plus',
  }, {
    icon: 'exposure_plus_2',
    keywords: 'image, exposure, plus',
  }, {
    icon: 'exposure_zero',
    keywords: 'image, exposure, zero',
  }, {
    icon: 'filter',
    keywords: 'image, filter',
  }, {
    icon: 'filter_1',
    keywords: 'image, filter',
  }, {
    icon: 'filter_2',
    keywords: 'image, filter',
  }, {
    icon: 'filter_3',
    keywords: 'image, filter',
  }, {
    icon: 'filter_4',
    keywords: 'image, filter',
  }, {
    icon: 'filter_5',
    keywords: 'image, filter',
  }, {
    icon: 'filter_6',
    keywords: 'image, filter',
  }, {
    icon: 'filter_7',
    keywords: 'image, filter',
  }, {
    icon: 'filter_8',
    keywords: 'image, filter',
  }, {
    icon: 'filter_9',
    keywords: 'image, filter',
  }, {
    icon: 'filter_9_plus',
    keywords: 'image, filter, plus',
  }, {
    icon: 'filter_b_and_w',
    keywords: 'image, filter',
  }, {
    icon: 'filter_center_focus',
    keywords: 'image, filter, center, focus',
  }, {
    icon: 'filter_drama',
    keywords: 'image, filter, drama',
  }, {
    icon: 'filter_frames',
    keywords: 'image, filter, frames',
  }, {
    icon: 'filter_hdr',
    keywords: 'image, filter, hdr',
  }, {
    icon: 'filter_none',
    keywords: 'image, filter, none',
  }, {
    icon: 'filter_tilt_shift',
    keywords: 'image, filter, tilt, shift',
  }, {
    icon: 'filter_vintage',
    keywords: 'image, filter, vintage',
  }, {
    icon: 'flare',
    keywords: 'image, flare',
  }, {
    icon: 'flash_auto',
    keywords: 'image, flash, auto',
  }, {
    icon: 'flash_off',
    keywords: 'image, flash, off',
  }, {
    icon: 'flash_on',
    keywords: 'image, flash, on',
  }, {
    icon: 'flip',
    keywords: 'image, flip',
  }, {
    icon: 'gradient',
    keywords: 'image, gradient',
  }, {
    icon: 'grain',
    keywords: 'image, grain',
  }, {
    icon: 'grid_off',
    keywords: 'image, grid, off',
  }, {
    icon: 'grid_on',
    keywords: 'image, grid, on',
  }, {
    icon: 'hdr_off',
    keywords: 'image, hdr, off',
  }, {
    icon: 'hdr_on',
    keywords: 'image, hdr, on',
  }, {
    icon: 'hdr_strong',
    keywords: 'image, hdr, strong',
  }, {
    icon: 'hdr_weak',
    keywords: 'image, hdr, weak',
  }, {
    icon: 'healing',
    keywords: 'image, healing',
  }, {
    icon: 'image',
    keywords: 'image, image',
  }, {
    icon: 'image_aspect_ratio',
    keywords: 'image, image, aspect, ratio',
  }, {
    icon: 'iso',
    keywords: 'image, iso',
  }, {
    icon: 'landscape',
    keywords: 'image, landscape',
  }, {
    icon: 'leak_add',
    keywords: 'image, leak, add',
  }, {
    icon: 'leak_remove',
    keywords: 'image, leak, remove',
  }, {
    icon: 'lens',
    keywords: 'image, lens',
  }, {
    icon: 'linked_camera',
    keywords: 'image, linked, camera',
  }, {
    icon: 'looks',
    keywords: 'image, looks',
  }, {
    icon: 'looks_3',
    keywords: 'image, looks',
  }, {
    icon: 'looks_4',
    keywords: 'image, looks',
  }, {
    icon: 'looks_5',
    keywords: 'image, looks',
  }, {
    icon: 'looks_6',
    keywords: 'image, looks',
  }, {
    icon: 'looks_one',
    keywords: 'image, looks, one',
  }, {
    icon: 'looks_two',
    keywords: 'image, looks, two',
  }, {
    icon: 'loupe',
    keywords: 'image, loupe',
  }, {
    icon: 'monochrome_photos',
    keywords: 'image, monochrome, photos',
  }, {
    icon: 'movie_creation',
    keywords: 'image, movie, creation',
  }, {
    icon: 'movie_filter',
    keywords: 'image, movie, filter',
  }, {
    icon: 'musnote',
    keywords: 'image, music, note',
  }, {
    icon: 'nature',
    keywords: 'image, nature',
  }, {
    icon: 'nature_people',
    keywords: 'image, nature, people',
  }, {
    icon: 'navigate_before',
    keywords: 'image, navigate, before',
  }, {
    icon: 'navigate_next',
    keywords: 'image, navigate, next',
  }, {
    icon: 'palette',
    keywords: 'image, palette',
  }, {
    icon: 'panorama',
    keywords: 'image, panorama',
  }, {
    icon: 'panorama_fish_eye',
    keywords: 'image, panorama, fish, eye',
  }, {
    icon: 'panorama_horizontal',
    keywords: 'image, panorama, horizontal',
  }, {
    icon: 'panorama_vertical',
    keywords: 'image, panorama, vertical',
  }, {
    icon: 'panorama_wide_angle',
    keywords: 'image, panorama, wide, angle',
  }, {
    icon: 'photo',
    keywords: 'image, photo',
  }, {
    icon: 'photo_album',
    keywords: 'image, photo, album',
  }, {
    icon: 'photo_camera',
    keywords: 'image, photo, camera',
  }, {
    icon: 'photo_filter',
    keywords: 'image, photo, filter',
  }, {
    icon: 'photo_library',
    keywords: 'image, photo, library',
  }, {
    icon: 'photo_size_select_actual',
    keywords: 'image, photo, size, select, actual',
  }, {
    icon: 'photo_size_select_large',
    keywords: 'image, photo, size, select, large',
  }, {
    icon: 'photo_size_select_small',
    keywords: 'image, photo, size, select, small',
  }, {
    icon: 'picture_as_pdf',
    keywords: 'image, picture, as, pdf',
  }, {
    icon: 'portrait',
    keywords: 'image, portrait',
  }, {
    icon: 'remove_red_eye',
    keywords: 'image, remove, red, eye',
  }, {
    icon: 'rotate_90_degrees_ccw',
    keywords: 'image, rotate, 90, degrees, ccw',
  }, {
    icon: 'rotate_left',
    keywords: 'image, rotate, left',
  }, {
    icon: 'rotate_right',
    keywords: 'image, rotate, right',
  }, {
    icon: 'slideshow',
    keywords: 'image, slideshow',
  }, {
    icon: 'straighten',
    keywords: 'image, straighten',
  }, {
    icon: 'style',
    keywords: 'image, style',
  }, {
    icon: 'switch_camera',
    keywords: 'image, switch, camera',
  }, {
    icon: 'switch_video',
    keywords: 'image, switch, video',
  }, {
    icon: 'tag_faces',
    keywords: 'image, tag, faces',
  }, {
    icon: 'texture',
    keywords: 'image, texture',
  }, {
    icon: 'timelapse',
    keywords: 'image, timelapse',
  }, {
    icon: 'timer',
    keywords: 'image, timer',
  }, {
    icon: 'timer_10',
    keywords: 'image, timer, 10',
  }, {
    icon: 'timer_3',
    keywords: 'image, timer',
  }, {
    icon: 'timer_off',
    keywords: 'image, timer, off',
  }, {
    icon: 'tonality',
    keywords: 'image, tonality',
  }, {
    icon: 'transform',
    keywords: 'image, transform',
  }, {
    icon: 'tune',
    keywords: 'image, tune',
  }, {
    icon: 'view_comfy',
    keywords: 'image, view, comfy',
  }, {
    icon: 'view_compact',
    keywords: 'image, view, compact',
  }, {
    icon: 'vignette',
    keywords: 'image, vignette',
  }, {
    icon: 'wb_auto',
    keywords: 'image, wb, auto',
  }, {
    icon: 'wb_cloudy',
    keywords: 'image, wb, cloudy',
  }, {
    icon: 'wb_incandescent',
    keywords: 'image, wb, incandescent',
  }, {
    icon: 'wb_iridescent',
    keywords: 'image, wb, iridescent',
  }, {
    icon: 'wb_sunny',
    keywords: 'image, wb, sunny',
  }, {
    icon: 'add_location',
    keywords: 'maps, add, location',
  }, {
    icon: 'beenhere',
    keywords: 'maps, beenhere',
  }, {
    icon: 'directions',
    keywords: 'maps, directions',
  }, {
    icon: 'directions_bike',
    keywords: 'maps, directions, bike',
  }, {
    icon: 'directions_boat',
    keywords: 'maps, directions, boat',
  }, {
    icon: 'directions_bus',
    keywords: 'maps, directions, bus',
  }, {
    icon: 'directions_car',
    keywords: 'maps, directions, car',
  }, {
    icon: 'directions_railway',
    keywords: 'maps, directions, railway',
  }, {
    icon: 'directions_run',
    keywords: 'maps, directions, run',
  }, {
    icon: 'directions_subway',
    keywords: 'maps, directions, subway',
  }, {
    icon: 'directions_transit',
    keywords: 'maps, directions, transit',
  }, {
    icon: 'directions_walk',
    keywords: 'maps, directions, walk',
  }, {
    icon: 'edit_location',
    keywords: 'maps, edit, location',
  }, {
    icon: 'flight',
    keywords: 'maps, flight',
  }, {
    icon: 'hotel',
    keywords: 'maps, hotel',
  }, {
    icon: 'layers',
    keywords: 'maps, layers',
  }, {
    icon: 'layers_clear',
    keywords: 'maps, layers, clear',
  }, {
    icon: 'local_activity',
    keywords: 'maps, local, activity',
  }, {
    icon: 'local_airport',
    keywords: 'maps, local, airport',
  }, {
    icon: 'local_atm',
    keywords: 'maps, local, atm',
  }, {
    icon: 'local_bar',
    keywords: 'maps, local, bar',
  }, {
    icon: 'local_cafe',
    keywords: 'maps, local, cafe',
  }, {
    icon: 'local_car_wash',
    keywords: 'maps, local, car, wash',
  }, {
    icon: 'local_convenience_store',
    keywords: 'maps, local, convenience, store',
  }, {
    icon: 'local_dining',
    keywords: 'maps, local, dining',
  }, {
    icon: 'local_drink',
    keywords: 'maps, local, drink',
  }, {
    icon: 'local_florist',
    keywords: 'maps, local, florist',
  }, {
    icon: 'local_gas_station',
    keywords: 'maps, local, gas, station',
  }, {
    icon: 'local_grocery_store',
    keywords: 'maps, local, grocery, store',
  }, {
    icon: 'local_hospital',
    keywords: 'maps, local, hospital',
  }, {
    icon: 'local_hotel',
    keywords: 'maps, local, hotel',
  }, {
    icon: 'local_laundry_service',
    keywords: 'maps, local, laundry, service',
  }, {
    icon: 'local_library',
    keywords: 'maps, local, library',
  }, {
    icon: 'local_mall',
    keywords: 'maps, local, mall',
  }, {
    icon: 'local_movies',
    keywords: 'maps, local, movies',
  }, {
    icon: 'local_offer',
    keywords: 'maps, local, offer',
  }, {
    icon: 'local_parking',
    keywords: 'maps, local, parking',
  }, {
    icon: 'local_pharmacy',
    keywords: 'maps, local, pharmacy',
  }, {
    icon: 'local_phone',
    keywords: 'maps, local, phone',
  }, {
    icon: 'local_pizza',
    keywords: 'maps, local, pizza',
  }, {
    icon: 'local_play',
    keywords: 'maps, local, play',
  }, {
    icon: 'local_post_office',
    keywords: 'maps, local, post, office',
  }, {
    icon: 'local_printshop',
    keywords: 'maps, local, printshop',
  }, {
    icon: 'local_see',
    keywords: 'maps, local, see',
  }, {
    icon: 'local_shipping',
    keywords: 'maps, local, shipping',
  }, {
    icon: 'local_taxi',
    keywords: 'maps, local, taxi',
  }, {
    icon: 'map',
    keywords: 'maps, map',
  }, {
    icon: 'my_location',
    keywords: 'maps, my, location',
  }, {
    icon: 'navigation',
    keywords: 'maps, navigation',
  }, {
    icon: 'near_me',
    keywords: 'maps, near, me',
  }, {
    icon: 'person_pin',
    keywords: 'maps, person, pin',
  }, {
    icon: 'person_pin_circle',
    keywords: 'maps, person, pin, circle',
  }, {
    icon: 'pin_drop',
    keywords: 'maps, pin, drop',
  }, {
    icon: 'place',
    keywords: 'maps, place',
  }, {
    icon: 'rate_review',
    keywords: 'maps, rate, review',
  }, {
    icon: 'restaurant_menu',
    keywords: 'maps, restaurant, menu',
  }, {
    icon: 'satellite',
    keywords: 'maps, satellite',
  }, {
    icon: 'store_mall_directory',
    keywords: 'maps, store, mall, directory',
  }, {
    icon: 'terrain',
    keywords: 'maps, terrain',
  }, {
    icon: 'traffic',
    keywords: 'maps, traffic',
  }, {
    icon: 'zoom_out_map',
    keywords: 'maps, zoom, out, map',
  }, {
    icon: 'apps',
    keywords: 'navigation, apps',
  }, {
    icon: 'arrow_back',
    keywords: 'navigation, arrow, back',
  }, {
    icon: 'arrow_downward',
    keywords: 'navigation, arrow, downward',
  }, {
    icon: 'arrow_drop_down',
    keywords: 'navigation, arrow, drop, down',
  }, {
    icon: 'arrow_drop_down_circle',
    keywords: 'navigation, arrow, drop, down, circle',
  }, {
    icon: 'arrow_drop_up',
    keywords: 'navigation, arrow, drop, up',
  }, {
    icon: 'arrow_forward',
    keywords: 'navigation, arrow, forward',
  }, {
    icon: 'arrow_upward',
    keywords: 'navigation, arrow, upward',
  }, {
    icon: 'cancel',
    keywords: 'navigation, cancel',
  }, {
    icon: 'check',
    keywords: 'navigation, check',
  }, {
    icon: 'chevron_left',
    keywords: 'navigation, chevron, left',
  }, {
    icon: 'chevron_right',
    keywords: 'navigation, chevron, right',
  }, {
    icon: 'close',
    keywords: 'navigation, close',
  }, {
    icon: 'expand_less',
    keywords: 'navigation, expand, less',
  }, {
    icon: 'expand_more',
    keywords: 'navigation, expand, more',
  }, {
    icon: 'fullscreen',
    keywords: 'navigation, fullscreen',
  }, {
    icon: 'fullscreen_exit',
    keywords: 'navigation, fullscreen, exit',
  }, {
    icon: 'menu',
    keywords: 'navigation, menu',
  }, {
    icon: 'more_horiz',
    keywords: 'navigation, more, horiz',
  }, {
    icon: 'more_vert',
    keywords: 'navigation, more, vert',
  }, {
    icon: 'refresh',
    keywords: 'navigation, refresh',
  }, {
    icon: 'subdirectory_arrow_left',
    keywords: 'navigation, subdirectory, arrow, left',
  }, {
    icon: 'subdirectory_arrow_right',
    keywords: 'navigation, subdirectory, arrow, right',
  }, {
    icon: 'adb',
    keywords: 'notification, adb',
  }, {
    icon: 'airline_seat_flat',
    keywords: 'notification, airline, seat, flat',
  }, {
    icon: 'airline_seat_flat_angled',
    keywords: 'notification, airline, seat, flat, angled',
  }, {
    icon: 'airline_seat_individual_suite',
    keywords: 'notification, airline, seat, individual, suite',
  }, {
    icon: 'airline_seat_legroom_extra',
    keywords: 'notification, airline, seat, legroom, extra',
  }, {
    icon: 'airline_seat_legroom_normal',
    keywords: 'notification, airline, seat, legroom, normal',
  }, {
    icon: 'airline_seat_legroom_reduced',
    keywords: 'notification, airline, seat, legroom, reduced',
  }, {
    icon: 'airline_seat_recline_extra',
    keywords: 'notification, airline, seat, recline, extra',
  }, {
    icon: 'airline_seat_recline_normal',
    keywords: 'notification, airline, seat, recline, normal',
  }, {
    icon: 'bluetooth_audio',
    keywords: 'notification, bluetooth, audio',
  }, {
    icon: 'confirmation_number',
    keywords: 'notification, confirmation, number',
  }, {
    icon: 'disc_full',
    keywords: 'notification, disc, full',
  }, {
    icon: 'do_not_disturb',
    keywords: 'notification, do, not, disturb',
  }, {
    icon: 'do_not_disturb_alt',
    keywords: 'notification, do, not, disturb, alt',
  }, {
    icon: 'drive_eta',
    keywords: 'notification, drive, eta',
  }, {
    icon: 'enhanced_encryption',
    keywords: 'notification, enhanced, encryption',
  }, {
    icon: 'event_available',
    keywords: 'notification, event, available',
  }, {
    icon: 'event_busy',
    keywords: 'notification, event, busy',
  }, {
    icon: 'event_note',
    keywords: 'notification, event, note',
  }, {
    icon: 'folder_special',
    keywords: 'notification, folder, special',
  }, {
    icon: 'live_tv',
    keywords: 'notification, live, tv',
  }, {
    icon: 'mms',
    keywords: 'notification, mms',
  }, {
    icon: 'more',
    keywords: 'notification, more',
  }, {
    icon: 'network_check',
    keywords: 'notification, network, check',
  }, {
    icon: 'network_locked',
    keywords: 'notification, network, locked',
  }, {
    icon: 'no_encryption',
    keywords: 'notification, no, encryption',
  }, {
    icon: 'ondemand_video',
    keywords: 'notification, ondemand, video',
  }, {
    icon: 'personal_video',
    keywords: 'notification, personal, video',
  }, {
    icon: 'phone_bluetooth_speaker',
    keywords: 'notification, phone, bluetooth, speaker',
  }, {
    icon: 'phone_forwarded',
    keywords: 'notification, phone, forwarded',
  }, {
    icon: 'phone_in_talk',
    keywords: 'notification, phone, in, talk',
  }, {
    icon: 'phone_locked',
    keywords: 'notification, phone, locked',
  }, {
    icon: 'phone_missed',
    keywords: 'notification, phone, missed',
  }, {
    icon: 'phone_paused',
    keywords: 'notification, phone, paused',
  }, {
    icon: 'power',
    keywords: 'notification, power',
  }, {
    icon: 'sd_card',
    keywords: 'notification, sd, card',
  }, {
    icon: 'sim_card_alert',
    keywords: 'notification, sim, card, alert',
  }, {
    icon: 'sms',
    keywords: 'notification, sms',
  }, {
    icon: 'sms_failed',
    keywords: 'notification, sms, failed',
  }, {
    icon: 'sync',
    keywords: 'notification, sync',
  }, {
    icon: 'sync_disabled',
    keywords: 'notification, sync, disabled',
  }, {
    icon: 'sync_problem',
    keywords: 'notification, sync, problem',
  }, {
    icon: 'system_update',
    keywords: 'notification, system, update',
  }, {
    icon: 'tap_and_play',
    keywords: 'notification, tap, play',
  }, {
    icon: 'time_to_leave',
    keywords: 'notification, time, to, leave',
  }, {
    icon: 'vibration',
    keywords: 'notification, vibration',
  }, {
    icon: 'voice_chat',
    keywords: 'notification, voice, chat',
  }, {
    icon: 'vpn_lock',
    keywords: 'notification, vpn, lock',
  }, {
    icon: 'wc',
    keywords: 'notification, wc',
  }, {
    icon: 'wifi',
    keywords: 'notification, wifi',
  }, {
    icon: 'ac_unit',
    keywords: 'places, ac, unit',
  }, {
    icon: 'airport_shuttle',
    keywords: 'places, airport, shuttle',
  }, {
    icon: 'all_inclusive',
    keywords: 'places, all, inclusive',
  }, {
    icon: 'beach_access',
    keywords: 'places, beach, access',
  }, {
    icon: 'business_center',
    keywords: 'places, business, center',
  }, {
    icon: 'casino',
    keywords: 'places, casino',
  }, {
    icon: 'child_care',
    keywords: 'places, child, care',
  }, {
    icon: 'child_friendly',
    keywords: 'places, child, friendly',
  }, {
    icon: 'fitness_center',
    keywords: 'places, fitness, center',
  }, {
    icon: 'free_breakfast',
    keywords: 'places, free, breakfast',
  }, {
    icon: 'golf_course',
    keywords: 'places, golf, course',
  }, {
    icon: 'hot_tub',
    keywords: 'places, hot, tub',
  }, {
    icon: 'kitchen',
    keywords: 'places, kitchen',
  }, {
    icon: 'pool',
    keywords: 'places, pool',
  }, {
    icon: 'room_service',
    keywords: 'places, room, service',
  }, {
    icon: 'rv_hookup',
    keywords: 'places, rv, hookup',
  }, {
    icon: 'smoke_free',
    keywords: 'places, smoke, free',
  }, {
    icon: 'smoking_rooms',
    keywords: 'places, smoking, rooms',
  }, {
    icon: 'spa',
    keywords: 'places, spa',
  }, {
    icon: 'cake',
    keywords: 'social, cake',
  }, {
    icon: 'domain',
    keywords: 'social, domain',
  }, {
    icon: 'group',
    keywords: 'social, group',
  }, {
    icon: 'group_add',
    keywords: 'social, group, add',
  }, {
    icon: 'location_city',
    keywords: 'social, location, city',
  }, {
    icon: 'mood',
    keywords: 'social, mood',
  }, {
    icon: 'mood_bad',
    keywords: 'social, mood, bad',
  }, {
    icon: 'notifications',
    keywords: 'social, notifications',
  }, {
    icon: 'notifications_active',
    keywords: 'social, notifications, active',
  }, {
    icon: 'notifications_none',
    keywords: 'social, notifications, none',
  }, {
    icon: 'notifications_off',
    keywords: 'social, notifications, off',
  }, {
    icon: 'notifications_paused',
    keywords: 'social, notifications, paused',
  }, {
    icon: 'pages',
    keywords: 'social, pages',
  }, {
    icon: 'party_mode',
    keywords: 'social, party, mode',
  }, {
    icon: 'people',
    keywords: 'social, people',
  }, {
    icon: 'people_outline',
    keywords: 'social, people, outline',
  }, {
    icon: 'person',
    keywords: 'social, person',
  }, {
    icon: 'person_add',
    keywords: 'social, person, add',
  }, {
    icon: 'person_outline',
    keywords: 'social, person, outline',
  }, {
    icon: 'plus_one',
    keywords: 'social, plus, one',
  }, {
    icon: 'poll',
    keywords: 'social, poll',
  }, {
    icon: 'public',
    keywords: 'social, public',
  }, {
    icon: 'school',
    keywords: 'social, school',
  }, {
    icon: 'share',
    keywords: 'social, share',
  }, {
    icon: 'whatshot',
    keywords: 'social, whatshot',
  }, {
    icon: 'check_box',
    keywords: 'toggle, check, box',
  }, {
    icon: 'check_box_outline_blank',
    keywords: 'toggle, check, box, outline, blank',
  }, {
    icon: 'indeterminate_check_box',
    keywords: 'toggle, indeterminate, check, box',
  }, {
    icon: 'radio_button_checked',
    keywords: 'toggle, radio, button, checked',
  }, {
    icon: 'radio_button_unchecked',
    keywords: 'toggle, radio, button, unchecked',
  }, {
    icon: 'star',
    keywords: 'toggle, star',
  }, {
    icon: 'star_border',
    keywords: 'toggle, star, border',
  }, {
    icon: 'star_half',
    keywords: 'toggle, star, half',
  },
];