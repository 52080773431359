import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import AddCard from './AddCard';
import Card from './Card';
import Filter from '../Shared/Filter';
import CustomDroppable from '../Shared/CustomDroppable';
import { cardsFilter } from 'core/helpers/filter';
import { setLibraryFilterAction } from 'core/store/actions/filterActions';
import { createCardAction } from 'core/store/actions/cardActions';
import './styles.scss';
import { 
  useAuth,
  useMyCards
} from 'core/store/selectors';
import useDragDrop from 'core/hooks/useDragDrop';
import { getCardsByList } from 'core/helpers/cards';
import { LOCATION_LIBRARY } from 'core/constants';
  

const CardsLibrary = () => {
  const dispatch = useDispatch();
  
  const filter = useSelector((state) => state.filter.library);
  const { libraryColumns } = useDragDrop();
  
  const auth = useAuth();
  
  const cardsFromState = useMyCards();
  
  const onCardCreate = (card) => {
    dispatch(createCardAction({userId: auth?.uid, cardData: card}));
  };

  const onDraggableItemChange = (colId, card) => {
    // dispatch(updateCardAction({ colId, card }));
  };

  const onDraggableItemCopy = (colId, card) => {
    // dispatch(sidebarRemoveCardAction(card))
  };
  const handleCardsFilterChange = (name, value) => {
    dispatch(setLibraryFilterAction({ name, value }));
  };

  return (
    <div>
      <div style={{ marginBottom: 15, minHeight: 80 }}>
        <Grid container spacing={3}>
          <Grid item md={10}>
            <Filter filter={filter} onFilterChange={handleCardsFilterChange} />
          </Grid>
          <Grid item md={2}>
            <AddCard style={{ marginTop: 15 }} onSubmit={onCardCreate} />
          </Grid>
        </Grid>
      </div>
      <div className="card-library">
        {libraryColumns && (
          <div className="card-library__board">
            {libraryColumns.map((column) => (
              <div
                className="card-library__column"
                key={column.id}
              >
                <CustomDroppable
                  id={{ id: column.id }}
                  cardIdPrefix={column.id}
                  renderDraggableComponent={({ item, ...props }) => (
                    <Card
                      isHorizontal
                      id={item.id}
                      onCardChange={(card) =>
                        onDraggableItemChange(column.id, card)
                      }
                      onCardCopy={(card) =>
                        onDraggableItemCopy(column.id, card)
                      }
                      card={item}
                      board={false}
                      type="board"
                      location={LOCATION_LIBRARY}
                      {...props}
                    />
                  )}
                  data={cardsFilter(getCardsByList(cardsFromState, column.cards), filter)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardsLibrary;
