import React from 'react';
import { SliderPicker as Picker } from 'react-color';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

import './styles.scss';
import clsx from 'clsx';

const propTypes = {
  color: PropTypes.string,
  onChangeComplete: PropTypes.func,
  onChange: PropTypes.func,
  onOutsideClick: PropTypes.func,
  style: PropTypes.shape(),
};

const defaultProps = {
  color: '',
  onChange: () => {},
  onChangeComplete: () => {},
  onOutsideClick: () => {},
  style: {},
};

const ColorPicker = ({
  color,
  onChange,
  onChangeComplete,
  onOutsideClick,
  style,
  className,
}) => {
  const handleChange = (color) => {
    if (!color) return;
    onChange(color.hex);
  };

  const handleChangeComplete = (color) => {
    if (!color) return;
    onChangeComplete(color.hex);
  };

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <Picker
        className={clsx(['color-picker', className])}
        color={color}
        onChange={(color) => handleChange(color)}
        onChangeComplete={(color) => handleChangeComplete(color)}
        style={style}
      />
    </OutsideClickHandler>
  );
};

ColorPicker.propTypes = propTypes;
ColorPicker.defaultProps = defaultProps;

export default ColorPicker;
