import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { IconButton, makeStyles } from '@material-ui/core';

/**
 * Use this as a title component's `role` attribute
 * to show it only when hovering the node;
 */
export const COMMAND_ROLE = 'cmd';

const useStyles = makeStyles((theme) => ({
  node: ({ isDragging, isDropAncestor }) => ({
    height: '100%',
    display: 'flex',
    cursor: 'move',
    transition: theme.transitions.create('background-color'),
    backgroundColor: isDragging
      ? theme.palette.action.disabledBackground
      : theme.palette.background.paper,
    fontWeight: isDropAncestor ? theme.typography.fontWeightBold : 'unset',
    '& [role="cmd"]': {
      opacity: 0,
      transition: theme.transitions.create('opacity'),
    },
    '&:hover': {
      '& [role="cmd"]': {
        opacity: 1,
      },
    },
  }),
  title: {
    flexGrow: 1,
    display: 'flex',
  },
  indentation: {
    width: '1.5rem', // this is the same as the +/- button
  },
  handle: {
    cursor: 'move',
  },
}));

export default function NodeContentRenderer({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility,
  connectDragPreview,
  connectDragSource,
  isDragging,
  canDrop,
  canDrag,
  node,
  title,
  subtitle,
  draggedNode,
  path,
  treeIndex,
  isSearchMatch,
  isSearchFocus,
  buttons,
  className,
  style,
  didDrop,
  treeId,
  isOver, // Not needed, but preserved for other renderers
  parentNode, // Needed for dndManager
  rowDirection,
  ...otherProps
}) {
  const nodeTitle = title || node.title;
  let isDraggingDescendant = false;
  let isDropAncestor = false;
  if (draggedNode) {
    let { parent } = node;
    while (parent && !isDraggingDescendant) {
      isDraggingDescendant =
        parent.nodeItem && parent.nodeItem.id === draggedNode.nodeItem.id;
      ({ parent } = parent);
    }
    if (parentNode) {
      let parent = parentNode;
      while (parent && !isDropAncestor) {
        isDropAncestor =
          parent.nodeItem && parent.nodeItem.id === node.nodeItem.id;
        ({ parent } = parent);
      }
    }
  }

  const classes = useStyles({
    isDragging: isDragging || isDraggingDescendant,
    isDropAncestor,
  });

  let indentation = [];
  for (let i = 1; i < path.length; i++) {
    indentation.push(
      <div className={classes.indentation} key={`indent-${i}`} />
    );
  }
  let button;
  if (toggleChildrenVisibility && node.children && node.children.length > 0) {
    button = (
      <IconButton
        size="small"
        aria-label={node.expanded ? 'Collapse' : 'Expand'}
        onClick={() =>
          toggleChildrenVisibility({
            node,
            path,
            treeIndex,
          })
        }
      >
        {node.expanded ? (
          <RemoveIcon fontSize="inherit" />
        ) : (
          <AddIcon fontSize="inherit" />
        )}
      </IconButton>
    );
  } else {
    button = <div className={classes.indentation} />;
  }

  const titleWrapper = (
    <div className={classes.title}>
      {typeof nodeTitle === 'function'
        ? nodeTitle({
            node,
            path,
            treeIndex,
          })
        : nodeTitle}
    </div>
  );

  return connectDragSource(
    connectDragPreview(
      <div className={classes.node} {...otherProps}>
        {indentation}
        {button}
        {titleWrapper}
      </div>
    )
  );
}
