import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import clsx from 'clsx';
import moment from 'moment';
import * as firebase from 'firebase';

import Settings from 'components/Settings';
import { useAuth } from 'core/store/selectors';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '100%',
    maxWidth: '280px',
    height: 'auto',
  },
  email: {
    verticalAlign: 'bottom',
  },
  verified: {
    marginLeft: '.75em',
  },
  provider: {
    marginLeft: '.75em',
    verticalAlign: 'unset !important',
  },
}));

const providerIconClass = {
  [firebase.auth.EmailAuthProvider.PROVIDER_ID]: 'fa-email',
  [firebase.auth.GoogleAuthProvider.PROVIDER_ID]: 'fa-google',
  [firebase.auth.TwitterAuthProvider.PROVIDER_ID]: 'fa-twitter',
  'microsoft.com': 'fa-microsoft',
};

const Profile = () => {
  const auth = useAuth();
  const classes = useStyles();
  const registered = moment(Number(auth.createdAt));
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item container spacing={4}>
        <Grid item md={2} sm={3} xs={1}>
          <Avatar
            src={auth.photoURL}
            variant="rounded"
            className={classes.avatar}
          />
        </Grid>
        <Grid
          item
          md={10}
          sm={9}
          xs={12}
          container
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h4">{auth.displayName}</Typography>
            <div>
              <Typography
                variant="subtitle1"
                component="span"
                className={classes.email}
              >
                {auth.email}
              </Typography>
              {auth.emailVerified && (
                <Tooltip title="E-mail verified">
                  <VerifiedUserIcon
                    htmlColor="green"
                    fontSize="small"
                    className={classes.verified}
                  />
                </Tooltip>
              )}
              {auth.providerData.map(({ providerId }) => (
                <Tooltip
                  title={`Logged in with ${providerId}`}
                  key={providerId}
                >
                  <span>
                    <i
                      className={clsx(
                        'fab',
                        'fa-lg',
                        providerIconClass[providerId],
                        classes.provider
                      )}
                    />
                  </span>
                </Tooltip>
              ))}
            </div>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              <Tooltip title={registered.format('LLL')}>
                <span>Registered {registered.fromNow()}</span>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <Settings />
      </Grid>
    </Grid>
  );
};
export default Profile;
