import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';

import { setShowErrorAction } from "./errorActions";
import { getColumnById } from "core/helpers/columns";
import { updateBoardColumnAction } from './boardActions';
import { addCardIntoSubColumn } from "./boardCardActions";
import { 
  addCardIntoLibraryColumn, 
  createLibrary, 
  updateLibrary 
} from './libraryColumnActions';
import { 
  getFirstLibraryColumn, 
  getLibraryColumnById 
} from "core/helpers/library";
import { 
  getCardById,
  newCardPrototype,
  reorder 
} from "core/helpers/cards";
import { addCardIntoSidebar, removeCardFromSidebar } from './sidebarActions';
import { LOCATION_BOARD, LOCATION_SIDEBAR } from 'core/constants';


/**
 * Local method to create the card. Can be called only from `createCardAction`
 * 
 * @param {String} userId Card's Owner ID
 * @param {Object} cardData Card's info
 * @param {String} libraryColumnId ID of the library column
 * @param {String} columnId (Optional) If is on board, the ID of the column
 */
const createCard = ({userId, newCard, column, subColumnId, currentSidebar, _dispatch, firestore }) => {
  return firestore
    .collection('users')
    .doc(userId)
    .collection('cards')
    .add(newCard)
    .then(c => {
      if (subColumnId) {
        _dispatch(addCardIntoSubColumn({userId, column, subColumnId, cardId: c.id}));
      }
      _dispatch(addCardIntoLibraryColumn({userId, libraryColumnId: newCard.libraryColumnId, cardId: c.id}));
      if (currentSidebar) {
        _dispatch(addCardIntoSidebar({userId, cardId: c.id, currentSidebar}));
      }
    })
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    })
}
/**
 * Create a new card
 * 
 * @param {String} userId Card's Owner ID
 * @param {Object} cardData Card's info
 * @param {String} libraryColumnId ID of the library column
 * @param {String} columnId (Optional) If is on board, the ID of the column
 */
export const createCardAction = ({userId, cardData, columnId, currentSidebar }) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
    const newCard = newCardPrototype(cardData);
    let column, subColumnId;

    if (columnId) {
      column = getColumnById(_getState, columnId);
      subColumnId = column.columns[0].id;
      newCard['columnId'] = columnId;
      newCard['subColumnId'] = subColumnId;
    }

    const libraryColumnId = getFirstLibraryColumn(_getState)?.id;
    if (libraryColumnId) {
      newCard['libraryColumnId'] = libraryColumnId;
    }

    if (!newCard['libraryColumnId']) {
      // To not have card library yet, create it
      _dispatch(createLibrary({userId}));
      setTimeout(() => {
        return createCard({userId, newCard, column, subColumnId, currentSidebar, _dispatch, firestore: getFirestore() });
      }, 500);
    } else {
      return createCard({userId, newCard, column, subColumnId, currentSidebar, _dispatch, firestore: getFirestore() });
    }
};

/**
 * Update a card
 * 
 * @param {String} userId Card's Owner ID
 * @param {Object} updatedCard New Card's info
 */
export const updateCardAction = ({userId, updatedCard}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

  const id = updatedCard.id;
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('cards')
    .doc(id)
    .update(updatedCard)
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};
  
/**
 * As the content is a sub collection within a card, this function goal is to
 * delete the content of the card
 * 
 * @param {String} userId Card's Owner ID
 * @param {String} cardId Card's ID
 */
export const removeContentFromCard = ({userId, cardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('cards')
    .doc(cardId)
    .collection('states')
    .doc('default')
    .delete()
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    })
}

/**
 * Delete a card by ID
 * 
 * @param {String} userId Card's Owner ID
 * @param {String} cardId Card's ID
 */
export const deleteCardAction = ({userId, cardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('cards')
    .doc(cardId)
    .delete()
    .then(() => {
      _dispatch(removeContentFromCard({userId, cardId}));
      _dispatch(removeCardFromSidebar({userId, cardId}));
    })
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};

/**
 * Clone the content subcollection of the card
 * 
 * @param {String} userId Card's Owner ID
 * @param {String} cardId Card's ID
 */
const cloneCardContent = ({userId, sourceCardId, destinationCardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  
  const body = _getState().firestore.ordered[`myCards.${sourceCardId}.states.default`][0];

  if (body) {
    delete body.id;

    return getFirestore()
      .collection('users')
      .doc(userId)
      .collection('cards')
      .doc(destinationCardId)
      .collection('states')
      .doc("default")
      .set({ ...body }, { merge: true })
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
  }
}
/**
 * Clone a card with a new id
 * 
 * @param {String} userId Card's Owner ID
 * @param {String} cardToClone Card's Owner ID 
 */
export const cloneCardAction = ({userId, cardToClone, location, currentSidebar}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  
    const clonedCard = {
      ...cardToClone,
    }

    delete clonedCard.id

    return getFirestore()
      .collection('users')
      .doc(userId)
      .collection('cards')
      .add(clonedCard)
      .then(c => {
        switch (location) {
          case LOCATION_BOARD:
            const column = getColumnById(_getState, cardToClone.columnId);
            _dispatch(
              addCardIntoSubColumn({ 
                userId, 
                column, 
                subColumnId: cardToClone.subColumnId, 
                cardId: c.id
              })
            );
            break;
          case LOCATION_SIDEBAR:
            _dispatch(
              addCardIntoSidebar({
                userId,
                cardId: c.id,
                currentSidebar,
              })
            );
            break;
          default:
            break;
        }
        _dispatch(
          addCardIntoLibraryColumn({
            userId, 
            libraryColumnId: cardToClone.libraryColumnId, 
            cardId: c.id
          })
        );
        _dispatch(
          cloneCardContent({
            userId, 
            sourceCardId: cardToClone.id,
            destinationCardId: c.id,
          })
        );
      })
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
};

/**
 * Move one card to another library column
 * @param {Object} source Source from dnd library
 * @param {Object} destination Destination from dnd library
 */
export const moveLibraryCard = ({userId, source, destination})  => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  let libraryColumn = getLibraryColumnById(_getState, source.droppableId);

  if (source.droppableId === destination.droppableId) {
     // Is in the same column, just reorder
     const reorderedColumn = reorder(libraryColumn.cards, source.index, destination.index);
     libraryColumn = {...libraryColumn, cards: reorderedColumn};
     _dispatch(updateLibrary({userId, updatedLibrary: libraryColumn}));
  } else {
    // Is not in the same column
    
    const unmutedLibraryColumns = _cloneDeep(libraryColumn);
    const cardId = libraryColumn.cards[source.index];

    // 1) Remove card from source column
    libraryColumn = {
      ...libraryColumn, 
      cards: 
        _filter(libraryColumn.cards, c => c !== libraryColumn.cards[source.index])
    };

    _dispatch(updateLibrary({userId, updatedLibrary: libraryColumn}));

    // 2) Add card into the new column in the right position
    let destinationLibraryColumn = getLibraryColumnById(_getState, destination.droppableId);

    const destinationCards = _cloneDeep(destinationLibraryColumn.cards);
    destinationCards.splice(destination.index, 0, unmutedLibraryColumns.cards[source.index]);
    destinationLibraryColumn = {
      ...destinationLibraryColumn, 
      cards: destinationCards
    };


    _dispatch(updateLibrary({userId, updatedLibrary: destinationLibraryColumn}));
    const card = getCardById(_getState, cardId);
    _dispatch(updateCardAction({userId, updatedCard: { ...card, libraryColumnId: destination.droppableId }}));
  }  
}

/**
 * Move one card to another board column or subcolumn
 * 
 * @param {Object} source Source from dnd library
 * @param {Object} destination Destination from dnd library
 */
export const moveBoardCard = ({userId, source, destination})  => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

  const column = getColumnById(_getState, source.parentId);

  let cardId, subColumnIndex;
  for (let i = 0; i < column.columns.length; i++) {
    if (column.columns[i].id === source.droppableId) {
      cardId = column.columns[i].cards[source.index];
      subColumnIndex = i;
    }
  }

  if (source.droppableId === destination.droppableId) {
    // Is in the same subcolumn, just reorder
    const subColumnOrderedCards = reorder(column.columns[subColumnIndex].cards, source.index, destination.index);
    const updatedSubColumn = {
        ...column.columns[subColumnIndex], 
        cards: subColumnOrderedCards 
    }
    const updatedColumn = _cloneDeep(column);
    updatedColumn.columns[subColumnIndex] = updatedSubColumn;

    _dispatch(updateBoardColumnAction({userId, columnData: updatedColumn}));
  } else {
    // Is not in the same subcolumn

    // Check if the card already exists in the destination subcolumn
    let destinationColumn = getColumnById(_getState, destination.parentId);

    let duplicatedCard = false;
    _map(destinationColumn.columns, subColumn => {
      if (subColumn.id === destination.droppableId) {
        _map(subColumn.cards, card => {
          if (card === cardId) {
            // This card already exists on the subcolumn , ignore
            duplicatedCard = true;
          }
        });
      }
    });
    
    if (duplicatedCard) return;

    // 1) Remove from source subcolumn
    const updatedColumn = _cloneDeep(column);
    updatedColumn.columns[subColumnIndex].cards.splice(source.index, 1);
    _dispatch(updateBoardColumnAction({userId, columnData: updatedColumn}));
    
    // 2) Add into the new subcolumn  
    if (source.parentId === destination.parentId) {
      destinationColumn = _cloneDeep(updatedColumn);
    } else {
      destinationColumn = getColumnById(_getState, destination.parentId);
    }

    let destinationSubColumnIndex;
    for (let i = 0; i < destinationColumn.columns.length; i++) {
      if (destinationColumn.columns[i].id === destination.droppableId) {
        destinationSubColumnIndex = i;
      }
    }

    const clonedDestinationColumn = _cloneDeep(destinationColumn);
    clonedDestinationColumn.columns[destinationSubColumnIndex].cards.splice(destination.index, 0, cardId);

    _dispatch(updateBoardColumnAction({userId, columnData: clonedDestinationColumn}));
    const card = getCardById(_getState, cardId);
    _dispatch(updateCardAction({userId, updatedCard: { ...card, subColumnId: destination.droppableId, columnId: destination.parentId }}));
  }
}

export const setCardContent = ({userId, cardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('cards')
    .doc(cardId)
    .update({
      updatedAt: getFirestore().FieldValue.serverTimestamp(),
    });
};

/**
 * Remove all label reference within the cards 
 * @param {String} userId Card's Owner ID
 * @param {String} labelId LabelId 
 */
export const updateAllCardsWithLabel = ({userId, labelId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  _map(_getState().firestore.ordered.myCards, card => {
    if (card.labels.includes(labelId)) {
      _dispatch(updateCardAction({userId, updatedCard: {
        ...card,
        labels: _filter(card.labels, l => l !== labelId),
      }}));
    }
  })
}

/**
 * Remove all types reference within the cards 
 * @param {String} userId Card's Owner ID
 * @param {String} labelId LabelId 
 */
export const updateAllCardsWithType = ({userId, typeId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  _map(_getState().firestore.ordered.myCards, card => {
    if (card.type === typeId) {
      _dispatch(updateCardAction({userId, updatedCard: {
        ...card,
        type: null,
      }}));
    }
  })
}