import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Moment from 'react-moment';
import _map from 'lodash/map';
import _find from 'lodash/find';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import _cloneDeep from 'lodash/cloneDeep';

import ProjectShareMenu from '../ProjectShareMenu';
import './styles.scss';
import { updateProjectAction } from 'core/store/actions/projectsActions';
import { useMyLabels } from 'core/store/selectors';
import { renderLabels } from 'core/helpers/labels';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  header: {
    padding: '10px 17px 10px 10px',
  },
  content: {
    padding: 10,
  },
  actions: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
}));

const propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string,
    updatedAt: PropTypes.shape({
      seconds: PropTypes.number,
    }),
    words: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  actionBtnTxt: PropTypes.string,
  onButtonClick: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
};

const defaultProps = {
  project: {},
  actionBtnTxt: 'Open',
  onButtonClick: () => {},
  onDownload: () => {},
  onDelete: () => {},
};

const ProjectCard = ({
  project,
  actionBtnTxt,
  onButtonClick,
  onDelete,
  onDownload,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stateLabels = useMyLabels();
  
  const labels = _map(project.labels, l => 
      _find(stateLabels, sl => sl.id === l)
  )

  const projectUpdatedAt = moment(project.updatedAt?.seconds * 1000);
  const now = moment();
  const updatedAt = projectUpdatedAt > now ? now : projectUpdatedAt; // in case server time is greater than local time
  
  const addLabelIntoProject = (label) => {
    const updatedLabels = _cloneDeep(project.labels || []);
    if (!updatedLabels.includes(label.id)) {
      updatedLabels.push(label.id);
    }
    dispatch(updateProjectAction({projectId: project?.id, updatedProject: {...project, labels: updatedLabels }}));
  }

  const removeLabelFromProject = (label) => {
    const updatedLabels = _cloneDeep(project.labels || []);
    if (updatedLabels.includes(label.id)) {
      updatedLabels.splice(project.labels.indexOf(label.id), 1);
    }
    dispatch(updateProjectAction({projectId: project?.id, updatedProject: {...project, labels: updatedLabels }}));
  }

  const [small, setSmall] = useState(true);

  const toggleSmall = () => {
    setSmall(!small);
  }

  return (
    <div className="project-card">
      <Card className={clsx([classes.root])}>
        <CardHeader
          avatar={<DescriptionOutlinedIcon fontSize="large" />}
          action={<ProjectShareMenu project={project} />}
          title={project.name}
          subheader={project.words ? `${project.words} words` : '1432 words'}
          className={classes.header}
        />
        <CardContent className={classes.content}>
          <Tooltip title={updatedAt.format('llll')}>
            <Typography
              style={{ fontSize: '0.75rem' }}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              Last modified: <Moment fromNow>{updatedAt}</Moment>
            </Typography>
          </Tooltip>
        </CardContent>
        <CardActions className={classes.actions} disableSpacing>
          <Button
            onClick={() => onButtonClick(project)}
            variant="contained"
            color="primary"
            size="small"
          >
            {actionBtnTxt}
          </Button>
          <IconButton
            onClick={() => onDelete(project)}
            size="small"
            aria-label="delete"
          >
            <DeleteForeverIcon />
          </IconButton>
        </CardActions>
      </Card>
      {renderLabels({labels, onAddLabel: addLabelIntoProject, onDeleteLabel: removeLabelFromProject, small, handleSmallUiChange: toggleSmall})}
    </div>
  );
};

ProjectCard.propTypes = propTypes;
ProjectCard.defaultProps = defaultProps;

export default ProjectCard;
