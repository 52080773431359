import React, { memo } from 'react';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RoomIcon from '@material-ui/icons/Room';
import {
  NodeCollectionProvider,
  NodeStateProvider,
  useNodeCollection,
  KwilioEditor,
} from '@kwilio/editor';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    overflow: 'auto',
  },
  borderTop: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
  },
  borderBottom: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  bookmark: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(30),
  },
  bookmarkItem: {
    flexDirection: 'column',
    alignItems: 'normal',
  },
}));

const Bookmark = memo(function Bookmark({ node, goAndScrollTo }) {
  const classes = useStyles();

  return (
    <>
      <ListItem className={`${classes.borderBottom} ${classes.bookmarkItem}`}>
        <NodeStateProvider nodeItem={node}>
          <KwilioEditor
            menuBar={false}
            snippetExtension={false}
            bubbleMenu={false}
          />
          <Box component="div" textAlign="right">
            <IconButton
              color="primary"
              title="Navigate in the editor"
              onClick={() => goAndScrollTo(node.pos)}
            >
              <PlayArrowIcon />
            </IconButton>
            <IconButton
              color="primary"
              title="Pin in the editor"
              onClick={() => alert('WIP')}
            >
              <RoomIcon />
            </IconButton>
          </Box>
        </NodeStateProvider>
      </ListItem>
    </>
  );
});

function BookmarksWrapper({ goAndScrollTo }) {
  const { nodeItems } = useNodeCollection();

  return nodeItems && nodeItems.length > 0 ? (
    nodeItems.map((node) => (
      <Bookmark key={`bookmark-${node.id}`} {...{ node, goAndScrollTo }} />
    ))
  ) : (
    <ListItem>
      <ListItemText secondary="No bookmarks to display." />
    </ListItem>
  );
}

export default function Bookmarks({ goAndScrollTo }) {
  const classes = useStyles();

  return (
    <List className={classes.root} disablePadding>
      <ListItem
        className={`${classes.borderBottom} ${classes.borderTop} ${classes.header}`}
      >
        <BookmarkIcon className={classes.bookmark} />
        <ListItemText primary="Bookmarks" />
      </ListItem>
      <NodeCollectionProvider nodeTypes={['snippet']}>
        <BookmarksWrapper goAndScrollTo={goAndScrollTo} />
      </NodeCollectionProvider>
    </List>
  );
}
