import React, { useState, useContext, useRef } from 'react';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
// import BookmarkIcon from '@material-ui/icons/Bookmark';
import TextField from '@material-ui/core/TextField';
import _find from 'lodash/find';

import TypeIcon from '../../../../Shared/TypeIcon';
import { CardContext } from '../context';
import { useMyLabels, useMyTypes } from 'core/store/selectors';
import { renderLabels } from 'core/helpers/labels';
import CardSettings from './CardSettings';


const CardHeader = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { onTitleUpdate, provided, data, onCardCollapsed, onAddLabel, onDeleteLabel, isHorizontal, handleSmallUiChange, small } = useContext(CardContext);
  const types = useMyTypes();

  const stateLabels = useMyLabels();

  const type = _find(types, t => t.id === data.type);

  const labelVertical = useRef();
  
  const onBlur = (e) => {
    setIsEdit(false);
    onTitleUpdate(e.target.value);
  };

  const renderActions = () => {
    return (
      <div 
        style={{
          height: 33,
          display: 'flex',
          alignItems: 'center',
      }}>
        {/* {onPinClick && (
          <BookmarkIcon
            style={{
              color: data?.isPinned ? '#000' : data?.color,
              cursor: 'pointer',
            }}
            onClick={onPinClick}
          />
        )} */}
        <ArrowDropDownCircleOutlinedIcon
            style={{
              color: data?.color,
              cursor: 'pointer',
              transform: `rotate(${data?.collapsed ? 180 : 0}deg)`,
            }}
            onClick={onCardCollapsed}
        />
        {!isHorizontal && <CardSettings isVertical={!isHorizontal}/>}
      </div>
    )
  }
  
  return (
    <div className="card__header" {...provided.dragHandleProps}>
      <div
        className="card__header-inner"
        style={{
          maxWidth: isHorizontal && 'calc(100% - 50px)',
        }}
      >
        <TypeIcon 
          type={type?.icon}
          color={data?.color}
        />
        {isEdit ? (
          <TextField
            onBlur={onBlur}
            id="title"
            className="card__header-input"
            defaultValue={data?.title}
            autoFocus
          />
        ) : (
          <span
            onClick={() => {
              setIsEdit(true);
            }}
            className="card__header-title"
          >
            {data?.title}
          </span>
        )}
      </div>
      {!isHorizontal ? (
        <div ref={labelVertical} className="labels-vertical">
          {renderLabels({stateLabels, card: data, onAddLabel, onDeleteLabel, small, handleSmallUiChange})}
          {renderActions()}
        </div>
      ) : 
        renderActions()
      } 
    </div>
  );
};

export default CardHeader;
