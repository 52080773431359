import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import CssBaseline from '@material-ui/core/CssBaseline';

import { ReactComponent as Feather } from 'assets/feather.svg';
import useStyles from './SignBase.styles';
import SignInSocial from './SignInSocial';

const SignBase = ({ socialHeading, children, link }) => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Grid container className={classes.root} direction="column">
        <Grid
          container
          item
          className={classes.header}
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid container item sm={3} xs={12}>
            <Grid className={classes.feather}>
              <Feather />
            </Grid>
            <Grid className={classes.title}>Kwil.io</Grid>
          </Grid>
          <Grid
            container
            item
            className={classes.links}
            spacing={2}
            sm={9}
            xs={12}
            justify="flex-end"
          >
            <Grid item>About</Grid>
            <Grid item>FAQ</Grid>
            <Grid item>Pricing</Grid>
            <Grid item>Download</Grid>
            <Grid item>
              <TwitterIcon />
            </Grid>
            <Grid item>
              <FacebookIcon />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.background}>
          <Grid className={classes.content} container alignContent="center">
            <Box padding={4} paddingTop={14} className={classes.form}>
              <SignInSocial heading={socialHeading} />
              {children}
            </Box>
            <Grid
              item
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              {link}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.footer} container justify="flex-end">
          <Grid>
            <Box paddingRight={3}>(c) 2020 kwil.io made with love in Philadelphia</Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SignBase;
