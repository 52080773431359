import React from 'react';
import clsx from 'clsx';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { grid } from 'core/constants';
import './styles.scss';

const getListStyle = (isDraggingOver) => ({
  background: 'transparent',
  padding: grid,
  width: '100%',
  height: '100%',
  minHeight: '300px',
});

const CustomDroppable = ({ id, data, cardIdPrefix, renderDraggableComponent, className, type, ...props }) => {
  const jsonId = JSON.stringify(id);
  return (
    <Droppable droppableId={jsonId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
          className={clsx(['droppable-container', className])}
          {...props}
        >
          {data?.map((item, index) => {
            if (item) {
              const id = `${cardIdPrefix}${item.id}`;
              return (
                <Draggable
                  key={id}
                  draggableId={id}
                  index={index}
                >
                  {(provided, snapshot) =>
                    renderDraggableComponent({ item, provided, snapshot })
                  }
                </Draggable>
              )
            } else {
              return null;
            }
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
};

export default CustomDroppable;
