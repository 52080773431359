import React from 'react';
import Chip from '@material-ui/core/Chip';

import './styles.scss';

const Label = (props) => {
  if (props.small) {
    return (
      <div style={props.style} onClick={props.onClick} >
        <span className='label'>{props.label}</span>
      </div>
    )
  } else {
    return <Chip {...props} />;
  }
};

export default Label;
