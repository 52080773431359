import { updateAllCardsWithLabel } from "./cardActions";
import { setShowErrorAction } from "./errorActions";

export const createLabelAction = (userId, labelData) => async (
    _dispatch,
    _getState,
    { getFirestore }
  ) => {   
    const firestore = getFirestore();
    
    return firestore
      .collection('users')
      .doc(userId)
      .collection('labels')
      .add(labelData)
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
};
  
export const updateLabelAction = (userId, labelId, labelData) => async (
    _dispatch,
    _getState,
    { getFirestore }
  ) => {
    const firestore = getFirestore();
    delete labelData.id;
    return firestore
      .collection('users')
      .doc(userId)
      .collection('labels')
      .doc(labelId)
      .update(labelData)
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
};

export const deleteLabelAction = (userId, id) => async (
    _dispatch,
    _getState,
    { getFirestore }
  ) => {
    const firestore = getFirestore();

    _dispatch(updateAllCardsWithLabel({userId, labelId: id}));

    return firestore
      .collection('users')
      .doc(userId)
      .collection('labels')
      .doc(id)
      .delete()
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      })
};
  