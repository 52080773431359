import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';

import { setShowErrorAction } from "./errorActions";
import { getFirstLibraryColumn, getLibraryColumnById } from 'core/helpers/library';
import { deleteCardAction, updateCardAction } from './cardActions';
import { getCardById } from 'core/helpers/cards';

/**
 * This action is called to setup the library columns
 * It's called just once when there is no card created in the library
 * It creates 4 static columns to move and organize the cards in the library
 * 
 * @param {String} userId Library's Owner ID
 */
export const createLibrary = ({userId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

  const cardLibrary = _getState().firestore.ordered.myCardLibrary;
  
  if (!cardLibrary || cardLibrary?.length === 0) {
    const columnsToBeCreated = [];
    for (let i = 1; i <= 4; i++) {
      columnsToBeCreated.push({
        cards: [],
        position: i
      });
    }
    _map(columnsToBeCreated, column => {
      return getFirestore()
        .collection('users')
        .doc(userId)
        .collection('cardLibrary')
        .doc('default')
        .collection('columns')
        .add(column)
        .catch(error => {
          _dispatch(setShowErrorAction({error}));
        });
      })
    } 
};

/**
 * Update library data
 *
 * @param {String} userId Library's Owner ID
 * @param {Object} updatedLibrary Updated library data
 */
export const updateLibrary = ({userId, updatedLibrary}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
    
  const id = updatedLibrary.id;

  delete updatedLibrary.id;

  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('cardLibrary')
    .doc('default')
    .collection('columns')
    .doc(id)
    .update(updatedLibrary)
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};

/**
 * Add card into the library column
 * 
 * @param {String} userId Card's Owner ID
 * @param {String} libraryColumnId Library Column to add the card
 * @param {String} cardId Card ID to be added into the library column
 */
export const addCardIntoLibraryColumn = ({userId, libraryColumnId, cardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  let id = libraryColumnId;
  let libraryColumn;
  if (!id) {
    // A new card is been added in a user without a Library
    libraryColumn = getFirstLibraryColumn(_getState);
    id = libraryColumn?.id;
    // Update the card data with the library column id
    _dispatch(
      updateCardAction(
        {userId, 
          updatedCard: 
            {
              ...getCardById(_getState,cardId), 
              libraryColumnId: id
            }
        }
      ));
  } 
  
  if (!libraryColumn) {
    libraryColumn = _cloneDeep(getLibraryColumnById(_getState, id));
  }
  
  libraryColumn.cards.unshift(cardId);
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('cardLibrary')
    .doc('default')
    .collection('columns')
    .doc(id)
    .update({position: libraryColumn.position, cards: libraryColumn.cards})
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
}
  
/**
 * Remove card from the library column
 * 
 * @param {String} userId Card's Owner ID
 * @param {String} libraryColumnId Library Column to add the card
 * @param {String} cardId Card ID to be added into the library column
 */
export const removeCardFromLibraryColumn = ({userId, libraryColumnId, cardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  let libraryColumn = _cloneDeep(getLibraryColumnById(_getState, libraryColumnId));
  
  delete libraryColumn.id;

  libraryColumn.cards = _filter(libraryColumn.cards, card => card !== cardId);
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('cardLibrary')
    .doc('default')
    .collection('columns')
    .doc(libraryColumnId)
    .update(libraryColumn)
    .then(r => {
      _dispatch(deleteCardAction({userId, cardId}))
    })
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
}
  
