import React from 'react';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';

import { defaultIconsColor } from 'core/constants';

const propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape(),
  color: PropTypes.string,
};

const defaultProps = {
  type: 'note',
  style: {},
  className: '',
  color: defaultIconsColor,
};

const TypeIcon = ({ type, className, style, color }) => (
  <Icon style={{ color, ...style }} className={className}>
    {type}
  </Icon>
);

TypeIcon.propTypes = propTypes;
TypeIcon.defaultProps = defaultProps;

export default TypeIcon;
