import React, { useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FolderIcon from '@material-ui/icons/Folder';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useCurrentProject, useMyProjects } from 'core/store/selectors';
import {
  openNewProjectModalAction,
  setCurrentProjectAction,
} from 'core/store/actions/projectsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.primary.contrastText,
  },
}));

const ProjectMenu = () => {
  const [menuAnchor, setMenuAnchor] = useState(null);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const projects = useMyProjects(2);
  const currentProject = useCurrentProject();

  const recentProjects = useMemo(() => {
    return [...projects].filter((project) => project.id !== currentProject?.id);
  }, [projects, currentProject]);

  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const openNewProject = () => {
    dispatch(openNewProjectModalAction());
    closeMenu();
  };

  const openProject = (project) => {
    dispatch(setCurrentProjectAction(project.id));
    history.push('/app/project');
    closeMenu();
  };

  const openProjects = () => {
    history.push('/app/projects');
    closeMenu();
  };

  const menuLabel = currentProject ? currentProject.name : 'Projects';

  return (
    <div className={classes.root}>
      <Button
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        variant="contained"
        color="primary"
        disableElevation
      >
        {menuLabel}
      </Button>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {currentProject && (
          <MenuItem onClick={() => openProject(currentProject)} selected>
            {currentProject.name}
          </MenuItem>
        )}
        <MenuItem onClick={openNewProject}>
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="New Project" />
        </MenuItem>
        <Divider />
        {recentProjects.map((project) => (
          <MenuItem
            key={project.id}
            onClick={() => openProject(project)}
            selected={currentProject && project.id === currentProject.id}
          >
            {project.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={openProjects}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary="All Projects" />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProjectMenu;
