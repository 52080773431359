import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import filter from './filter';
import pinnedArea from './pinnedArea';
import projects from './projects';
import error from './error';
import sidebar from './sidebar';

const rootReducers = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  filter,
  pinnedArea,
  projects,
  error,
  sidebar,
});

export default rootReducers;
