import { v4 as uuidv4 } from 'uuid';
import _orderBy from 'lodash/orderBy';
import _filter from 'lodash/filter';

export const createColumns = (type, parendId) => {
  let colsLength = 1;
  let cols = [];
  if (type === 'double') {
    colsLength = 2;
  }
  if (type === 'multi') {
    colsLength = 3;
  }
  for (let i = 0; i < colsLength; i++) {
    cols.push({
      id: 'sub_col' + uuidv4(),
      parent: parendId,
      cards: [],
    });
  }
  return cols;
};

export const reorderColumns = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const newResult = _orderBy(result, 'position', 'asc');
  const [removed] = newResult.splice(startIndex, 1);
  newResult.splice(endIndex, 0, removed);
  return newResult.map((c, index) => ({ ...c, position: index + 1 }));
};

export const getColumns = (getState, projectId) => {
  const columns = _filter(getState().firestore.ordered.myBoardColumns, c => c.projectId === projectId);
  return columns;
}

export const getColumnById = (getState, id) => {
  return getState().firestore.ordered.myBoardColumns.find((column) => column.id === id);;
}
