import React, { Children, cloneElement } from 'react';

export default function TreeNodeRenderer({
  children,
  listIndex,
  swapFrom,
  swapLength,
  swapDepth,
  scaffoldBlockPxWidth,
  lowerSiblingCounts,
  connectDropTarget,
  isOver,
  draggedNode,
  canDrop,
  treeIndex,
  treeId, // Delete from otherProps
  getPrevRow, // Delete from otherProps
  node, // Delete from otherProps
  path, // Delete from otherProps
  rowDirection,
  ...otherProps
}) {
  return connectDropTarget(
    <div {...otherProps}>
      {Children.map(children, (child) =>
        cloneElement(child, {
          isOver,
          canDrop,
          draggedNode,
        })
      )}
    </div>
  );
}
