import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { createFirebaseInstance, getFirebase } from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import {
  createFirestoreInstance,
  getFirestore,
  reduxFirestore,
} from 'redux-firestore';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers/index';

firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));
firebase.firestore();

const rffConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

const isDevelopment = process.env.NODE_ENV === 'development';
const thunkMiddleware = thunk.withExtraArgument({ getFirebase, getFirestore });

const loggerMiddleware = createLogger();
const middleware = isDevelopment
  ? applyMiddleware(thunkMiddleware, loggerMiddleware)
  : applyMiddleware(thunkMiddleware);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(middleware, reduxFirestore(firebase, rffConfig))
);

export default store;

export const rrfProps = {
  firebase,
  dispatch: store.dispatch,
  createFirebaseInstance,
  createFirestoreInstance,
  config: rffConfig,
};
