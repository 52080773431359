import { useDispatch } from 'react-redux';
import _find from 'lodash/find';
import { 
  useCurrentProject,
  useAuth,
  useMyBoardColumn,
} from 'core/store/selectors';
import {
  moveColumnAction,
} from 'core/store/actions/boardActions';
import {
  addCardIntoSubColumn
} from 'core/store/actions/boardCardActions';
import { 
  useMyCardLibrary,
  useCurrentSidebar
} from 'core/store/selectors';
import { 
  moveLibraryCard,
  moveBoardCard,
} from 'core/store/actions/cardActions';
import { 
  addCardIntoSidebar, 
  reorderSidebar 
} from 'core/store/actions/sidebarActions';


const parseSourceAndDestination = ({ source, destination, type }) => {
  if (type === 'COLUMN' || !destination) return { source, destination };

  const sourceParceData = JSON.parse(source.droppableId);
  const destinationParceData = JSON.parse(destination.droppableId);

  return {
    source: {
      ...source,
      droppableId: sourceParceData.id,
      parentId: sourceParceData.parentId,
    },
    destination: {
      ...destination,
      droppableId: destinationParceData.id,
      parentId: destinationParceData.parentId,
    },
  };
};

const useDragDrop = () => {
  const dispatch = useDispatch();
  const currentProject = useCurrentProject();
  const sidebar = useCurrentSidebar({projectId: currentProject?.id});
  const sidebarCards = sidebar?.cards;

  const auth = useAuth();

  const libraryColumns = useMyCardLibrary();
  const board = useMyBoardColumn({projectId: currentProject?.id});
  const currentSidebar = useCurrentSidebar({projectId: currentProject?.id});

  const onDragEnd = (result) => {
    const { source, destination } = parseSourceAndDestination(result);

    if (!destination || !destination.droppableId) {
      return;
    }

    if (result.type === 'COLUMN') {
      dispatch(moveColumnAction({userId: auth?.uid, source, destination, projectId: currentProject?.id}));
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.index === destination.index) return;

      if (source.droppableId === 'sidebar') {
        // reorder in sidebar
        dispatch(
          reorderSidebar({
            userId: auth?.uid,
            currentSidebar,
            source,
            destination,
          })
        );

        return;
      }

      if (source.parentId) {
        // reorder in board
        dispatch(
          moveBoardCard({
            userId: auth?.uid,
            source,
            destination,
          })
        );
        return;
      }

      // reorder in library
      dispatch(
        moveLibraryCard({
          userId: auth?.uid,
          source,
          destination,
        })
      );
    } else {
      if (source.droppableId === 'sidebar') {
        if (destination.parentId) {
          const column = board.find(b => b.id === destination.parentId);
          
          let cardId;
          for (let i = 0; i < column.columns.length; i++) {
            if (column.columns[i].id === destination.droppableId) {
              cardId = currentSidebar.cards[source.index];
            }
          }

          dispatch(addCardIntoSubColumn({
            userId: auth?.uid, 
            column, 
            subColumnId: destination.droppableId, 
            cardId
          }));
        }
        
        return;
      } 

      if (source.parentId) {
        // move in board
        if (destination.droppableId === 'sidebar') {

          const column = board.find(b => b.id === source.parentId);
          
          let cardId;
          for (let i = 0; i < column.columns.length; i++) {
            if (column.columns[i].id === source.droppableId) {
              cardId = column.columns[i].cards[source.index];
            }
          }

          dispatch(
            addCardIntoSidebar({
              userId: auth?.uid,
              cardId,
              currentSidebar,
            })
          );
        } else {
          dispatch(
            moveBoardCard({
              userId: auth?.uid,
              source,
              destination,
            })
          );
        }
        
        return;
      }

      if (destination.droppableId === 'sidebar') {
        const sourceColumn = _find(libraryColumns, lc => lc.id === source.droppableId);
        const card = sourceColumn.cards[source.index];
        
        dispatch(
          addCardIntoSidebar({
            userId: auth?.uid,
            cardId: card,
            currentSidebar,
          })
        );

        return;
      }
      
      dispatch(
        moveLibraryCard({
          userId: auth?.uid,
          source,
          destination,
        })
      );
    }
  };

  return { libraryColumns, sidebarCards, onDragEnd };
};

export default useDragDrop;
