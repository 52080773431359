import React from 'react';
import _map from 'lodash/map';
import _find from 'lodash/find';

import LabelsList from 'components/Shared/Labels';

export const renderLabels = ({stateLabels, card, labels, onAddLabel, onDeleteLabel, small, handleSmallUiChange}) => {
  let localLabels = labels;
  if (!labels) {
    localLabels = _map(card?.labels, l => 
      _find(stateLabels, sl => sl.id === l)
    )
  }
  
  return (
    <LabelsList
      labels={localLabels}
      onAdd={onAddLabel}
      onDelete={onDeleteLabel}
      small={small}
      handleSmallUiChange={handleSmallUiChange}
    />
  )
}
