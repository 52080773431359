import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

import CardForm from '../CardForm';

const propTypes = {
  style: PropTypes.shape(),
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: () => {},
};

export const AddCard = ({ style, onSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCardFormSubmit = ({ title, type }) => {
    onSubmit({
      title,
      type,
    });
    handleClose();
  };
  return (
    <Fragment>
      <Button
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        startIcon={<Icon>add</Icon>}
        style={{...style, color: 'black'}}
      >
        Add card
      </Button>
      <Popover
        open={open}
        className="card__settings-inner-popover"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CardForm onSubmit={handleCardFormSubmit} />
      </Popover>
    </Fragment>
  );
};

AddCard.propTypes = propTypes;
AddCard.defaultProps = defaultProps;

export default AddCard;
