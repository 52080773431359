import { drawerWidth } from "core/constants";

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map(c => c);
  };


  export const calculateWidthAvailable = (windowWidth, sidebarOpen, subColumnLength) => {
    let widthAvailable = windowWidth;
    if (sidebarOpen) {
      widthAvailable = widthAvailable - drawerWidth;
    } 
    return widthAvailable / subColumnLength;
  }
  