/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import CardBody from './Components/CardBody';
import CardFooter from './Components/CardFooter';
import CardHeader from './Components/CardHeader';
import { CardContext } from './context';

const propTypes = {
  data: PropTypes.shape(),
  onDataChange: PropTypes.func,
  onSettingsClose: PropTypes.func,
  onTitleUpdate: PropTypes.func,
  onBodyUpdate: PropTypes.func,
  onCardRemove: PropTypes.func,
  onCardCopy: PropTypes.func,
  onPin: PropTypes.func,
  onUnPin: PropTypes.func,
  disableResize: PropTypes.bool,
  provided: PropTypes.shape(),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const defaultProps = {
  data: {},
  onPin: null,
  onUnPin: null,
  onDataChange: () => {},
  onSettingsClose: () => {},
  onTitleUpdate: () => {},
  onBodyUpdate: () => {},
  onLabelAdd: () => {},
  onLabelDelete: () => {},
  onCardRemove: () => {},
  onCardCopy: () => {},
  disableResize: false,
  provided: {},
};

const CardInterface = ({
  data,
  onDataChange,
  onBodyUpdate,
  onTitleUpdate,
  provided,
  onCardCollapsed,
  onCardRemove,
  onCardCopy,
  onPinClick,
  onSettingsClose,
  onAddLabel,
  onDeleteLabel,
  height,
  board,
  disableActions,
  isHorizontal,
  location,
  small,
  handleSmallUiChange,
  subColumnLength,
}) => {

  return (
    <CardContext.Provider
      value={{
        provided,
        settings: {
          ...data,
          type: data.type,
          color: data.color,
        },
        onPinClick,
        onSettingsClose,
        onDataChange,
        onCardRemove,
        onCardCopy,
        onBodyUpdate,
        onTitleUpdate,
        onCardCollapsed,
        onAddLabel,
        onDeleteLabel,
        data,
        height,
        disableActions,
        board,
        isHorizontal,
        location,
        small,
        handleSmallUiChange,
        subColumnLength,
      }}
    >
      <div
        className={'card'}
        style={{
          padding: !isHorizontal && 8,
          height: height,
          borderColor: data.color,
        }}
      >
        <CardHeader />
        <CardBody />
        {!disableActions && isHorizontal && <CardFooter />}
      </div>
    </CardContext.Provider>
  );
};

CardInterface.propTypes = propTypes;
CardInterface.defaultProps = defaultProps;

export default CardInterface;
