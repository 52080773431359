import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Chip,
} from '@material-ui/core';

import TypeIcon from '../../TypeIcon';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  onOptionDelete: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.shape()),
};

const defaultProps = {
  options: [],
  onOptionDelete: () => {},
  onChange: () => {},
  value: [],
};

const TypesMultiSelect = ({ options, value, onChange, onOptionDelete }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (e) => {
    e.preventDefault();
    if (e.target.tagName === 'path' || e.target.tagName === 'svg') return;
    setOpen(true);
  };
  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel id="types-label">Types</InputLabel>
      <Select
        labelId="types-label"
        id="types"
        name="types"
        multiple
        fullWidth
        input={<Input />}
        placeholder="Types"
        onOpen={handleOpen}
        open={open}
        onClose={handleClose}
        value={value}
        onChange={onChange}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip
                key={value.id}
                label={value.title}
                style={{ marginRight: 5 }}
                onDelete={() => onOptionDelete(value.id)}
                size="small"
              />
            ))}
          </div>
        )}
      >
        {options.map((type) => (
          <MenuItem key={type.id} value={type}>
            <span>
              <TypeIcon
                type={type.icon}
                style={{ marginRight: 5, position: 'relative', top: 8 }}
              />
              {type.title}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

TypesMultiSelect.propTypes = propTypes;
TypesMultiSelect.defaultProps = defaultProps;

export default TypesMultiSelect;
