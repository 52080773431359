import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Icon } from '@material-ui/core';

import icons from './icons';
import './styles.scss';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

const defaultProps = {
  value: null,
  onChange: () => {},
};

const IconsSelect = memo(function IconsSelect({ value, onChange })  {
  return (
    <div className="icons-wrapper">
      {/* <TextField
        id="search-input"
        label="Search icons"
        className="icons-input"
        type="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
             <Icon>search</Icon>
            </InputAdornment>
          ),
        }}
      /> */}
      <div className="icons-list">
        {icons.map(({ icon }) => (
          <IconButton
            color={value === icon ? 'primary' : 'inherit'}
            onClick={() => onChange(icon)}
            key={`i_${icon}`}
            aria-label={icon}
          >
            <Icon>{icon}</Icon>
          </IconButton>
        ))}
      </div>
    </div>
  );
});

IconsSelect.propTypes = propTypes;
IconsSelect.defaultProps = defaultProps;

export default IconsSelect;
