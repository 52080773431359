import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import ShareIcon from '@material-ui/icons/Share';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import useStyles from './ProjectShareMenu.styles';
import PublishProject from './PublishProject';

const ProjectShareMenu = ({ project }) => {
  const classes = useStyles();

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <IconButton variant="contained" {...bindTrigger(popupState)}>
            <ShareIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box p={2} className={classes.root}>
              <PublishProject project={project} />
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default ProjectShareMenu;
