import { setCard, removeCard } from '../actionsCreators/pinnedAreaCreator';
import { setShowErrorAction } from './errorActions';

export const pinCardAction = (card) => async (dispatch) => {
  try {
    dispatch(setCard({ card: { ...card, collapsed: true } }));
  } catch (error) {
    dispatch(setShowErrorAction({error}));
  }
};

export const unpinCardAction = (card) => async (dispatch) => {
  try {
    dispatch(removeCard({ card }));
  } catch (error) {
    dispatch(setShowErrorAction({error}));
  }
};
