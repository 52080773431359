import {
  openNewProjectModal,
  closeNewProjectModal,
} from '../actionsCreators/projectsActionCreator';
import { unpublishState } from './stateActions';
import { setShowErrorAction } from "./errorActions";

export const openNewProjectModalAction = () => async (dispatch) => {
  dispatch(openNewProjectModal());
};

export const closeNewProjectModalAction = () => async (dispatch) => {
  dispatch(closeNewProjectModal());
};

export const createProjectAction = ({ name }) => async (
  _dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  return firestore.add('projects', {
    name,
    roles: { [getState().firebase.auth.uid]: 'owner' },
    createdAt: firestore.FieldValue.serverTimestamp(),
    updatedAt: firestore.FieldValue.serverTimestamp(),
  });
};

export const deleteProjectAction = (id) => async (
  dispatch,
  _getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  await dispatch(unpublishState(id, 'default'));
  await firestore.delete(`projects/${id}/states/default`);
  await firestore.delete(`projects/${id}/statesData/default`);
  return firestore.delete(`projects/${id}`);
};

export const setCurrentProjectAction = (id) => (
  _dispatch,
  _getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  return firebase.updateProfile({
    currentProject: id,
  });
};

export const setProjectLastUpdated = (projectId) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  const firestore = getFirestore();

  return firestore.collection('projects').doc(projectId).update({
    updatedAt: firestore.FieldValue.serverTimestamp(),
  });
};

/**
 * Update a project
 * 
 * @param {String} projectId Project's ID
 * @param {Object} updatedProject New Card's info
 */
export const updateProjectAction = ({projectId, updatedProject}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

    return getFirestore()
      .collection('projects')
      .doc(projectId)
      .update(updatedProject)
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
};
