import React from 'react';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ScrollContainer from 'react-indiana-drag-scroll';

import Label from './Label';
import { isBright } from 'core/helpers/color';
import { defaultIconsColor } from 'core/constants';

const propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape()),
  onClickLabelDelete: PropTypes.func,
  onAdd: PropTypes.func,
  labelIcon: PropTypes.object,
  style: PropTypes.shape(),
  small: PropTypes.bool,
  disableSmallChange: PropTypes.bool,
};

const defaultProps = {
  labels: [],
  onClickLabelDelete: () => {},
  onAdd: () => {},
  labelIcon: null,
  style: {},
  small: true,
  disableSmallChange: false,
};

const LabelsList = ({ labels, onAdd, onClickLabelDelete, labelIcon, style, small, disableSmallChange, handleSmallUiChange }) => {
  
  const labelName = (item) => small ?  
      Array.from(item.title.trim()).slice(0,1).join('')
    : 
      item.title.length > 12 ?
        item.title.substring(0,11) + '...'
      :
        item.title;
  
  return (
    <ScrollContainer horizontal className="labels-list" style={style}>
      <div className="labels-list__inner" style={small ? {display: 'flex', flexDirection: 'row'} : {}}>
        {labels.map((item) => {
          if (item) {
            const lName = labelName(item);
            return (
              <Label
                key={item.id}
                style={{
                  backgroundColor: item.color || defaultIconsColor,
                  borderColor: item.color,
                  marginRight: 5,
                  height: 20,
                  width: small && 20,
                  minWidth: 20,
                  borderRadius: 10,
                  display: small ? 'flex' : '',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: lName.length > 1 ? 1 : 0,
                  paddingLeft: lName.length > 1 ? 4 : 0,
                  color: isBright(item.color) ? 'black' : 'white',
                }}
                label={lName}
                onDelete={small ? null : onClickLabelDelete(item)}
                className="labels-list__label"
                deleteIcon={labelIcon ? labelIcon : <HighlightOffIcon style={{height: 15, color: isBright(item.color) ? 'black' : 'white'}}/>}
                small={small ? 1 : 0}
                onClick={() => {
                  if (!disableSmallChange) {
                    handleSmallUiChange && handleSmallUiChange()
                  } else {
                    onAdd(item)
                  }
                }}
                clickable={!disableSmallChange}
              />
            )
          } else {
            return null;
          }
        })}
      </div>
    </ScrollContainer>
  );
};

LabelsList.propTypes = propTypes;
LabelsList.defaultProps = defaultProps;

export default LabelsList;
