import {
  OPEN_NEW_PROJECT_MODAL,
  CLOSE_NEW_PROJECT_MODAL,
} from '../actionsTypes/projects';

const projects = (
  state = {
    newProjectModalOpen: false,
  },
  { type, payload }
) => {
  switch (type) {
    case OPEN_NEW_PROJECT_MODAL:
      return {
        ...state,
        newProjectModalOpen: true,
      };
    case CLOSE_NEW_PROJECT_MODAL:
      return {
        ...state,
        newProjectModalOpen: false,
      };
    default:
      return state;
  }
};

export default projects;
