import { useEffect, useState } from 'react';
import _kebabCase from 'lodash/kebabCase';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  usePublishInfo,
  useAuth,
  useProjectStateData,
} from 'core/store/selectors';
import {
  useProjectPublishDataConnect,
  useProjectStateDataConnect,
} from 'core/store/connectors';
import {
  publishState,
  republishState,
  unpublishState,
} from 'core/store/actions/stateActions';
import { setShowErrorAction } from 'core/store/actions/errorActions';

export const useShareToInternet = (projectId) => {
  useProjectPublishDataConnect(projectId);
  useProjectStateDataConnect(projectId);

  const [title, setTitle] = useState('');
  const [publishing, setPublishing] = useState(false);
  const [unpublishing, setUnpublishing] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [publishError, setPublishError] = useState(false);

  const { uid } = useAuth();
  const kebabedTitle = _kebabCase(title);

  const {
    publishedAt: _publishedAt,
    title: currentTitle,
    permalink: currentPermalink,
  } = usePublishInfo(projectId);
  const { updatedAt: _updatedAt } = useProjectStateData(projectId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentTitle) {
      setTitle(currentTitle);
    }
  }, [currentTitle]);

  const now = moment();

  const publishedBefore = !!currentPermalink;

  const publishedAt = _publishedAt && moment(_publishedAt.seconds * 1000);
  const updatedAt = _updatedAt && moment(_updatedAt.seconds * 1000);

  const baseUrl = `${window.location.origin}/live/${uid}/`;

  const changingTitle = publishedBefore && currentTitle !== title;

  const publishable = !!updatedAt;

  const outdated =
    publishable && !publishing && (!publishedBefore || updatedAt > publishedAt);

  const publishDisabled =
    (!outdated && !changingTitle) ||
    publishing ||
    publishSuccess ||
    publishError ||
    unpublishing ||
    kebabedTitle.length === 0;

  const unpublishDisabled =
    publishing || publishSuccess || publishError || unpublishing;

  const temporarilySetTrue = (callback, timeout = 3000) => {
    callback(true);
    setTimeout(() => callback(false), timeout);
  };

  const publish = async () => {
    setPublishing(true);
    try {
      if (publishedBefore) {
        await dispatch(
          republishState(
            projectId,
            'default',
            title,
            kebabedTitle,
            currentPermalink
          )
        );
      } else {
        await dispatch(publishState(projectId, 'default', title, kebabedTitle));
      }
      temporarilySetTrue(setPublishSuccess);
    } catch (error) {
      dispatch(setShowErrorAction({ error }));
      temporarilySetTrue(setPublishError);
    } finally {
      setPublishing(false);
    }
  };

  const unpublish = async () => {
    setUnpublishing(true);
    try {
      await dispatch(unpublishState(projectId, 'default'));
    } catch (error) {
      dispatch(setShowErrorAction({ error }));
    } finally {
      setUnpublishing(false);
    }
  };

  return {
    publishable,
    publishedAt:
      (publishedBefore && !publishedAt) || publishedAt > now
        ? now
        : publishedAt,
    updatedAt,
    title,
    setTitle,
    publishing,
    publish,
    baseUrl,
    kebabedTitle,
    publishDisabled,
    publishedBefore,
    changingTitle,
    outdated,
    currentTitle,
    currentPermalink,
    publishSuccess,
    publishError,
    unpublish,
    unpublishing,
    unpublishDisabled,
  };
};
