import React from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useError } from 'core/store/selectors/useError';
import { setHideErrorAction } from "core/store/actions/errorActions";

const ErrorMessage = () => {
    const message = useError();
    const dispatch = useDispatch();

    return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!!message}
          autoHideDuration={6000}
          onClose={() => dispatch(setHideErrorAction())}
        >
            <SnackbarContent
              style={{
                backgroundColor: '#ff7f7f',
                fontSize: 16,
              }}
              message={<span id="client-snackbar">{message}</span>}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => dispatch(setHideErrorAction()) }
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
        </Snackbar>
    )
}

export default ErrorMessage;
