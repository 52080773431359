import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import TypesSelect from '../../Settings/Types/TypesSelect';
import { useMyTypes } from 'core/store/selectors';

const propTypes = {
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: () => {},
};

export const CardForm = ({ onSubmit }) => {
  const types = useMyTypes();
  const [formData, setFormData] = useState({
    title: {
      value: '',
      touched: false,
    },
    type: {
      value: types[0],
      touched: false,
    },
  });

  const handleSubmit = () => {
    const { title, type } = formData;
    onSubmit({
      title: title.value,
      type: type.value.id,
    });
  };

  const handleFormChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: {
        value: value,
        touched: true,
      },
    });
  };

  const { title, type } = formData;
  
  return (
    <div className="card-form">
      <FormControl style={{ width: '100%', marginBottom: 15 }}>
        <TextField
          onChange={({ target }) => handleFormChange('title', target.value)}
          autoFocus
          id="form-title"
          error={!title.value && title.touched}
          helperText="Title is required"
          label="Title"
        />
      </FormControl>
      <TypesSelect
        value={type.value}
        onChange={(value) => handleFormChange('type', value)}
        style={{ marginBottom: 15 }}
      />
      <Button
        onClick={handleSubmit}
        color="primary"
        variant="contained"
        style={{ width: '100%' }}
        disabled={!title.value || !type.value}
      >
        Add
      </Button>
    </div>
  );
};

CardForm.propTypes = propTypes;
CardForm.defaultProps = defaultProps;

export default CardForm;
