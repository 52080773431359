import { Button, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import _debounce from 'lodash/debounce';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _find from 'lodash/find';
import _map from 'lodash/map';

import { filterByStringField } from 'core/helpers/filter';
import useDragDrop from 'core/hooks/useDragDrop';
import { setSidebarFilterAction } from 'core/store/actions/filterActions';
import { createCardAction } from 'core/store/actions/cardActions';
import { 
  useAuth, 
  useCurrentProject, 
  useMyCards,
  useCurrentSidebar
} from 'core/store/selectors';
import AddCard from '../CardsLibrary/AddCard';
import Card from '../CardsLibrary/Card';
import CustomDroppable from '../Shared/CustomDroppable';
import { LOCATION_SIDEBAR } from 'core/constants';

const CardsTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentProject = useCurrentProject();
  const cardsFromState = useMyCards();
  const currentSidebar = useCurrentSidebar({projectId: currentProject?.id});
  const auth = useAuth();
  
  const filter = useSelector((state) => state.filter.sidebar);
  const { sidebarCards } = useDragDrop();

  const onCardCreate = (card) => {
    dispatch(createCardAction({userId: auth?.uid, cardData: card, currentSidebar}));
  };

  const onSearchChange = _debounce((value) => {
    dispatch(setSidebarFilterAction({ name: 'title', value }));
  }, 300);

  const getCardsByIds = (ids) => {
    return _map(ids, cardId => 
      _find(cardsFromState, stateCard => stateCard?.id === cardId)
    )
  }

  return (
    <Fragment>
      <div style={{ textAlign: 'center' }}>
        <Button
          onClick={() => {
            history.push('/app/cards');
          }}
          style={{ display: 'block', width: '100%', marginBottom: '10px', color: '#000' }}
        >
          Card Library
        </Button>
        {currentProject && (
          <>
            <Button
              onClick={() => {
                history.push('/app/mindmap');
              }}
              style={{ display: 'block', width: '100%', marginBottom: '10px', color: '#000' }}
            >
              Board
            </Button>
            <div style={{ marginBottom: 15 }}>
              <TextField
                id="search"
                defaultValue={filter.title}
                onChange={({ target }) => onSearchChange(target.value)}
                label="Search card by title"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <AddCard
              style={{ marginBottom: 15 }}
              onSubmit={onCardCreate}
              projectId={currentProject.id}
            />
          </>
        )}
      </div>
      {currentProject?.id && 
        <CustomDroppable
          renderDraggableComponent={({ item, ...props }) => (
            <Card
              card={item}
              type="board"
              {...props}
              location={LOCATION_SIDEBAR}
              isHorizontal
            />
          )}
          data={filterByStringField(getCardsByIds(sidebarCards), filter.title, 'title')}
          id={{ id: 'sidebar' }}
          cardIdPrefix="sidebar"
        />
      }
    </Fragment>
  );
};
export default CardsTab;
