import { useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';

/**
 * Hook to use board column
 * @param {String} projectId Current project's ID
 */
export const useMyBoardColumn = ({projectId}) => 
    useSelector((state) => 
        _sortBy(
            _filter(state.firestore.ordered.myBoardColumns, c => c.projectId === projectId),
            c => c.position))
