export const forAsync = (arr, work) => {
	const loop = (array, i) => {
		return new Promise((resolve, reject) => {
			if (i >= array.length) {
				resolve();
			} else {
				try {
					Promise.resolve(work(array[i], i))
						.then(() => resolve(loop(array, i + 1)))
						.catch(reject);
				} catch (error) {
					reject(error);
				}
			}
		});
	};
	return loop(arr, 0);
};
