import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';

import { setShowErrorAction } from "./errorActions";
import { getColumnById } from 'core/helpers/columns';
import { updateCardAction } from './cardActions';
import { getCardById } from 'core/helpers/cards';

/**
 * Add card into the subcolumn
 * 
 * @param {String} userId Card's Owner ID
 * @param {Object} column Column to add the card
 * @param {String} subColumnId SubColumn to add the card
 * @param {String} cardId Card ID to be added into the subcolumn
 */
export const addCardIntoSubColumn = ({userId, column, subColumnId, cardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  const newColumn = _cloneDeep(column);

  if (subColumnId) {
    let position = 0;
    _map(newColumn.columns, (subcolumn, index) => {
      if (subcolumn.id === subColumnId) {
        position = index;
      }
    });
    if (!newColumn.columns[position].cards.includes(cardId)) {
      newColumn.columns[position].cards.unshift(cardId);
    }
  } else {
    if (!newColumn.columns[0].cards.includes(cardId)) {
      newColumn.columns[0].cards.unshift(cardId);
    }
  }
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('board')
    .doc('default')
    .collection('columns')
    .doc(column.id)
    .update(newColumn)
    .then(c => {
      const card = getCardById(_getState, cardId);
      _dispatch(updateCardAction({userId, updatedCard: { ...card, columnId: column.id, subColumnId }}));
    })
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};

/**
 * Remove card from a subColumn
 * 
 * @param {String} userId Card's Owner ID
 * @param {Object} column Column to add the card
 * @param {String} subColumnId SubColumn to add the card
 * @param {String} cardId Card ID to be added into the subcolumn
 */
export const removeCardFromSubColumn = ({userId, columnId, subColumnId, cardId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  const newColumn = _cloneDeep(getColumnById(_getState,columnId));

  delete newColumn.id;
  
  if (subColumnId) {
    let position = 0;
    _map(newColumn.columns, (subcolumn, index) => {
      if (subcolumn.id === subColumnId) {
        position = index;
      }
    });
    newColumn.columns[position].cards = _filter(newColumn.columns[position].cards, c => c !== cardId);
  } else {
    newColumn.columns[0].cards = _filter(newColumn.columns[0].cards, c => c !== cardId);
  }
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('board')
    .doc('default')
    .collection('columns')
    .doc(columnId)
    .update(newColumn)
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};
