import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import EditIcon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderItem: PropTypes.func,
  item: PropTypes.shape().isRequired,
};

const defaultProps = {
  onEdit: null,
  onDelete: null,
  renderItem: null,
};

const CustomListItem = ({ item, onEdit, onDelete, renderItem }) => (
  <ListItem button>
    {item.icon && (
      <ListItemIcon>
        <Icon>{item.icon}</Icon>
      </ListItemIcon>
    )}
    <ListItemText secondary={item.secondary}>{renderItem ? renderItem(item) : item.title}</ListItemText>
    {(onEdit || onDelete) && (
      <ListItemSecondaryAction>
        {onEdit && (
          <IconButton
            onClick={() => onEdit(item)}
            size="medium"
            edge="end"
            aria-label="delete"
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        )}
        {onDelete && (
          <IconButton
            onClick={() => onDelete(item)}
            size="medium"
            edge="end"
            aria-label="edit"
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    )}
  </ListItem>
);

CustomListItem.propTypes = propTypes;
CustomListItem.defaultProps = defaultProps;

export default CustomListItem;
