import '@fortawesome/fontawesome-free/js/all.js';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'react-sortable-tree/style.css';

import AppRouter from './core/router/AppRouter';
import store, { rrfProps } from './core/store/store';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './Styles/app.scss';
import ErrorMessage from './components/Shared/ErrorMessage';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      contrastText: '#000000',
    },
    secondary: {
      main: '#9C27B0',
      contrastText: '#000000',
    },
    third: {
      main: '#000000',
    }
  },
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <ThemeProvider theme={theme}>
              <AppRouter />
              <ErrorMessage/>
            </ThemeProvider>
          </ReactReduxFirebaseProvider>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
