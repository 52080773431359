import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Feather } from 'assets/feather.svg';
import useStyles from './SignInSocial.styles';

const SignInSocial = ({ heading }) => {
  const firebase = useFirebase();

  const loginWithGoogle = () =>
    firebase.login({
      provider: 'google',
      type: 'popup',
    });

  const loginWithMicrosoft = () =>
    firebase.login({
      provider: 'microsoft.com',
      type: 'popup',
    });

  const loginWithTwitter = () =>
    firebase.login({
      provider: 'twitter',
      type: 'popup',
    });

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid item className={classes.feather}>
        <Feather />
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="center" alignItems="center">
          <Typography variant="h6" className={classes.heading}>
            {heading}
          </Typography>
          <Grid
            container
            spacing={6}
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.whiteText}
          >
            <Grid item onClick={loginWithGoogle}>
              <i className={`fab fa-google fa-lg ${classes.icon}`} />
            </Grid>
            <Grid item onClick={loginWithMicrosoft}>
              <i className={`fab fa-microsoft fa-lg ${classes.icon}`} />
            </Grid>
            <Grid item onClick={loginWithTwitter}>
              <i className={`fab fa-twitter fa-lg ${classes.icon}`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default SignInSocial;
