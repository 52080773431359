import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { 
  FirebaseEditorStateProvider,
  KwilioEditor
} from '@kwilio/editor';

import { CardContext } from '../context';
import { useAuth } from 'core/store/selectors';
import { useContentStateRef } from 'core/store/selectors/useContentStateRef';
import { setCardContent } from 'core/store/actions/cardActions';

const CardBody = React.memo(() => {
  const { height, data } = useContext(CardContext);
  const maxHeight = height - 88;
  const auth = useAuth();
  const dispatch = useDispatch();
  const contentStateRef = useContentStateRef({userId: auth?.uid, cardId: data.id});

  const handleFirebaseUpdate = () => {
    dispatch(setCardContent({userId: auth?.uid, cardId: data.id}));
  };

  return (
    <div
      className="card__body"
      style={{ 
        overflowY: 'auto', 
        maxHeight: maxHeight < 0 ? 0 : maxHeight,
       }}
    >
      <FirebaseEditorStateProvider
        key={data.id}
        fireStateRef={contentStateRef}
        onFirebaseUpdate={handleFirebaseUpdate}
        localStateThrottle="manual"
      >
       <KwilioEditor
         menuBar={false}
         snippetExtension={false}
         bubbleMenu={false}
       />
      </FirebaseEditorStateProvider>
    </div>
  );
});

export default CardBody;
