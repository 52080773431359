import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

import { useCurrentProject } from 'core/store/selectors';
import { useProjectStateDataConnect } from 'core/store/connectors';
import CardsTab from './CardsTab';
import NavigationTab from './NavigationTab';

function TabPanel({
  children,
  value,
  index,
  padding = 3,
  className,
  ...other
}) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={padding} className={className}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  padding: PropTypes.number,
  className: PropTypes.string,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  rootList: {
    width: '100%',
  },
  mainsTab: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
  },
  toolbar: {
    width: '100%',
  },
  tabs: {
    flexGrow: 1,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  },
  tab: {
    minWidth: 100,
    width: 100,
    padding: '1em',
  },
  // navigationTabPanel: {
  //   height: `calc(100vh - ${searchGridHeightPx}px - ${separatorHeightPx}px - ${headerHeightPx}px)`,
  //   display: 'flex',
  //   flexDirection: 'column',
  // },
}));

const DefaultStateConnector = ({ projectId }) => {
  useProjectStateDataConnect(projectId);
  return null;
};

export default function TabsItems({ toggleDrawer }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const currentProject = useCurrentProject();

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {currentProject && (
        <DefaultStateConnector projectId={currentProject.id} />
      )}
      <AppBar position="static" className={classes.mainsTab}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="fullWidth"
            className={classes.tabs}
          >
            {/* <Tab
            icon={<HomeIcon style={{ color: 'white' }} fontSize="large" />}
            {...a11yProps(0)}
            className={classes.tab}
          /> */}
            <Tab
              icon={
                <GpsNotFixedIcon style={{ color: 'black' }} fontSize="large" />
              }
              {...a11yProps(0)}
              className={classes.tab}
            />
            <Tab
              // disabled={!currentProject.id}
              icon={
                <FileCopyIcon style={{ color: 'black' }} fontSize="large" />
              }
              {...a11yProps(1)}
              className={classes.tab}
            />
          </Tabs>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        <HomeTab />
      </TabPanel> */}
      <TabPanel value={value} index={0} padding={0}>
        <NavigationTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CardsTab />
      </TabPanel>
    </div>
  );
}
