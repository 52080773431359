/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import './styles.scss';
import CardTypes from './CardTypes';
import { 
  collapsedHeight, 
  collapsedVerticalHeight, 
  LOCATION_BOARD, 
  LOCATION_LIBRARY, 
  LOCATION_SIDEBAR 
} from 'core/constants';
import { useAuth } from 'core/store/selectors';
import { 
  pinCardAction, 
  unpinCardAction 
} from 'core/store/actions/pinnedAreaActions';
import { updateCardAction } from 'core/store/actions/cardActions';
import { setShowErrorAction } from 'core/store/actions/errorActions';
import { useWindowSize } from 'core/store/selectors/useWindowSize';
import { calculateWidthAvailable } from 'core/helpers/sidebar';

const propTypes = {
  card: PropTypes.shape(),
  onResizeStart: PropTypes.func,
  onResizeStop: PropTypes.func,
  onCardChange: PropTypes.func,
  onCardCopy: PropTypes.func,
  onCardRemove: PropTypes.func,
  onPin: PropTypes.func,
  onUnpin: PropTypes.func,
  type: PropTypes.string,
};

const defaultProps = {
  card: {},
  onResizeStart: () => {},
  onResizeStop: () => {},
  onCardChange: () => {},
  onCardCopy: () => {},
  onCardRemove: () => {},
  onPin: () => {},
  onUnpin: () => {},
  type: null,
};

function areEqual(prevProps, nextProps) {
  return _isEqual(prevProps, nextProps);
}

const Card = React.memo(
  ({
    card,
    onResizeStart,
    onResizeStop,
    onCardChange,
    onCardRemove,
    onCardCopy,
    onPin,
    onUnpin,
    type,
    disableActions,
    isHorizontal,
    location,
    subColumnLength,
    ...props
  }) => {

    const [data, setData] = useState({ ...card });

    const sidebarOpen = useSelector((state) => state.sidebar.open);

    const auth = useAuth();
    const _dispatch = useDispatch();
    const [windowWidth] = useWindowSize();

    const updateHeight = (collapsed = false) => {
      let proportion = calculateWidthAvailable(windowWidth, sidebarOpen, subColumnLength);
      if (!isHorizontal) {
        if (proportion < 331) {
          if (collapsed) {
            if (height === collapsedHeight) {
              const newHeight = height + 25;
              setHeight(newHeight);
            } else {
              setHeight(collapsedHeight + 25);
            }
          } else {
            setHeight(data.height);
          }
          
        } else {
          if (collapsed) {
            if (height === collapsedHeight + 25) {
              const newHeight = height - 25;
              setHeight(newHeight);
            } else {
              if (proportion > 381) {
                setHeight(collapsedVerticalHeight);  
              } else {
                setHeight(data.labels.length > 2 ? collapsedHeight : collapsedVerticalHeight);
              }
            }
          } else {
            setHeight(card.height);
          }
        }
      } else {
        const newHeight = collapsed ? 
          collapsedHeight : 
            location === LOCATION_BOARD ? 
              card.boardHeight 
            : location === LOCATION_SIDEBAR ? 
              card.sidebarHeight 
          : card.height;
        setHeight(newHeight);
      }
    }

    useEffect(() => {
      updateHeight(data?.collapsed);
    }, []);

    const [small, setSmall] = useState(true);
    
    const handleSmallUiChange = () => {
      const updatedSmall = !small;
      setSmall(updatedSmall);
      let proportion = calculateWidthAvailable(windowWidth, sidebarOpen, subColumnLength);
      let difference = 50;
      if (proportion > 690) {
        difference = 0;
      } else {
        if (proportion < 480 ) {
          difference = 80;
          if (card.labels.length > 2) {
            difference = 90;
          }
        }  
      }
      !updatedSmall ? setHeight(height + difference) : setHeight(height - difference);
    }

    useEffect(() => {
      setSmall(true);
      updateHeight(data?.collapsed);
    }, [isHorizontal, windowWidth, sidebarOpen]);
    
    useEffect(() => {
      if (_isEqual(data, card)) return;
      setData({ ...card });
    }, [card]);

    const [height, setHeight] = useState();

    const handleDataChange = (newData, updateData) => {
      if (newData) setData({ ...data, ...newData });
      if (updateData) _dispatch(updateCardAction({userId: auth?.uid, updatedCard: { ...data, ...newData }}));
    };

    const handleCardResize = (e, { element, size, handle }) => {
      e.preventDefault();
      setHeight(size.height);
      if (data.collapsed) {
        handleDataChange({ collapsed: false });
      }
    };

    const handleResizeStart = (e) => {
      e.preventDefault();
      onResizeStart();
    };

    const handleResizeStop = (e) => {
      e.preventDefault();
      switch (location) {
        case LOCATION_SIDEBAR:
          handleDataChange({ sidebarHeight: height }, true);
          break;
        case LOCATION_BOARD:
          handleDataChange({ boardHeight: height }, true);
          break;
        case LOCATION_LIBRARY:
        default:
          handleDataChange({ height }, true);
          break;
      }
      onResizeStop();
    };

    const handleCardCollapsed = () => {
      const updatedCollapsed = !data.collapsed;
      updateHeight(updatedCollapsed);
      handleDataChange({ collapsed: updatedCollapsed }, true);
    };

    const handleCardRemove = () => {
      onCardRemove(data);
    };

    const handleAddLabel = (label) => {
      if (data.labels.length < 4) {
        const dataCopy = _cloneDeep(data);
        dataCopy.labels.push(label.id);
        handleDataChange({ labels: dataCopy.labels }, true);
      } else {
        _dispatch(setShowErrorAction({error: { message: "You can't add more than 4 labels in the same card"}}))
      }
    };

    const handleDeleteLabel = (label) => {
      handleDataChange(
        { labels: data.labels.filter(l => l !== label.id) },
        true
      );
    };

    const handleCardCopy = () => {
      onCardCopy(data);
    };

    const handleCardPinClick = (card) => {
      const updatedCard = {...card, isPinned: !card.isPinned };
      _dispatch(updateCardAction({userId: auth?.uid, updatedCard}));
      if (card.isPinned) {
        _dispatch(unpinCardAction(card));
      } else {
        _dispatch(pinCardAction(card));
      }
    };

    const localDisableActions = data.collapsed && !isHorizontal ? true : disableActions;

    return (
      <Fragment>
        <CardTypes
          card={data}
          onResizeStart={handleResizeStart}
          onResizeStop={handleResizeStop}
          onResize={handleCardResize}
          onCardChange={handleDataChange}
          onCardRemove={handleCardRemove}
          onCardCopy={handleCardCopy}
          onCardCollapse={handleCardCollapsed}
          onSettingsClose={() => handleDataChange(null, true)}
          onTitleUpdate={(title) => handleDataChange({ title }, true)}
          onBodyUpdate={(body) => handleDataChange({ body }, true)}
          onAddLabel={handleAddLabel}
          onDeleteLabel={handleDeleteLabel}
          onPinClick={() => handleCardPinClick(card)}
          height={height}
          type={type}
          disableActions={localDisableActions}
          isHorizontal={isHorizontal}
          location={location}
          small={small}
          handleSmallUiChange={handleSmallUiChange}
          subColumnLength={subColumnLength}
          {...props}
        />
      </Fragment>
    );
  },
  areEqual
);

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;

export default Card;
