/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import UnfoldMoreOutlinedIcon from '@material-ui/icons/UnfoldMoreOutlined';
import { Resizable } from 'react-resizable';
import PropTypes from 'prop-types';

import CardInterface from '../CardInterface';
import { collapsedHeight } from 'core/constants';

const propTypes = {
  card: PropTypes.shape(),
  onResizeStart: PropTypes.func,
  onResize: PropTypes.func,
  onResizeStop: PropTypes.func,
  onCardChange: PropTypes.func,
  onCardCopy: PropTypes.func,
  onCardRemove: PropTypes.func,
  onCardCollapse: PropTypes.func,
  onSettingsClose: PropTypes.func,
  onTitleUpdate: PropTypes.func,
  onBodyUpdate: PropTypes.func,
  onAddLabel: PropTypes.func,
  onDeleteLabel: PropTypes.func,
  disableResize: PropTypes.bool,
};

const defaultProps = {
  card: {},
  onResize: () => {},
  onResizeStart: () => {},
  onResizeStop: () => {},
  onCardChange: () => {},
  onCardCopy: () => {},
  onCardRemove: () => {},
  onCardCollapse: () => {},
  onSettingsClose: () => {},
  onTitleUpdate: () => {},
  onBodyUpdate: () => {},
  onAddLabel: () => {},
  onDeleteLabel: () => {},
  disableResize: false,
};

const DefaultCard = ({
  card,
  onResizeStart,
  onResizeStop,
  onResize,
  onCardChange,
  onCardRemove,
  onCardCopy,
  onCardCollapse,
  disableResize,
  onSettingsClose,
  onTitleUpdate,
  onBodyUpdate,
  onAddLabel,
  onDeleteLabel,
  height,
  disableActions,
  location,
  isHorizontal,
  ...props
}) => {

  return (
    <Resizable
      height={height}
      width={0}
      axis="y"
      onResize={() => !card.isPinned}
      minConstraints={[0, collapsedHeight]}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      disableResize={true}
      handle={
        <UnfoldMoreOutlinedIcon
        className={`card${disableActions ? '__resize-disabled' : '__resize-handle'}`}
          style={{
            color: card?.color,
            right: 5,
            bottom: 50,
          }}
        />
      }
    >
      <div>
        <CardInterface
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
          onCardCollapsed={onCardCollapse}
          onCardRemove={onCardRemove}
          onCardCopy={onCardCopy}
          disableResize={disableResize}
          data={card}
          onDataChange={onCardChange}
          onSettingsClose={onSettingsClose}
          onTitleUpdate={onTitleUpdate}
          onBodyUpdate={onBodyUpdate}
          height={height}
          disableActions={disableActions}
          location={location}
          isHorizontal={isHorizontal}
          onDeleteLabel={onDeleteLabel}
          {...props}
        />
      </div>
    </Resizable>
  );
};

DefaultCard.propTypes = propTypes;
DefaultCard.defaultProps = defaultProps;

export default DefaultCard;
