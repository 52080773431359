import React from 'react';
import { EditorStateProvider, KwilioEditor } from '@kwilio/editor';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import * as tocbot from 'tocbot';

import { useLive } from './Live.hooks';
import './Live.scss';

const Live = () => {
  const { fetching, fetchingError, contents, publishData } = useLive();

  if (fetchingError) {
    return <Redirect to="/" />; // TODO 404 page
  }
  if (fetching) {
    return <LinearProgress />;
  }
  const initToc = () => {
    tocbot.init({
      tocSelector: '#toc',
      contentSelector: '.ProseMirror',
      headingSelector: 'h1, h2, h3',
      hasInnerContainers: true,
      positionFixedSelector: '#toc',
      orderedList: false,
    });
    setTimeout(() => tocbot.refresh());
  };
  const title = publishData?.title || '';

  return (
    <>
      <Helmet>
        <title>Kwil.io | {title}</title>
      </Helmet>
      <Box id="live-wrapper" display="flex" flexDirection="row">
        <Hidden xsDown implementation="css">
          <div id="toc" />
        </Hidden>
        <Box id="content-wrapper" mx={2} flexGrow="1">
          <Box mb={1}>
            <Typography variant="h3">{title}</Typography>
            <Divider />
          </Box>
          <EditorStateProvider remoteData={contents}>
            <KwilioEditor
              bubbleMenu={false}
              menuBar={false}
              editable={false}
              onFirstRender={initToc}
            />
          </EditorStateProvider>
        </Box>
      </Box>
    </>
  );
};

export default Live;
