import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import SignBase from './SignBase';
import useStyles from './SignBase.styles';

const SignUp = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const errorMessage = useSelector(
    (state) => state.firebase.authError?.message
  );

  const firebase = useFirebase();

  const signUpWithEmail = async (event) => {
    event.preventDefault();
    try {
      await firebase.createUser({
        email,
        password,
      });
      await firebase.auth().currentUser.updateProfile({
        displayName: name,
      });
    } catch (error) {}
  };

  return (
    <SignBase
      socialHeading="Register with"
      link={
        <RouterLink className={classes.link} to="/signin">
          Already have an account? Login
        </RouterLink>
      }
    >
      <form onSubmit={signUpWithEmail}>
        <Typography align="center" variant="subtitle2">Register with e-mail</Typography>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={1}>
            <Icon>face</Icon>
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="standard"
              margin="none"
              required
              fullWidth
              id="name"
              label="Full Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={1}>
            <Icon>email</Icon>
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="standard"
              margin="none"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={1}>
            <Icon>lock</Icon>
          </Grid>
          <Grid item xs={10}>
            <TextField
              variant="standard"
              margin="none"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Box marginTop={2}>
            <Button type="submit" color="secondary">
              GET STARTED
            </Button>
          </Box>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => firebase.logout()}
        >
          <SnackbarContent
            style={{
              backgroundColor: '#ff7f7f',
              fontSize: 16,
            }}
            message={<span id="client-snackbar">{errorMessage}</span>}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => firebase.logout()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </form>
    </SignBase>
  );
};

export default SignUp;
