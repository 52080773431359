import { showError, hideError } from '../actionsCreators/errorActionCreator';

/**
 * Receive an error and display a Toast message
 * 
 * @param {Object} error Error object from try catches 
 */
export const setShowErrorAction = ({ error }) => async (dispatch) => {
  dispatch(showError({ message: error.message }));
  console.error(error);
};

/**
 * Hide Toast message error
 */
export const setHideErrorAction = () => async (dispatch) => {
    try {
      dispatch(hideError());
    } catch (error) {
      console.error(error);
    }
};
