import { useState } from 'react';

const useConfirm = () => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deletedData, setDeletedData] = useState(null);

  const closeConfirmModal = () => {
    setDeletedData(null)
    setOpenConfirm(false);
  };

  const openConfirmModal = (data) => {
    setOpenConfirm(true);
    setDeletedData(data);
  };

  return { openConfirm, closeConfirmModal, openConfirmModal, deletedData };
};

export default useConfirm;
