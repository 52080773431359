import React, { useContext } from 'react';

import CardSettings from './CardSettings';
import { CardContext } from '../context';
import { useMyLabels } from 'core/store/selectors';
import { useAuth } from 'core/store/selectors';
import { useCardStateDataConnect } from 'core/store/connectors';
import { renderLabels } from 'core/helpers/labels';
  
const CardFooter = () => {
  
  const { settings, onAddLabel, onDeleteLabel, handleSmallUiChange, small } = useContext(CardContext);

  const auth = useAuth();

  useCardStateDataConnect(settings.id, auth?.uid);

  const stateLabels = useMyLabels();

  return (
    <div className="card__footer">
      <div className="card__footer__inner">
        <CardSettings />
        {renderLabels({stateLabels, card: settings, onAddLabel, onDeleteLabel, small, handleSmallUiChange})}
      </div>
    </div>
  );
};

export default CardFooter;
