import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';

import Card from '../CardsLibrary/Card';
import { unpinCardAction } from 'core/store/actions/pinnedAreaActions';

import './styles.scss';

const PinnedArea = () => {
  const dispatch = useDispatch();
  const cards = useSelector(({ pinnedArea }) => pinnedArea.cards);

  const onCardUnpin = (card) => {
    dispatch(unpinCardAction(card));
  };
  
  return (
    <div className="pinned-area">
      {cards.map((card) => (
        <Draggable key={card.id} bounds="parent">
          <div
            style={{
              position: 'absolute',
              bottom: '100px',
              right: '100px',
              width: 200,
              maxWidth: '100%',
              pointerEvents: 'auto',
            }}
          >
            <Card
              card={card}
              onUnpin={onCardUnpin}
              disableActions
            />
          </div>
        </Draggable>
      ))}
    </div>
  );
};

export default PinnedArea;
