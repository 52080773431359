import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseEditorStateProvider } from '@kwilio/editor';
import {
  AppBar,
  Avatar,
  CssBaseline,
  Drawer,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import { DragDropContext } from 'react-beautiful-dnd';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';

import ProjectMenu from 'components/ProjectMenu';
import PinnedArea from 'components/PinnedArea';
import TabsItems from 'components/Sidebar/TabsItems';
import useDragDrop from 'core/hooks/useDragDrop';
import routes from 'core/router/default';
import { 
  useMyProjectsConnect,
  useMyCardLibraryConnect,
  useMyBoardConnect,
  useMyLabelsConnect, 
  useMyTypesConnect,
  useMyCardsConnect,
  useMySidebarsConnect,
} from 'core/store/connectors';
import { 
  useCurrentProject, 
  useCurrentSidebar,
  useDefaultStateRef,
  useAuth,
} from 'core/store/selectors';
import { setProjectLastUpdated } from 'core/store/actions/projectsActions';
import BaseLayoutContainer from './container';
import useStyles from './useStyles.js';
import { createSidebarForProject, setSidebarOpen } from 'core/store/actions/sidebarActions';

const ESPInner = memo(function EditorStateAwareChildren({
  open,
  toggleDrawer,
}) {
  const classes = useStyles();

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <TabsItems toggleDrawer={toggleDrawer} />
      </Drawer>
      <BaseLayoutContainer classes={classes} open={open} routes={routes} />
      <PinnedArea />
    </>
  );
});

const UserAvatar = () => {
  const firebase = useFirebase();
  const [menuAnchor, setMenuAnchor] = useState();
  const history = useHistory();

  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(undefined);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar
          src={firebase.auth().currentUser.photoURL}
          alt={firebase.auth().currentUser.displayName}
        />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => history.push('/app/profile')}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </MenuItem>
        <MenuItem onClick={() => firebase.logout()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};

const BaseLayout = () => {
  const { onDragEnd } = useDragDrop();
  const auth = useAuth();
  const currentProject = useCurrentProject();
  const defaultStateRef = useDefaultStateRef();
  const classes = useStyles();
  const open = useSelector((state) => state.sidebar.open);

  const [dragging, setDragging] = useState(false);
  const dispatch = useDispatch();
  
  useMyLabelsConnect(auth.uid);
  useMyTypesConnect(auth.uid);
  useMyCardsConnect(auth.uid);
  useMyProjectsConnect();
  useMySidebarsConnect(auth.uid);
  useMyCardLibraryConnect(auth.uid);
  useMyBoardConnect(auth.uid);
  
  const sidebar = useCurrentSidebar({projectId: currentProject?.id});

  useEffect(() => {
    if (currentProject) {
      dispatch(createSidebarForProject({userId: auth.uid, currentSidebar: sidebar, projectId: currentProject?.id}));
    }
  }, [sidebar, currentProject, auth, dispatch]);
  
  const toggleDrawer = () => {
    dispatch(setSidebarOpen(!open));
  };

  const handleDragEnd = (results) => {
    onDragEnd(results);
    setDragging(false);
  };

  const handleFirebaseUpdate = () => {
    dispatch(setProjectLastUpdated(currentProject.id));
  };

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={(source) => {
        if (source.type === 'COLUMN') return;
        setDragging(true);
      }}
    >
      <div className={clsx([classes.root, { 'dragging-active': dragging }])}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar className="Toolbar" backgroundcolor="secondary">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <ProjectMenu />
            <UserAvatar />
          </Toolbar>
        </AppBar>
        <FirebaseEditorStateProvider
          key={currentProject?.id || 'no-project'}
          fireStateRef={defaultStateRef}
          onFirebaseUpdate={handleFirebaseUpdate}
          localStateThrottle="manual"
        >
          <ESPInner {...{ open, toggleDrawer }} />
        </FirebaseEditorStateProvider>
      </div>
    </DragDropContext>
  );
};

export const BaseLayoutRouter = {
  path: '/app',
  component: BaseLayout,
  routes,
};

export default BaseLayoutRouter;
