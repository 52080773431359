import { SET_OPEN } from "../actionsTypes/sidebar";

const sidebar = (
  state = {
    open: true
  },
  { type, payload = {} }
) => {
  const { open } = payload;
  switch (type) {
    case SET_OPEN:
      return {
        ...state,
        open: open,
      };
    default:
      return state;
  }
};

export default sidebar;
