import { setLibraryFilter, setBoardFilter, setSidebarFilter, setProjectsFilter } from '../actionsCreators/filterActionCreator';
import { setShowErrorAction } from './errorActions';

export const setLibraryFilterAction = ({ name, value }) => async (dispatch) => {
  try {
    dispatch(setLibraryFilter({ name, value }));
  } catch (error) {
    dispatch(setShowErrorAction({error}));
  }
};

export const setBoardFilterAction = ({ name, value }) => async (dispatch) => {
  try {
    dispatch(setBoardFilter({ name, value }));
  } catch (error) {
    dispatch(setShowErrorAction({error}));
  }
};

export const setSidebarFilterAction = ({ name, value }) => async (dispatch) => {
    try {
      dispatch(setSidebarFilter({ name, value }));
    } catch (error) {
      dispatch(setShowErrorAction({error}));
    }
};

export const setProjectsFilterAction = ({ name, value }) => async (dispatch) => {
  try {
    dispatch(setProjectsFilter({ name, value }));
  } catch (error) {
    dispatch(setShowErrorAction({error}));
  }
};
