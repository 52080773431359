import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SubjectIcon from '@material-ui/icons/Subject';
import Moment from 'react-moment';

import { useShareToInternet } from './ProjectShareMenu.hooks';
import useStyles from './ProjectShareMenu.styles';

const PublishProject = ({ project }) => {
  const classes = useStyles();

  const {
    title,
    setTitle,
    baseUrl,
    kebabedTitle,
    publishDisabled,
    publish,
    publishedBefore,
    publishable,
    publishing,
    changingTitle,
    publishedAt,
    outdated,
    currentTitle,
    currentPermalink,
    publishSuccess,
    publishError,
    unpublish,
    unpublishing,
    unpublishDisabled,
  } = useShareToInternet(project.id);

  if (!publishable) {
    return null;
  }

  const publishButtonLabel = publishedBefore ? 'Update' : 'Publish';

  const publishButtonContent = publishSuccess ? (
    <CheckIcon htmlColor="green" />
  ) : publishError ? (
    <CloseIcon htmlColor="red" />
  ) : (
    publishButtonLabel
  );

  return (
    <Box>
      <Typography variant="subtitle2" color="primary">
        Share {project.name} to Internet
      </Typography>
      <Box mb={1}>
        <Divider />
      </Box>
      <Box mb={1}>
        <TextField
          id="new-permalink"
          value={title}
          label="Title/Permalink"
          fullWidth
          autoFocus
          autoComplete="off"
          color="secondary"
          error={changingTitle}
          helperText={
            changingTitle && (
              <span>Changing the title/permalink comes with consequences.</span>
            )
          }
          onChange={({ target: { value } }) => setTitle(value)}
        />
        <Box mt={changingTitle ? 1 : 0} mb={1}>
          <Typography variant="caption" className={classes.link}>
            {baseUrl}
            <strong>{kebabedTitle}</strong>
          </Typography>
        </Box>
        {publishedAt && (
          <Box mb={2}>
            <Typography variant="body2" color="textSecondary" component="p">
              Published as{' '}
              <Link color="secondary" href={`${baseUrl}${currentPermalink}`} target="_blank">
                {currentTitle}
              </Link>
              {': '}
              <Tooltip title={publishedAt.format('llll')}>
                <Moment fromNow>{publishedAt}</Moment>
              </Tooltip>
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          {publishedBefore && (
            <Tooltip
              title={`Content has ${
                !outdated ? 'not ' : ''
              }changed since last publish`}
            >
              <Box mr={1}>
                <SubjectIcon color={outdated ? 'primary' : 'disabled'} />
              </Box>
            </Tooltip>
          )}
        </Box>
        <Box display="flex" flexDirection="row">
          {publishedBefore && (
            <Box mr={1}>
              <Button
                variant="outlined"
                disabled={unpublishDisabled}
                onClick={unpublish}
              >
                {unpublishing ? (
                  <CircularProgress size={24} color="secondary" thickness={2} />
                ) : (
                  'Unpublish'
                )}
              </Button>
            </Box>
          )}
          <Button
            color="secondary"
            variant="outlined"
            disabled={publishDisabled}
            onClick={publish}
          >
            {publishing ? (
              <CircularProgress size={24} color="secondary" thickness={2} />
            ) : (
              publishButtonContent
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PublishProject;
