import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import ListItem from './ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    // boxShadow: '0px 0px 9px -1px rgba(0,0,0,0.75)',
  },
}));

const propTypes = {
  onItemEdit: PropTypes.func,
  onItemDelete: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape()),
  renderItem: PropTypes.func,
};

const defaultProps = {
  onItemEdit: null,
  onItemDelete: null,
  renderItem: null,
  items: [],
};

const CustomList = ({ onItemEdit, onItemDelete, items, renderItem }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label={`${items.length}_list`}>
        {items.length < 1 && (
          <div style={{ padding: 10 }}>No data to show</div>
        )}
        {items.length > 0 &&
          items.map((item) => (
                <ListItem
                  onEdit={onItemEdit}
                  onDelete={onItemDelete}
                  key={item.id}
                  renderItem={renderItem}
                  item={item}
                />
          ))
        }
      </List>
    </div>
  );
};

CustomList.propTypes = propTypes;
CustomList.defaultProps = defaultProps;

export default CustomList;
