import { setProjectLastUpdated } from './projectsActions';
import retry from 'async-retry';

export const setStateNavigationInfo = (
  projectId,
  stateId,
  navigationInfo
) => async (_dispatch, _getState, { getFirestore }) => {
  const firestore = getFirestore();

  return firestore
    .collection('projects')
    .doc(projectId)
    .collection('statesData')
    .doc(stateId)
    .set({ navigationInfo }, { merge: true });
};

export const setStateLastUpdated = (projectId, stateId = 'default') => async (
  dispatch,
  _getState,
  { getFirestore }
) => {
  const firestore = getFirestore();

  dispatch(setProjectLastUpdated(projectId));

  return firestore
    .collection('projects')
    .doc(projectId)
    .collection('statesData')
    .doc(stateId)
    .set(
      { updatedAt: firestore.FieldValue.serverTimestamp() },
      { merge: true }
    );
};

export const uploadImage = (file) => async (
  _dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const storage = firebase.storage();
  const { uid } = getState().firebase.auth;
  const { ref } = await storage.ref(`images/${uid}/${file.name}`).put(file);
  return ref.getDownloadURL();
};

const getStateFileRef = (getState, getFirebase, permalink) => {
  const firebase = getFirebase();
  const storage = firebase.storage();
  const { uid } = getState().firebase.auth;
  return storage.ref(`live/${uid}/${permalink}.json`);
};

const fileExists = async (fileRef) => {
  try {
    await fileRef.getMetadata();
    return true;
  } catch (err) {
    if (err.code === 'storage/object-not-found') {
      return false;
    }
    throw err;
  }
};

const verifyFileExists = async (fileRef) => {
  if (await fileExists(fileRef)) {
    throw new Error('Permalink already in use in a different project.');
  }
};

export const publishState = (projectId, stateId, title, permalink) => async (
  _dispatch,
  getState,
  { getFirestore, getFirebase }
) => {
  const fileRef = getStateFileRef(getState, getFirebase, permalink);
  await verifyFileExists(fileRef);

  const firestore = getFirestore();

  const state = await firestore
    .collection('projects')
    .doc(projectId)
    .collection('states')
    .doc(stateId)
    .get();

  const publishDataRef = firestore
    .collection('projects')
    .doc(projectId)
    .collection('publishData')
    .doc(stateId);

  // sets published data
  await publishDataRef.set(
    {
      permalink,
      publishedAt: firestore.FieldValue.serverTimestamp(),
      title,
    },
    { merge: true }
  );

  // tries to get publishedAt up to n times before falling back to local time
  const maxAttempts = 5;
  const publishData = await retry(
    async (_bail, attempt) => {
      console.log(attempt);
      const data = (await publishDataRef.get()).data();
      if (!data.publishedAt) {
        if (attempt === maxAttempts) {
          return {
            ...data,
            publishedAt: firestore.Timestamp.now(),
          };
        }
        throw new Error();
      }
      return data;
    },
    { retries: maxAttempts }
  );

  const contents = JSON.stringify({
    ...state.data(),
    projectId,
    stateId,
    publishData,
  });

  // uploads public file
  await fileRef.putString(contents);
};

export const unpublishState = (projectId, stateId) => async (
  _dispatch,
  getState,
  { getFirestore, getFirebase }
) => {
  const firestore = getFirestore();

  const publishDataRef = firestore
    .collection('projects')
    .doc(projectId)
    .collection('publishData')
    .doc(stateId);

  const publishData = (await publishDataRef.get()).data();

  // if indeed published
  if (publishData) {
    const fileRef = getStateFileRef(getState, getFirebase, publishData.permalink);
    await fileRef.delete();
    await publishDataRef.delete();
  }
};

export const republishState = (
  projectId,
  stateId,
  title,
  permalink,
  previousPermalink
) => async (dispatch, getState, { getFirebase }) => {
  if (permalink !== previousPermalink) {
    // check if new permalink is already in use
    const newFileRef = getStateFileRef(getState, getFirebase, permalink);
    await verifyFileExists(newFileRef);
  }

  // remove old published file
  const fileRef = getStateFileRef(getState, getFirebase, previousPermalink);
  await fileRef.delete();

  await dispatch(publishState(projectId, stateId, title, permalink));
};
