import { SHOW_ERROR, HIDE_ERROR } from '../actionsTypes/error';

const error = (
  state = {
    message: null
  },
  { type, payload = {} }
) => {
  switch (type) {
    case SHOW_ERROR:
      return {
        ...state,
        message: payload.message,
      };
    case HIDE_ERROR:
      return {
        ...state,
        message: null,
      };
    default:
      return state;
  }
};

export default error;
