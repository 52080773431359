import * as firebase from 'firebase/app';

import { useCurrentProject } from './useCurrentProject';

export const useDefaultStateRef = () => {
  const currentProject = useCurrentProject();
  if (currentProject) {
    return firebase
      .firestore()
      .doc(`/projects/${currentProject.id}/states/default`);
  }
  return undefined;
};
