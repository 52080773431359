export const defaultIconsColor = '#6d6d6d';
export const drawerWidth = 340;
export const defaultCardColor = '#ededed';
export const grid = 5;
export const collapsedHeight = 90;
export const collapsedVerticalHeight = 50;
export const standartHeight = 200;
export const LOCATION_SIDEBAR = 'location_sidebar';
export const LOCATION_BOARD = 'location_board';
export const LOCATION_LIBRARY = 'location_library';

export const columnTypes = [
  { label: 'Single', type: 'single' },
  { label: 'Double column', type: 'double' },
  { label: 'Multi column', type: 'multi' },
];
