import React, { Suspense, lazy } from 'react';
import Preloader from '../Shared/Preloader';
import './styles.scss';

const BoardContainer = lazy(() => import('./BoardContainer'));

const Board = ({ columns }) => {
  return (
    <Suspense
      fallback={
        <div style={{ paddingTop: 50 }}>
          <Preloader />
        </div>
      }
    >
      <BoardContainer columns={columns} />
    </Suspense>
  );
};

export default Board;
