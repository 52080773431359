import { useFirestoreConnect } from 'react-redux-firebase';

import { useAuth } from '../selectors';

export const useMyProjectsConnect = () => {
  const auth = useAuth();
  return useFirestoreConnect([
    {
      collection: 'projects',
      storeAs: 'myProjects',
      where: [[`roles.${auth.uid}`, '==', 'owner']],
    },
  ]);
};

export const useProjectStateDataConnect = (
  projectId,
  stateId = 'default',
  storeAs = `${projectId}.${stateId}`
) => {
  return useFirestoreConnect([
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'statesData', doc: stateId }],
      storeAs: storeAs,
    },
  ]);
};

export const useProjectPublishDataConnect = (
  projectId,
  stateId = 'default',
  storeAs = `${projectId}.${stateId}.publishData`
) => {
  return useFirestoreConnect([
    {
      collection: 'projects',
      doc: projectId,
      subcollections: [{ collection: 'publishData', doc: stateId }],
      storeAs: storeAs,
    },
  ]);
};

export const useMyTypesConnect = (userId) => {
  return useFirestoreConnect([
    {
      collection: 'users',
      doc: userId,
      subcollections: [{ collection: 'types' }],
      storeAs: 'myTypes',
    },
  ]);
};

export const useMyLabelsConnect = (userId) => {
  return useFirestoreConnect([
    {
      collection: 'users',
      doc: userId,
      subcollections: [{ collection: 'labels' }],
      storeAs: 'myLabels',
    },
  ]);
};

export const useMyCardLibraryConnect = (userId) => {
  return useFirestoreConnect([
    {
      collection: 'users',
      doc: userId,
      subcollections: [
        { 
          collection: 'cardLibrary', 
          doc: 'default' 
        },
        { 
          collection: 'columns', 
        }
      ],
      storeAs: 'myCardLibrary',
    },
  ]);
};

export const useMyBoardConnect = (userId) => {
  return useFirestoreConnect([
    {
      collection: 'users',
      doc: userId,
      subcollections: [
        { 
          collection: 'board', 
          doc: 'default' 
        },
        { 
          collection: 'columns', 
        }
      ],
      storeAs: 'myBoardColumns',
    },
  ]);
};

export const useMyCardsConnect = (userId) => {
  return useFirestoreConnect([
    {
      collection: 'users',
      doc: userId,
      subcollections: [{ collection: 'cards' }],
      storeAs: 'myCards',
    },
  ]);
};

export const useCardStateDataConnect = (
  cardId,
  userId,
  stateId = 'default',
  storeAs = `myCards.${cardId}.states.${stateId}`
) => {
  return useFirestoreConnect([
    {
      collection: 'users',
      doc: userId,
      subcollections: [
        {
          collection: 'cards',
          doc: cardId
        },
        { 
          collection: 'states', 
          doc: 'default' 
        }
      ],
      storeAs: storeAs,
    },
  ]);
};


export const useMySidebarsConnect = (userId) => {
  return useFirestoreConnect([
    {
      collection: 'users',
      doc: userId,
      subcollections: [{ collection: 'sidebars' }],
      storeAs: 'mySidebars',
    },
  ]);
};
