import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IconButton, Popover } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import LabelForm from './LabelForm';
import LabelsList from './LabelsList';
import { createLabelAction } from 'core/store/actions/labelsActions';
import { useMyLabels } from 'core/store/selectors/useMyLabels';
import { useAuth } from 'core/store/selectors';
import './styles.scss';
import { defaultIconsColor } from 'core/constants';

const propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape()),
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  small: PropTypes.bool,
};

const defaultProps = {
  labels: [],
  onDelete: () => {},
  onAdd: () => {},
  small: false,
};

const Labels = ({ labels, onDelete, onAdd, small, handleSmallUiChange }) => {

  const dispatch = useDispatch();
  
  const labelsList = useMyLabels();
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleDelete = (label) => () => {
    onDelete(label);
  };

  const handleAdd = (label) => {
    onAdd(label);
  };

  const handleCreate = (label) => {
    dispatch(createLabelAction(auth?.uid, label));
  };

  const filtredLabelsList = labelsList?.filter(
    ({ id }) => !labels.some((item) => item?.id === id)
  );

  const renderAdd = () => {
    return (
      <div className="labels-actions">
        <IconButton
          style={{ position: 'relative', color: defaultIconsColor }}
          size="small"
          aria-label="add"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <AddIcon />
        </IconButton>
        <Popover
          open={open}
          className="labels-popover"
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <LabelForm
            onAdd={handleAdd}
            onCreate={handleCreate}
            labelsList={filtredLabelsList}
          />
        </Popover>
      </div>
    )
  }

  return (
    <div className="labels-wrapper">
      {small && renderAdd()}
      <LabelsList
        labels={labels}
        onClickLabelDelete={handleDelete}
        small={small}
        handleSmallUiChange={handleSmallUiChange}
      />
      {!small && renderAdd()}
    </div>
  );
};

Labels.propTypes = propTypes;
Labels.defaultProps = defaultProps;

export default Labels;
