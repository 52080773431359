import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 'min(100vw, 480px)',
    },
    link: {
      wordBreak: 'break-all',
    },
  })
);
