import _difference from 'lodash/difference';
import _trim from 'lodash/trim';
import _toLower from 'lodash/toLower';
import _isEmpty from 'lodash/isEmpty';

export const arraysDiff = (a, b) => {
  if (a.length > b.length) {
    return _difference(a, b);
  } else {
    return _difference(b, a);
  }
};

export const filterByLabels = (arr, filterIds) => {
  return arr.filter((item) => {
    const attrIds = item?.labels?.map((id) => id) || [];
    return attrIds.some((attrId) =>
      filterIds.some((labelId) => labelId === attrId)
    );
  });
};

export const filterByTypes = (arr, filterTypes) => {
  return arr.filter((item) => {
    return filterTypes.some((fId) => fId.id === item?.type);
  });
};

export const filterByStringField = (arr, filter, field) => {
  if (!_trim(filter)) return arr;
  return arr.filter((item) => {
    return _toLower(item[field]).includes(_trim(_toLower(filter)));
  });
};

export const cardsFilter = (arr, filter) => {
  const filterKeys = Object.keys(filter);
  let filterArr = arr;
  filterKeys.forEach((fKey) => {
    switch (fKey) {
      case 'labels':
        const labelsIds = filter[fKey].map(({ id }) => id);
        filterArr = !_isEmpty(labelsIds)
          ? filterByLabels(filterArr, labelsIds)
          : filterArr;
        break;
      case 'title':
        filterArr = filter[fKey].trim()
          ? filterByStringField(filterArr, filter[fKey], fKey)
          : filterArr;
        break;
      case 'types':
        const typesIds = filter[fKey];
        filterArr = !_isEmpty(typesIds)
          ? filterByTypes(filterArr, typesIds)
          : filterArr;
        break;
      default:
        break;
    }
  });
  return filterArr;
};

export const projectsFilter = (arr, filter) => {
  const filterKeys = Object.keys(filter);
  let filterArr = arr;
  filterKeys.forEach((fKey) => {
    switch (fKey) {
      case 'labels':
        const labelsIds = filter[fKey].map(({ id }) => id);
        filterArr = !_isEmpty(labelsIds)
          ? filterByLabels(filterArr, labelsIds)
          : filterArr;
        break;
      case 'title':
        filterArr = filter[fKey].trim()
          ? filterByStringField(filterArr, filter[fKey], 'name')
          : filterArr;
        break;
      default:
        break;
    }
  });
  return filterArr;
};
