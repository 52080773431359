import { useSelector } from 'react-redux';
import _find from 'lodash/find';

/**
 * Hook to use sidebar of the current project
 * @param {String} projectId Current project's ID
 */
export const useCurrentSidebar = ({projectId}) => 
    useSelector((state) => {
        const sidebars = state.firestore.ordered.mySidebars;
        if (sidebars) {
            return _find(sidebars, sb => sb.projectId === projectId)
        } else {
            return undefined;
        }
    })
        
            
