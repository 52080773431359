import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { projectsFilter } from 'core/helpers/filter';
import useConfirm from 'core/hooks/useConfirm';
import { setProjectsFilterAction } from 'core/store/actions/filterActions';
import {
  openNewProjectModalAction,
  setCurrentProjectAction,
  deleteProjectAction,
} from 'core/store/actions/projectsActions';
import { useMyProjects } from 'core/store/selectors';
import ConfirmModal from '../Shared/ConfirmModal';
import Filter from '../Shared/Filter';
import ProjectCard from './ProjectCard';

const ProjectsContainer = () => {
  const projects = useMyProjects();

  const filter = useSelector(({ filter }) => filter.projects);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    openConfirm,
    openConfirmModal,
    closeConfirmModal,
    deletedData,
  } = useConfirm();

  const handleButtonClick = (project) => {
    dispatch(setCurrentProjectAction(project.id));
    history.push('/app/project');
  };

  const handleConfirm = async () => {
    await dispatch(deleteProjectAction(deletedData.id));
    closeConfirmModal();
  };

  const handleProjectsFilterChange = (name, value) => {
    dispatch(setProjectsFilterAction({ name, value }));
  };

  const filteredProjects = projectsFilter(projects, filter);

  const openNewProject = () => {
    dispatch(openNewProjectModalAction());
  };

  return (
    <div>
      <Filter filter={filter} onFilterChange={handleProjectsFilterChange} />
      <Grid container spacing={3}>
        {filteredProjects.map((project, index) => (
          <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
            <ProjectCard
              onButtonClick={handleButtonClick}
              project={project}
              onDelete={openConfirmModal}
            />
          </Grid>
        ))}
      </Grid>
      <ConfirmModal
        open={openConfirm}
        onClose={closeConfirmModal}
        onConfirm={handleConfirm}
        title="Delete project?"
      />
      <Fab color="secondary" aria-label="add" onClick={openNewProject}>
        <AddIcon />
      </Fab>
    </div>
  );
};

export default ProjectsContainer;
