import React, { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  HeadingTreeProvider,
  NodeInput,
  useEditorState,
  useHeadingTree,
} from '@kwilio/editor';
import {
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SortableTree, { walk } from 'react-sortable-tree';

import { setStateNavigationInfo } from 'core/store/actions/stateActions';
import {
  useCurrentProject,
  useNavigationInfo,
} from 'core/store/selectors';
import NodeContentRenderer, { COMMAND_ROLE } from './NodeContentRenderer';
import TreeNodeRenderer from './TreeNodeRenderer';

const useStyles = makeStyles((theme) => ({
  navigationRoot: ({ maxHeight }) => ({
    maxHeight,
    overflow: 'auto',
  }),
  borderBottom: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  gps: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(30),
  },
  pullRight: {
    marginLeft: 'auto',
  },
}));

const rowHeight = 35;

const NavigationStateWrapper = (props) => {
  const dispatch = useDispatch();
  const { root } = useHeadingTree();

  const currentProject = useCurrentProject();

  const navigationInfo = useNavigationInfo(currentProject.id);

  const expand = (id, expanded = true) => {
    // nodeInfo(id).expanded = expanded;
    dispatch(
      setStateNavigationInfo(currentProject.id, 'default', {
        [id]: {
          expanded,
        },
      })
    );
  };

  const collapse = (id) => {
    expand(id, false);
  };

  const tree = useMemo(() => {
    if (root) {
      if (navigationInfo) {
        // updates expanded prop for the cached items
        walk({
          treeData: root.children,
          callback: ({ node: treeNode }) => {
            treeNode.expanded =
              navigationInfo?.[treeNode.nodeItem.id]?.expanded;
          },
          getNodeKey: ({ treeIndex }) => treeIndex,
        });
      }
      return root.children;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root]);

  return (
    <NavigationItemsWrapper
      {...{ ...props, defaultTree: tree, expand, collapse }}
    />
  );
};

const NavigationItemsWrapper = memo(function NavigationItemsWrapper({
  maxHeight,
  goAndScrollTo,
  defaultTree,
  expand,
  collapse,
}) {
  const { dispatch: dispatchEditorState } = useEditorState();
  const { root, moveNode, treeNodeMap } = useHeadingTree();

  const [tree, setTree] = useState();
  const [height, setHeight] = useState(0);

  const calculateHeight = useCallback(
    (treeData) => {
      let height = 0;
      walk({
        treeData,
        callback: ({ parentNode }) => {
          if (!parentNode || parentNode.expanded) {
            height += rowHeight;
          }
        },
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
      setHeight(height > maxHeight ? maxHeight : height);
    },
    [maxHeight]
  );

  /**
   * Updates the `expanded` flag
   * under current project `navigationInfo`
   * for the node (parent) and its descendants.
   */
  const handleVisibilityToggle = ({ node: parent, expanded, treeData }) => {
    if (expanded) {
      expand(parent.nodeItem.id);
    } else {
      collapse(parent.nodeItem.id);
      walk({
        treeData: parent.children,
        callback: ({ node: descendant }) => {
          collapse(descendant.nodeItem.id);
          descendant.expanded = false;
        },
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
    }
    calculateHeight(treeData);
  };

  const handleNodeMoved = (data) => {
    const node = treeNodeMap[data.node.nodeItem.id];
    let parent;
    let index;
    if (data.nextParentNode) {
      parent = treeNodeMap[data.nextParentNode.nodeItem.id];
      index = data.nextParentNode.children.indexOf(data.node);
    } else {
      parent = root;
      index = tree.indexOf(data.node);
    }
    const moved = moveNode(node, parent, index);
    if (moved && dispatchEditorState) {
      if (parent.nodeItem) {
        expand(parent.nodeItem.id);
      }
      dispatchEditorState();
    }
  };

  useEffect(() => {
    if (defaultTree) {
      setTree(defaultTree);
    }
  }, [defaultTree]);

  useEffect(() => {
    calculateHeight(tree);
  }, [calculateHeight, maxHeight, tree]);

  return (
    <NavigationItems
      {...{
        tree,
        height,
        rowHeight,
        updateTree: setTree,
        goAndScrollTo,
        handleNodeMoved,
        dispatchEditorState,
        handleVisibilityToggle,
      }}
    />
  );
});

const NavigationItems = memo(function NavigationItems({
  tree,
  height,
  rowHeight,
  updateTree,
  goAndScrollTo,
  handleNodeMoved,
  dispatchEditorState,
  handleVisibilityToggle,
}) {
  const classes = useStyles();
  if (tree && tree.length > 0) {
    return (
      <div style={{ height }}>
        <SortableTree
          rowHeight={rowHeight}
          theme={{
            nodeContentRenderer: NodeContentRenderer,
            treeNodeRenderer: TreeNodeRenderer,
          }}
          slideRegionSize={20}
          scaffoldBlockPxWidth={22}
          treeData={tree}
          onChange={updateTree}
          maxDepth={6}
          onMoveNode={handleNodeMoved}
          onVisibilityToggle={handleVisibilityToggle}
          generateNodeProps={({ node: treeNode, path }) => ({
            title: (
              <>
                <NodeInput
                  tag={Input}
                  style={{ width: '90%' }}
                  nodeItem={treeNode.nodeItem}
                  onBlur={() => {
                    dispatchEditorState();
                  }}
                />
                <IconButton
                  size="small"
                  role={COMMAND_ROLE}
                  className={classes.pullRight}
                  title="Navigate in the main editor"
                  onClick={() => {
                    goAndScrollTo(treeNode.from);
                  }}
                >
                  <PlayArrowIcon fontSize="inherit" />
                </IconButton>
              </>
            ),
          })}
        />
      </div>
    );
  }
  return (
    <ListItem className={classes.borderBottom}>
      <ListItemText secondary="No navigation to display." />
    </ListItem>
  );
});

const listItemHeight = 50;

export default function NavigationTree({ maxHeight, goAndScrollTo }) {
  const classes = useStyles({ maxHeight });
  const itemsMaxHeight = maxHeight - listItemHeight;
  return (
    <List className={classes.root} disablePadding>
      <ListItem className={`${classes.borderBottom} ${classes.header}`}>
        <GpsNotFixedIcon className={classes.gps} />
        <ListItemText primary="Navigation" />
      </ListItem>
      <HeadingTreeProvider>
        <NavigationStateWrapper
          maxHeight={itemsMaxHeight}
          goAndScrollTo={goAndScrollTo}
        />
      </HeadingTreeProvider>
    </List>
  );
}
