import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MoreHorIcon from '@material-ui/icons/MoreHoriz';
import MoreVerIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import _find from 'lodash/find';

import ColorPicker from '../../../../Shared/ColorPicker';
import TypesSelect from '../../../../Settings/Types/TypesSelect';
import { isBright } from 'core/helpers/color';
import ConfirmModal from '../../../../Shared/ConfirmModal';
import { cloneCardAction, updateCardAction } from 'core/store/actions/cardActions';
import { removeCardFromLibraryColumn } from 'core/store/actions/libraryColumnActions';
import { removeCardFromSubColumn } from 'core/store/actions/boardCardActions';
import { useAuth, useMyTypes, useCurrentProject, useCurrentSidebar } from 'core/store/selectors';
import { 
  LOCATION_BOARD, 
  LOCATION_SIDEBAR, 
  LOCATION_LIBRARY 
} from 'core/constants';
import { removeCardFromSidebar } from 'core/store/actions/sidebarActions';
import { CardContext } from '../context';

const propTypes = {
  settings: PropTypes.shape(),
  onSettingsChanged: PropTypes.func,
  onRemove: PropTypes.func,
  onCopy: PropTypes.func,
  onClose: PropTypes.func,
  color: PropTypes.string,
};

const defaultProps = {
  settings: {},
  onSettingsChanged: () => {},
  onClose: () => {},
  onRemove: () => {},
  onCopy: () => {},
};

const CardSettings = ({isVertical}) => {

  const { settings, location } = useContext(CardContext);

  const dispatch = useDispatch();
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(settings.color);
  const [showPicker, setShowPicker] = useState(false);
  
  const currentProject = useCurrentProject();
  const currentSidebar = useCurrentSidebar({projectId: currentProject?.id});

  const open = Boolean(anchorEl);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toDelete, setToDelete] = useState();
  
  const types = useMyTypes();
  const type = _find(types, type => type.id === settings.type);

  const handleSettingsChange = (settings) => {
    dispatch(updateCardAction({userId: auth.uid, updatedCard: settings}));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setConfirmModalOpen(false);
  };

  const handleCopy = (settings) => {
    setAnchorEl(null);
    dispatch(cloneCardAction({ userId: auth?.uid , cardToClone: settings, location, currentSidebar }));
  };

  const handleRemove = (settings) => {
    setAnchorEl(null);
    setToDelete({userId: auth?.uid, card: settings});
    setConfirmModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    switch (location) {
      case LOCATION_SIDEBAR:
        dispatch(
          removeCardFromSidebar({
            userId: toDelete.userId, 
            cardId: toDelete.card.id
          }));
        break;
      case LOCATION_BOARD:
        dispatch(
          removeCardFromSubColumn(
            {
              userId: toDelete.userId, 
              columnId: toDelete.card.columnId, 
              subColumnId: toDelete.card.subColumnId, 
              cardId: toDelete.card.id
            }
          )
        );
        break;
      case LOCATION_LIBRARY:
        dispatch(
          removeCardFromLibraryColumn({
            userId: toDelete.userId, 
            libraryColumnId: toDelete.card.libraryColumnId, 
            cardId: toDelete.card.id
          })
        )
        break;
      default:
        break;
    }
    setConfirmModalOpen(false);
  }

  return (
    <div className="card__settings">
      <div className="card__settings-inner">
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {isVertical ? 
            <MoreVerIcon 
              style={{ color: settings?.color }}
            />
          :
            <MoreHorIcon 
              style={{ color: settings?.color }}
            />
          }
        </IconButton>
        <Popover
          open={open}
          className="card__settings-inner-popover"
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <TypesSelect
            onChange={(value) =>
              handleSettingsChange({ ...settings, type: value.id })
            }
            color={settings?.color}
            value={type}
          />
          <div style={{ 
            position: 'relative', 
            marginTop: 10
          }}>
            Card color:{' '}
            <span
              className="color-preview"
              style={{
                borderRadius: 4,
                padding: '4px 6px 4px 6px',
                display: 'inline-block',
                cursor: 'pointer',
                background: settings?.color,
                color: isBright(settings?.color) ? 'black' : 'white', 
              }}
              onClick={() => setShowPicker(!showPicker)}
            >
              {color}
            </span>
            {showPicker && (
              <ColorPicker
                color={color}
                onChangeComplete={(color) => {
                  handleSettingsChange({ ...settings, color })
                }}
                onChange={(color) => {
                  setColor(color)
                }}
                onOutsideClick={() => setShowPicker(false)}
              />
            )}
          </div>

          <div
            style={{
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <ButtonGroup variant="contained" size="small">
              <Button onClick={() => handleCopy(settings)} color="primary">
                Clone
              </Button>
              <Button onClick={() => handleRemove({...settings})} style={{backgroundColor: '#D11A2A'}}>
                <DeleteForeverIcon style={{ color: 'white' }} />
              </Button>
            </ButtonGroup>
          </div>
        </Popover>
      </div>
      <ConfirmModal
        open={confirmModalOpen}
        onClose={handleClose}
        onConfirm={handleDeleteConfirm}
        title="Delete card?"
        text="You cannot restore this card."
      />
    </div>
  );
};

CardSettings.propTypes = propTypes;
CardSettings.defaultProps = defaultProps;

export default CardSettings;
