import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';

import LabelModal from './LabelModal';
import CustomList from '../../Shared/CustomList';
import ConfirmModal from '../../Shared/ConfirmModal';
import ColorPoint from '../../Shared/ColorPoint';
import {
  createLabelAction,
  updateLabelAction,
  deleteLabelAction,
} from 'core/store/actions/labelsActions';
import { useMyLabels } from 'core/store/selectors/useMyLabels';
import { useAuth } from 'core/store/selectors';

const Labels = () => {
  const dispatch = useDispatch();

  const labels = useMyLabels();
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [editableItem, setEditableItem] = useState(null);
  const [deletedId, setDeletedId] = useState(null);


  const modalToggle = () => {
    if (open) setEditableItem(null);
    setOpen(!open);
  };

  const cofirmModalToggle = () => {
    setConfirmModal(!confirmModal);
  };

  const onLabelEdit = ({ id, title, color }) => {
    setEditableItem({ id, title, color });
    modalToggle();
  };

  const onLabelDelete = ({ id }) => {
    setDeletedId(id);
    cofirmModalToggle();
  };

  const editItem = (item) => {
    dispatch(updateLabelAction(auth?.uid,editableItem.id, item));
  };

  const createItem = (item) => {
    dispatch(createLabelAction(auth?.uid,item));
  };

  const onLabelSave = (item) => {
    if (editableItem) editItem(item);
    if (!editableItem) createItem(item);
    modalToggle();
  };

  const onModalOpen = () => {
    setEditableItem(null);
    modalToggle();
  };

  const onConfirm = () => {
    dispatch(deleteLabelAction(auth?.uid, deletedId));
    setDeletedId(null);
    cofirmModalToggle();
  };

  return (
    <div>
      <h3
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Labels
        <Button
          onClick={onModalOpen}
          variant="contained"
          size="small"
          color="primary"
        >
          Create
        </Button>
      </h3>
      <CustomList
        onItemEdit={onLabelEdit}
        onItemDelete={onLabelDelete}
        items={labels}
        renderItem={({ color, title }) => (
          <Fragment>
            <ColorPoint style={{ marginRight: 10 }} color={color} />
            {title}
          </Fragment>
        )}
      />
      <LabelModal
        initData={editableItem}
        onSave={onLabelSave}
        open={open}
        toggle={modalToggle}
      />
      <ConfirmModal
        title={'Delete Label?'}
        open={confirmModal}
        onClose={cofirmModalToggle}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default Labels;
