import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';

export const useLive = () => {
  const [fetching, setFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState();
  const [contents, setContents] = useState();
  const [publishData, setPublishData] = useState();
  const { userId, permalink } = useParams();

  const firebase = useFirebase();
  const storage = firebase.storage();

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const fileRef = storage.ref(`live/${userId}/${permalink}.json`);
        const url = await fileRef.getDownloadURL();
        const res = await fetch(url);
        const data = await res.json();
        const { projectId, stateId, publishData, ...contents } = data;
        setContents(contents);
        setPublishData(publishData);
      } catch (error) {
        setFetchingError(error);
      } finally {
        setFetching(false);
      }
    };
    if (userId && permalink) {
      fetchFile();
    }
  }, [storage, userId, permalink]);

  return { fetching, fetchingError, contents, publishData };
};
