import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';

export const useMyCardLibrary = () => 
    useSelector((state) => {
        const cardLibrary = state.firestore.ordered.myCardLibrary;
        if (cardLibrary) {
            return _orderBy(cardLibrary, 'position', 'asc');;
        } else {
            return undefined;
        }
    }
);
