import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, FormControl, TextField } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ColorLensOutlinedIcon from '@material-ui/icons/ColorLensOutlined';
import ColorPicker from '../../ColorPicker';

import LabelsList from '../LabelsList';
import './styles.scss';
import { isBright } from 'core/helpers/color';
import { defaultIconsColor } from 'core/constants';

const propTypes = {
  onAdd: PropTypes.func,
  onCreate: PropTypes.func,
  labelsList: PropTypes.arrayOf(PropTypes.shape()),
};

const defaultProps = {
  onAdd: () => {},
  onCreate: () => {},
  labelsList: [],
};

const defaultLabel = {
  title: '',
  color: 'rgb(210, 202, 202)',
};

const LabelForm = ({ onAdd, onCreate, labelsList }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [newLabel, setNewLabel] = useState(defaultLabel);

  const handelLabelChange = (newData) => {
    setNewLabel({ ...newLabel, ...newData });
  };

  const handleAdd = (label) => onAdd(label);

  const handleCreate = (label) => {
    setNewLabel(defaultLabel);
    onCreate(label);
  };

  return (
    <div className="labels-form">
      <FormControl style={{ width: '100%', marginBottom: 10 }}>
        <TextField
          onChange={({ target }) => handelLabelChange({ title: target.value })}
          value={newLabel.title}
          autoFocus
          id="new-label"
          label="Label"
          placeholder="Add or select"
          className="labels-form__input"
        />
      </FormControl>
      {labelsList.length > 0 ? (
        <LabelsList
          labels={labelsList}
          onAdd={onAdd}
          onClickLabelDelete={handleAdd}
          labelIcon={<AddCircleIcon style={{width: '15px', color: 'white'}}/>}
          style={{ width: '100%', marginBottom: 10 }}
          disableSmallChange={true}
        />
      ) : (
        <div style={{ marginBottom: 10 }}>No labels to select</div>
      )}
      <div className="labels-form__new-label new-label">
        <Chip
          style={{ 
            backgroundColor: newLabel.color, 
            color: isBright(newLabel.color) ? 'black' : 'white'
          }}
          label={newLabel.title}
          onDelete={() => setShowPicker(true)}
          className="new-label__preview"
          deleteIcon={
            <ColorLensOutlinedIcon 
              style={{color: isBright(newLabel.color) ? defaultIconsColor : 'white' }}
            />
          }
        />
        <AddCircleIcon
          className={`new-label__action ${
            newLabel.title.trim() ? '' : 'disabled'
          }`}
          onClick={() => handleCreate(newLabel)}
        />
        {showPicker && (
          <ColorPicker
            color={newLabel.color}
            onChange={(color) => handelLabelChange({ color })}
            onOutsideClick={() => setShowPicker(false)}
          />
        )}
      </div>
    </div>
  );
};

LabelForm.propTypes = propTypes;
LabelForm.defaultProps = defaultProps;

export default LabelForm;
