import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    card: {
      margin: 'auto',
      top: '-12.5%',
      left: '15.5%',
      background: 'linear-gradient(90deg, #4F4F4F 0%, #979797 100%)',
      boxShadow:
        '0px 5px 15px rgba(0, 0, 0, 0.5), 0px 4px 25px rgba(0, 0, 0, 0.12)',
      borderRadius: '5px',
      paddingTop: '30px',
      position: 'absolute',
      overflow: 'visible',
    },
    heading: {
      color: 'white',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      lineHeight: '23px',
      marginBottom: '1em',
    },
    whiteText: {
      color: 'white',
      marginBottom: '1em',
    },
    icon: {
      cursor: 'pointer',
    },
    feather: {
      position: 'absolute',
      left: '-10%',
      top: '-30%',
      width: '84px',
    },
  })
);
