import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import './styles.scss';

const Preloader = ({ size, withWrapper, ...props }) => {
  if (withWrapper) {
    return (
      (
        <div className="preloader-wrapper">
          <div className="preloader">
            
            <CircularProgress {...props} />
          </div>
        </div>
      )
    );
  }
  return (
    (
      <div className="preloader">
        <CircularProgress {...props} />
      </div>
    )
  );
};

Preloader.propTypes = {
  size: PropTypes.string,
  withWrapper: PropTypes.bool,
};

Preloader.defaultProps = {
  size: 'md',
  withWrapper: false,
};

export default Preloader;
