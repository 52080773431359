import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import LabelsSelect from './LabelsSelect';
import TypesSelect from './TypesMultiSelect';
import { useMyTypes } from 'core/store/selectors/useMyTypes';
import { useMyLabels } from 'core/store/selectors/useMyLabels';

const propTypes = {
  filter: PropTypes.shape({
    types: PropTypes.array,
    labels: PropTypes.array,
    title: PropTypes.string,
  }),
  onFilterChange: PropTypes.func,
};

const defaultProps = {
  filter: {
    types: null,
    labels: null,
    title: '',
  },
  onFilterChange: () => {},
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'left',
  },
}));

const Filter = ({ filter, onFilterChange, className, ...props }) => {
  const classes = useStyles();

  const labels = useMyLabels();
  const types = useMyTypes();
  
  const handleFilterChange = (name, value) => {
    onFilterChange(name, value);
  };

  const handleTitleChange = _debounce((name, value) => {
    handleFilterChange(name, value);
  }, 300);

  const handleLabelDelete = (id) => {
    const filtredLabels = filter.labels.filter((l) => l.id !== id);
    handleFilterChange('labels', filtredLabels);
  };

  const handleTypesDelete = (id) => {
    const filtredTypes = filter.types.filter((t) => t.id !== id);
    handleFilterChange('types', filtredTypes);
  };

  return (
    <div className={clsx([classes.root, className])} {...props}>
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <TextField
            id="search"
            defaultValue={filter.title}
            label="Search by title"
            fullWidth
            onChange={({ target }) => handleTitleChange('title', target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {filter.types && (
          <Grid item md={4} xs={12}>
            <TypesSelect
              options={types}
              value={filter.types}
              onChange={({ target }) =>
                handleFilterChange('types', target.value)
              }
              onOptionDelete={handleTypesDelete}
            />
          </Grid>
        )}
        {filter.labels && (
          <Grid item md={4} xs={12}>
            <LabelsSelect
              options={labels}
              value={filter.labels}
              onChange={({ target }) =>
                handleFilterChange('labels', target.value)
              }
              onOptionDelete={handleLabelDelete}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

export default Filter;
