import _cloneDeep from 'lodash/cloneDeep';

import { 
  createColumns, 
  reorderColumns 
} from 'core/helpers/columns';
import { 
  getColumns, 
  getColumnById 
} from 'core/helpers/columns';
import { forAsync } from "core/helpers/forAsync";
import { setShowErrorAction } from "./errorActions";
import { moveBoardCard } from 'core/store/actions/cardActions';

/**
 * Create a column in the board
 * 
 * @param {String} userId Column's Owner ID
 * @param {Object} columnData Data of the column to be created
 * @param {String} projectId Data of the column to be created
 */
export const createBoardColumnAction = ({userId, columnData, projectId}) => async (
  _dispatch,
  _getState,
  { getFirestore } 
) => {
    
    const columns = getColumns(_getState);

    let type;
    if (columnData.type) {
      type = columnData.type.type; 
    }

    const newCol = {
      ...columnData,
      type: type,
      color: columnData.color || '#DADADA',
      position: columns.length + 1, 
      projectId: projectId,
    }

    return getFirestore()
      .collection('users')
      .doc(userId)
      .collection('board')
      .doc('default')
      .collection('columns')
      .add(newCol)
      .then(result => {
          const subColumns = createColumns(columnData.type.type, result.id);
          const columnToUpdate = {...newCol};
          columnToUpdate.columns = subColumns;
          
          return getFirestore()
            .collection('users')
            .doc(userId)
            .collection('board')
            .doc('default')
            .collection('columns')
            .doc(result.id)
            .update(columnToUpdate)
      })
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
};

/**
 * Delete a column from the board
 * 
 * @param {String} userId Column's Owner ID
 * @param {String} id Column ID to be deleted
 */
export const deleteBoardColumnAction = ({userId, id}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  
  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('board')
    .doc('default')
    .collection('columns')
    .doc(id)
    .delete()
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};

/**
 * Move cards to the left column when reducing column type
 * 
 * @param {String} userId Column's Owner ID
 * @param {Object} currentColumn Current column where the card will be moved
 * @param {Function} _dispatch Function to dispatch new actions
 * @param {Number} reduceSize Number of columns that will be removed
 */
const moveCardBackwards = async ({userId, currentColumn, _dispatch, reduceSize}) => {
  for (let i = 1; i <= reduceSize; i++) {
    const lastSubColumn = currentColumn.columns[currentColumn.columns.length-i];
    const lastSubColumnCards = lastSubColumn.cards;
  
    const destinationSubColumn = currentColumn.columns[currentColumn.columns.length-(1+reduceSize)];
  
    await forAsync(lastSubColumnCards, card => {
      return new Promise(resolve => {
        const source = {
          index: 0,
          parentId: currentColumn.id,
          droppableId: lastSubColumn.id
        }
      
        const destination = {
          parentId: currentColumn.id,
          droppableId: destinationSubColumn.id,
          index: 0,
        }
        _dispatch(moveBoardCard({userId, source, destination}));
        setTimeout(() => {
          resolve()
        }, 300);
      })
    })
  }
}

/**
 * Call function to move card backwards and after it, dispatch and action
 * to delete the last subcolumn
 * 
 * @param {String} userId Column's Owner ID
 * @param {Object} currentColumn Current column where the card will be moved
 * @param {Function} _dispatch Function to dispatch new actions
 * @param {Number} reduceSize Number of columns that will be removed
 */
const reduceSubColumn = async ({userId, currentColumn, _dispatch, reduceSize}) => {
  await moveCardBackwards({userId, currentColumn, _dispatch, reduceSize})
  _dispatch(deleteLastSubColumn({userId, colId: currentColumn.id, reduceSize}));
}

/**
 * Update the column from a board
 * Checks if the types have changed to increase the subcolumns or
 * remove the subcolumns and move the cards
 * 
 * @param {String} userId Column's Owner ID
 * @param {Object} columnData Column data to be updated
 */
export const updateBoardColumnAction = ({userId, columnData}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {
  
  if (columnData.type.type) {
    columnData.type = columnData.type.type;
  }

  /*
    Check if there is any change on type, if is:
    	IF (New sub columns Length > Current sub columns length)
        (1) Add new SubColumn

      IF (New sub columns Length === Current sub column length)
        (2) Do nothing

      IF (New subs columns Length < Current Sub columns length)
        Move all cards from the last sub column to the new last sub column
  */

  const currentColumn = getColumnById(_getState, columnData.id);
  const updatedColumnData = _cloneDeep(columnData);

  if ( (currentColumn.type === 'single' && columnData.type === 'double') || 
       (currentColumn.type === 'double' && columnData.type === 'multi') ) {
    // (1) It's higher, so add ONE new subcolumn
    const newSubColumn = createColumns('single', columnData.id);
    updatedColumnData.columns.push(newSubColumn[0]);
  }

  if (currentColumn.type === 'single' && columnData.type === 'multi') {
    // (1) It's higher, so add TWO new subcolumn"
    const newSubColumn = createColumns('double', columnData.id);
    updatedColumnData.columns.push(newSubColumn[0]);
    updatedColumnData.columns.push(newSubColumn[1]);
  }

  if (currentColumn.type === columnData.type) {
    // (2) It's the same column type, don't do nothing"
  }

  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('board')
    .doc('default')
    .collection('columns')
    .doc(columnData.id)
    .update(updatedColumnData)
    .then(() => {
      if ( (currentColumn.type === 'double' && columnData.type === 'single') ||
      (currentColumn.type === 'multi' && columnData.type === 'double') ) {
        // (3) It's lower, so delete the last subcolumn "
        reduceSubColumn({currentColumn, _dispatch, userId, reduceSize: 1});
      }

      if (currentColumn.type === 'multi' && columnData.type === 'single') {
        // (3) It's lower, so delete the last two subcolumns "
        reduceSubColumn({currentColumn, _dispatch, userId, reduceSize: 2});
      }  
    })
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};
  
/**
 * Delete the last subcolumns depending on the reduce size
 * 
 * @param {String} userId Column's Owner ID
 * @param {String} colId Column Id where the subcolumn will be deleted
 * @param {Number} reduceSize Number of columns that will be removed
 */
export const deleteLastSubColumn = ({userId, colId, reduceSize}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

  const column = getColumnById(_getState, colId);
  const clonedColumn = _cloneDeep(column);

  clonedColumn.columns = clonedColumn.columns.slice(0, clonedColumn.columns.length - reduceSize);

  return getFirestore()
    .collection('users')
    .doc(userId)
    .collection('board')
    .doc('default')
    .collection('columns')
    .doc(colId)
    .update(clonedColumn)
    .catch(error => {
      _dispatch(setShowErrorAction({error}));
    });
};

/**
 * Move column using on drag and drop
 * 
 * @param {String} userId Column's Owner ID
 * @param {Object} source Source object from DnD library
 * @param {Object} destination Destination object from DnD library
 * @param {String} projectId Current project's ID
 */
export const moveColumnAction = ({userId, source, destination, projectId}) => async (
  _dispatch,
  _getState,
  { getFirestore }
) => {

  const columns = _cloneDeep(getColumns(_getState, projectId));
  const newColumns = reorderColumns(columns, source.index, destination.index);

  newColumns.map(c => {
    const id = c.id;

    return getFirestore()
      .collection('users')
      .doc(userId)
      .collection('board')
      .doc('default')
      .collection('columns')
      .doc(id)
      .update(c)
      .catch(error => {
        _dispatch(setShowErrorAction({error}));
      });
  })
};
