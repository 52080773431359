import React from 'react';
import {
  KwilioEditor,
  useEditorState,
  EDITOR_CLASS_SELECTOR,
} from '@kwilio/editor';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { useCurrentProject } from 'core/store/selectors';
import { uploadImage } from 'core/store/actions/stateActions';
import './Editor.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  placeholder: {
    flexGrow: 1,
    cursor: 'text',
  },
}));

export default function Project() {
  const dispatch = useDispatch();
  const { dispatch: dispatchEditor, manager } = useEditorState();
  const currentProject = useCurrentProject();
  const classes = useStyles();

  const handleBlur = (_, e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatchEditor && dispatchEditor();
  };

  const handleMenuAction = () => {
    dispatchEditor && dispatchEditor();
  };

  const goToStart = () => {
    if (manager) {
      manager.data.actions.goTo({ position: 0 });
    }
  };

  const handleUploadImage = async (file) => {
    const url = await dispatch(uploadImage(file));
    return url;
  }

  return (
    <div className={classes.root} id="content-wrapper">
      {currentProject ? (
        <KwilioEditor
          autoFocus
          key={`main-editor-${currentProject.id}`}
          onBlur={handleBlur}
          onMenuAction={handleMenuAction}
          theme={{
            styles: {
              'kwilio:menubar': {
                background: 'white',
                position: 'fixed',
                zIndex: 1,
                width: '100%',
                padding: '1em 1.5em',
                margin: '-20px',
              },
              'remirror:editor': {
                [EDITOR_CLASS_SELECTOR]: {
                  padding: '0',
                  marginTop: '3em',
                },
              },
            },
          }}
          uploadImage={handleUploadImage}
        />
      ) : (
        <h1>Please select a project</h1>
      )}
      <div className={classes.placeholder} onClick={goToStart} />
    </div>
  );
}
